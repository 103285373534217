import {
  Box,
  Typography,
  Button,
  AppBar,
  CardMedia,
  Divider,
  IconButton,
  Grid,
} from '@mui/material';
import { useParams } from 'react-router-dom';
import { useEffect, useRef, useState } from 'react';
import { styled } from '@mui/material/styles';
import { useDispatch, useSelector } from 'react-redux';
import {
  ApplicationProps,
  Document,
  esignApplicationResponse,
  mdmsInvestorProfileQuestion,
  RiskProfileMaster,
} from '../../redux-store/types/api-types';
import {
  esignApplication,
  getApplicationDetailsWithRefId,
} from '../../redux-store/actions/onBoarding';
import { useHistory } from 'react-router';
import { RootStateType } from '../../redux-store/reducers';
import { Footer } from '../commonComponents';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import { ESIGN_STATUS, USER_ROLES } from '../../utils/constant';
import {
  acknowledgeApplication,
  getDocuments,
  getInvestorProfileData,
  isFormValidForSubmission,
} from '../../redux-store/actions/application';
import { showError } from '../../redux-store/actions/auth';
import { ApplicationDetailsCommonLayout } from '../ApplicationDetails/index';
import { getNationalityList, riskProfileMasterData } from '../../redux-store/actions';
import { nationaliyType } from '../../redux-store/types/mdms';
import MFCheckbox from '../../lib/formik/Checkbox';
import { Formik } from 'formik';
import { useFeatureToggles } from '../../utils/toggler';

export const Item = styled('div')(({ theme }) => ({
  ...theme.typography.body2,
  paddingTop: '10px',
  marginLeft: '2px',
  color: theme.palette.text.secondary,
  fontSize: '15px',
  fontWeight: 500,
  letterSpacing: '0.5px',
}));
export const ItemDetails = styled('div')(({ theme }) => ({
  ...theme.typography.body2,
  color: theme.palette.text.secondary,
  fontSize: '16px',
  marginLeft: '2px',
  fontWeight: 700,
  letterSpacing: '0.5px',
  maxWidth: '100%',
  overflowWrap: 'anywhere',
}));
export const Gridstyles = styled('div')(({ theme }) => ({
  paddingLeft: '10%',
  [theme.breakpoints.only('xs')]: {
    paddingLeft: 0,
  },
}));
export type Values = {
  ppmAcknowledge: boolean | null;
  kycDeclaration: boolean | null;
  kycDocumentsDeclaration: boolean | null;
};
const initialValues: Values = {
  ppmAcknowledge: true,
  kycDeclaration: true,
  kycDocumentsDeclaration: true,
};
export default function InvestorApplication(): JSX.Element {
  const { referenceId } = useParams<{ referenceId: string }>();
  const dispatch = useDispatch();
  const history = useHistory();
  const { investor, auth } = useSelector((store: RootStateType) => store);
  const [loading, setLoading] = useState(false);
  const [application, setApplication] = useState<ApplicationProps>();
  const applicationDetailRef = useRef<HTMLDivElement>();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { role = '' } = useSelector((store: RootStateType) => store.auth);

  const [fatcaDetails, setFatcaDetails] = useState(initialValues);

  const dateFeatureToggles = useFeatureToggles();

  useEffect(() => {
    (async function () {
      try {
        const _application = (await dispatch(
          getApplicationDetailsWithRefId(referenceId)
        )) as unknown as ApplicationProps;
        setApplication(_application);
      } catch (e) {
        console.error((e as Error).message);
      } finally {
        setLoading(false);
      }
    })();
    setLoading(true);
  }, []);

  useEffect(() => {
    const { token } = investor;
    if (!token) {
      history.push(`/investment-details/${referenceId}/details`);
    }
  }, []);

  const handleEsign = async (values: Values) => {
    try {
      setIsSubmitting(true);
      if (
        values.ppmAcknowledge === false ||
        values.kycDeclaration === false ||
        values.kycDocumentsDeclaration === false
      ) {
        throw 'Declaration is required';
      }
      const nationalitiesMdmsMasters = (await dispatch(
        getNationalityList()
      )) as unknown as nationaliyType;
      const documentsResponse = (await dispatch(getDocuments())) as unknown as Document;
      const risk = (await dispatch(riskProfileMasterData())) as unknown as RiskProfileMaster;
      const questionsInvestor = (await dispatch(
        getInvestorProfileData()
      )) as unknown as mdmsInvestorProfileQuestion;
      if (ESIGN_STATUS.PENDING_SIGNATURE !== application?.signDetails.status) {
        ESIGN_STATUS.NOT_GENERATED === application?.signDetails.status
          ? await isFormValidForSubmission(
              application as ApplicationProps,
              risk,
              questionsInvestor.investor_question_individual,
              dateFeatureToggles,
              true,
              true,
              nationalitiesMdmsMasters,
              documentsResponse,
              auth.role
            )
          : await isFormValidForSubmission(
              application as ApplicationProps,
              risk,
              questionsInvestor.investor_question_individual,
              dateFeatureToggles,
              true,
              false,
              nationalitiesMdmsMasters,
              documentsResponse,
              auth.role
            );
      }
      const { signDetails, id } = application || {};
      if (signDetails?.url) {
        window.open(signDetails?.url, '_blank');
        return;
      }
      if (id) {
        const applicationResponse = (await dispatch(
          acknowledgeApplication({
            body: {
              ...application,
              ppmAcknowledge: values.ppmAcknowledge,
              kycDeclaration: values.kycDeclaration,
              kycDocumentsDeclaration: values.kycDocumentsDeclaration,
            },
            applicationId: id,
          })
        )) as unknown as ApplicationProps;
        if (
          applicationResponse.ppmAcknowledge &&
          applicationResponse.kycDeclaration &&
          applicationResponse.kycDocumentsDeclaration
        ) {
          const response = (await dispatch(
            esignApplication(referenceId)
          )) as unknown as esignApplicationResponse;
          setApplication({
            ...applicationResponse,
            signDetails: response,
          } as unknown as ApplicationProps);
          window.open(response?.url, '_blank');
          return;
        }
        return;
      }
    } catch (e) {
      typeof e === 'string' && dispatch(showError(e as string));
      console.error((e as Error).message);
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <Formik initialValues={fatcaDetails} onSubmit={handleEsign} enableReinitialize={true}>
      {({ handleSubmit, values, setValues }) => (
        <Box
          sx={{
            bgcolor: { xs: '', sm: 'rgba(238, 244, 251, 0.5)' },
          }}
          component="form"
          onSubmit={handleSubmit}>
          <IconButton
            sx={{
              position: 'fixed',
              right: 0,
              bottom: 75,
              borderRadius: '5px  0 0 5px',
              '&,:hover': {
                bgcolor: 'primary.main',
              },
            }}
            onClick={() => applicationDetailRef.current?.scrollIntoView({ behavior: 'smooth' })}>
            <ArrowUpwardIcon fontSize="large" sx={{ color: 'common.white' }} />
          </IconButton>
          <AppBar position="fixed" elevation={0} sx={{ bgcolor: 'common.white' }}>
            <Box>
              <CardMedia
                component="img"
                src="/images/kotak-logo.png"
                alt="Kotak Logo"
                sx={{ width: '190px', m: 3, my: 1 }}
              />
            </Box>
          </AppBar>
          <Divider sx={{ display: { xs: 'block', sm: 'none' } }} />
          <Box sx={{ py: 5, px: { xs: 5, sm: 10 } }} ref={applicationDetailRef}>
            <Box
              sx={{
                display: 'flex',
                alignItems: 'flex-start',
                mb: 2,
                justifyContent: 'space-between',
                mt: 10,
              }}>
              <Typography sx={{ fontSize: 20, fontWeight: 600, color: 'primary.main', mb: 4 }}>
                Application Details
              </Typography>
              {application?.editable && (
                <IconButton>
                  <CardMedia
                    component="img"
                    src="/images/edit-icon-outlined.svg"
                    alt="Edit Icon"
                    sx={{ width: 'unset' }}
                    onClick={() =>
                      history.push(`/investment-details/edit-application`, {
                        id: application?.id,
                        applicant1ReferenceId: application?.applicant1ReferenceId,
                      })
                    }
                  />
                </IconButton>
              )}
            </Box>
            {application && (
              <>
                <ApplicationDetailsCommonLayout
                  loading={loading}
                  application={application as ApplicationProps}
                />
                <Grid item xs={12} sx={{ mt: 4 }}>
                  <MFCheckbox
                    name={`kycDeclaration`}
                    disabled={application?.kycDeclaration as boolean}
                    label={
                      <Typography sx={{ textAlign: 'justify' }}>
                        I/We hereby declare that the details furnished above are true and correct to
                        the best of my/our knowledge and belief. In case any of the above
                        information is found to be false or untrue or misleading or misrepresenting,
                        I/We am/are aware that I/We may be held liable for it. I/We hereby authorize
                        and give the Investment Manager an explicit consent to download records and
                        fetch my/our KYC details from CVL KRA / CKYCR as my/our KYC as per CVL KRA /
                        CKYCR still holds goods, proofs for which were submitted earlier.
                      </Typography>
                    }
                    sx={{ justifyContent: 'flex-start', display: 'flex', alignItems: 'flex-start' }}
                    onChange={({ target: { checked } }) => {
                      setValues({
                        ...values,
                        kycDeclaration: checked,
                      });
                    }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <MFCheckbox
                    name={`ppmAcknowledge`}
                    disabled={application?.ppmAcknowledge as boolean}
                    label={
                      <Typography sx={{ textAlign: 'justify' }}>
                        I/We have received a copy of the{' '}
                        <Button
                          sx={{
                            fontSize: 'inherit',
                            fontWeight: 'inherit',
                            fontFamily: 'inherit',
                            color: 'inherit',
                            textUnderlinePosition: 'under',
                            textDecoration: 'underline',
                            p: 0,
                            ':hover': {
                              bgcolor: '#F4FCFC',
                              color: 'primary.main',
                              textDecoration: 'underline',
                            },
                          }}
                          onClick={() => window.open(application.plan.tcLink)}>
                          private placement memorandum (&quot;PPM&quot;)
                        </Button>{' '}
                        and a copy of the contribution agreement (“Contribution Agreement”). I/We
                        hereby further agree and confirm that the contents of PPM & Contribution
                        Agreement were explained to me and have also read and understood the
                        contents of the PPM and the Contribution Agreement.
                      </Typography>
                    }
                    sx={{ justifyContent: 'flex-start', display: 'flex', alignItems: 'flex-start' }}
                    onChange={({ target: { checked } }) => {
                      setValues({
                        ...values,
                        ppmAcknowledge: checked,
                      });
                    }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <MFCheckbox
                    name={`kycDocumentsDeclaration`}
                    disabled={application?.kycDocumentsDeclaration as boolean}
                    label={
                      <Typography sx={{ textAlign: 'justify' }}>
                        I/We do hereby confirm that I/We shall provide KYC documents on a periodic
                        basis as prescribed by the Investment Manager/ SEBI / under Applicable Law
                        including the Prevention of Money Laundering Act, 2005, and the rules
                        notified thereunder for ongoing due diligence.
                      </Typography>
                    }
                    sx={{ justifyContent: 'flex-start', display: 'flex', alignItems: 'flex-start' }}
                    onChange={({ target: { checked } }) => {
                      setValues({
                        ...values,
                        kycDocumentsDeclaration: checked,
                      });
                    }}
                  />
                </Grid>
                {![ESIGN_STATUS.SIGNED, ESIGN_STATUS.EXPIRED].includes(
                  application.signDetails.status
                ) && (
                  <Box sx={{ textAlign: 'center' }}>
                    <Button
                      variant="contained"
                      type="submit"
                      sx={{
                        color: 'common.white',
                        minWidth: '200px',
                        mt: 4,
                        fontWeight: 600,
                        lineHeight: 1.5,
                      }}
                      // onClick={handleEsign}
                      disabled={isSubmitting}>
                      {`e-Sign ${isSubmitting ? '...' : ''}`}
                    </Button>
                  </Box>
                )}
              </>
            )}
          </Box>
          <Footer />
        </Box>
      )}
    </Formik>
  );
}

/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { validateYupSchema } from 'formik';
import { bankObject } from '../../components/investors/bankDetails';
//import { getDocumentDetails } from '../../components/investors/documentDetails';
import { contactPersonObject } from '../../components/NonIndividualInvestor/ContactDetails';
import { document_object } from '../../components/NonIndividualInvestor/DocumentDetails';
import { percentageError } from '../../components/NonIndividualInvestor/ubo';
import {
  applicantStatusMasters,
  APPLICATION_TYPE_FOR_DOCUMENTS,
  IS_PENNYDROP_APPLICABLE_FOR_AMC,
  occupationDetailsMasters,
  POA_Authorized_signatory_can_Esign_Enable,
  POA_AUTHORIZED_SIGNATORY_INDIVIDUAL_FLOW_VISIBLITY_ENABLE,
  salutationsMasters,
  USER_ROLES,
  yesAndNoMasters,
} from '../../utils/constant';
import { ckycNumberRegex, emailRegex, individualPanRegex, stringRegex } from '../../utils/regex';
import {
  bankDetailsSchema,
  contributorDetailsSchema,
  documentDetailsSchema,
  FATCAValidationSchema,
  KYCDetailsSchema,
  nomineeDetailsSchema,
  nonIndividualContactDetailsSchema,
  NonIndividualContributorValidationSchema,
  nonIndividualDocumentDetailsSchema,
} from '../../utils/schema';
import {
  checkInvestorProfilebasedOnScheme,
  checkIfApplicationIsNonIndividualPOA,
  getAddressData,
  getNomineeRelation,
  getRelation,
  isAccreditedDocumentVisible,
  isMinor,
  poaApproverMandetryDocs,
  poaFirstApplicantDocumentVisible,
  fatcaDocumentCheck,
  checkValidationBasedOnDate,
  checkIfApplicationIsIndividualPOA,
  nominationPageDocument,
  individualDocuments,
  nomineesDocuments,
  nonIndividualDocuments,
} from '../../utils/utilityFunctions';
import {
  AuthorisedErrors,
  BankError,
  CALL_API,
  InvestorProfileErrors,
  RiskProfileErrors,
  UboErrors,
} from '../middleware';

import {
  Applicant,
  ApplicationProps,
  Bank,
  NomineeType,
  Document,
  uboTypes,
  FetchUBORequestBody,
  StampPapersType,
  dashboardOnboardingType,
  monthwiseOnboardingSummaryType,
  distributorWisecommitmentType,
  monthwiseCommitmentAmountType,
  ubo,
  FatcaMdms,
  individuals_Poa_nonIndividuals_Documents,
  Groups,
  GroupSignatories,
  RiskProfileMasterType,
  RiskProfileMaster,
  RiskProfile,
  investorQuestionMdsm,
  Declaration,
} from '../types/api-types';
import {
  CreateApplicationRequestBody,
  CREATE_APPLICATION,
  CREATE_APPLICATION_SUCCESS,
  GET_APPLICATION,
  GET_APPLICATION_SUCCESS,
  GET_ALL_APPLICATION_SUCCESS,
  GET_STAMPPAPER_DETAILS_SUCCESS,
  GET_STAMPPAPER_DETAILS,
  GET_DISTRIBUTOR_COMMITMENTAMOUNT_SUCCESS,
  GET_DISTRIBUTOR_COMMITMENTAMOUNT,
  GET_MONTHWISE_COMMITMENTAMOUNT_SUCCESS,
  GET_MONTHWISE_COMMITMENTAMOUNT,
  GET_ONBOARDING_SUMMARY_SUCCESS,
  GET_ONBOARDING_SUMMARY,
  GET_MONTHWISE_ONBOARDING_SUMMARY_SUCCESS,
  GET_MONTHWISE_ONBOARDING_SUMMARY,
  GET_ALL_APPLICATION,
  UPDATE_APPLICATION_SUCCESS,
  UPDATE_APPLICATION,
  AddJointHolderRequestBody,
  ADD_APPLICANT_SUCCESS,
  ADD_APPLICANT,
  GetAllApplicantsRequestBody,
  GetAllApplicantionsResponseBody,
  PENNY_DROP_VERIFICATION_SUCCESS,
  PENNY_DROP_VERIFICATION,
  GET_Documents_SUCCESS,
  GET_Documents,
  GET_Ubo_SUCCESS,
  GET_Ubo,
  UBO_LISTING_SUCCESS,
  UBO_LISTING,
  GET_Fatca_SUCCESS,
  GET_Fatca,
  GET_INVESTOR_PROFILE,
  GET_INVESTOR_PROFILE_SUCCESS,
} from '../types/application';
import { DateFeatureTogglesType, mdmsCountriesList, nationaliyType } from '../types/mdms';
import { getNationalityList } from './mdms';
import {
  handleIntialSelect,
  RiskProfileObj,
  riskQuetionnaireObject,
} from '../../components/investors/riskProfileDetails';
import { AssignAnsAndVisibilityForInitialInvestorQuestion } from '../../components/investors/investorQuestionnaire';
import store from '../../store';

export const createApplication =
  (body: CreateApplicationRequestBody) =>
  async (dispatch: any): Promise<ApplicationProps> => {
    return await dispatch({
      [CALL_API]: {
        url: `/onboarding/applications`,
        method: 'POST',
        types: [CREATE_APPLICATION_SUCCESS, CREATE_APPLICATION],
        body,
      },
    });
  };

export const getApplicationDetails =
  (applicationId: string, method = '') =>
  async (dispatch: any): Promise<ApplicationProps> => {
    return await dispatch({
      [CALL_API]: {
        url: `/onboarding/applications/${applicationId}`,
        method: method ? 'DELETE' : 'GET',
        types: [GET_APPLICATION_SUCCESS, GET_APPLICATION],
      },
    });
  };
export const getParams = (paramsObj: Partial<GetAllApplicantsRequestBody>) => ({
  type: 'GET_PARAMS_DATA',
  paramsObj,
});
export const getAllApplications =
  (params: Partial<GetAllApplicantsRequestBody>) =>
  async (dispatch: any): Promise<GetAllApplicantionsResponseBody> => {
    await dispatch(getParams(params));
    return await dispatch({
      [CALL_API]: {
        url: `/onboarding/applications`,
        method: 'GET',
        types: [GET_ALL_APPLICATION_SUCCESS, GET_ALL_APPLICATION],
        params: {
          limit: 10,
          sort: 'createdAt',
          order: 'DESC',
          ...params,
        },
      },
    });
  };

export const onboardingSummary =
  () =>
  async (dispatch: any): Promise<dashboardOnboardingType> => {
    return await dispatch({
      [CALL_API]: {
        url: `/onboarding/dashboard/onboardingSummary`,
        method: 'GET',
        types: [GET_ONBOARDING_SUMMARY_SUCCESS, GET_ONBOARDING_SUMMARY],
      },
    });
  };

export const monthwiseOnboardingSummary =
  () =>
  async (dispatch: any): Promise<monthwiseOnboardingSummaryType> => {
    return await dispatch({
      [CALL_API]: {
        url: `/onboarding/dashboard/monthwiseOnboardingSummary?months=4`,
        method: 'GET',
        types: [GET_MONTHWISE_ONBOARDING_SUMMARY_SUCCESS, GET_MONTHWISE_ONBOARDING_SUMMARY],
      },
    });
  };

export const distributorWisecommitmentAmount =
  () =>
  async (dispatch: any): Promise<distributorWisecommitmentType> => {
    return await dispatch({
      [CALL_API]: {
        url: `/onboarding/dashboard/distributorWisecommitmentAmount`,
        method: 'GET',
        types: [GET_DISTRIBUTOR_COMMITMENTAMOUNT_SUCCESS, GET_DISTRIBUTOR_COMMITMENTAMOUNT],
      },
    });
  };

export const monthwiseCommitmentAmount =
  () =>
  async (dispatch: any): Promise<monthwiseCommitmentAmountType> => {
    return await dispatch({
      [CALL_API]: {
        url: `/onboarding/dashboard/monthwiseCommitmentAmount`,
        method: 'GET',
        types: [GET_MONTHWISE_COMMITMENTAMOUNT_SUCCESS, GET_MONTHWISE_COMMITMENTAMOUNT],
      },
    });
  };

// export const getStampPaperCount =
//   (params: Partial<GetAllApplicantsRequestBody>) =>
//   async (dispatch: any): Promise<GetAllApplicantionsResponseBody> => {
//     return await dispatch({
//       [CALL_API]: {
//         url: `/leegality/stampPaperDetails`,
//         method: 'GET',
//         types: [GET_STAMPPAPER_DETAILS_SUCCESS, GET_STAMPPAPER_DETAILS],

//       },
//     });
//   };

export const getStampPaperCount =
  () =>
  async (dispatch: any): Promise<StampPapersType> => {
    return await dispatch({
      [CALL_API]: {
        url: `/onboarding/leegality/stampPaperDetails`,
        method: 'GET',
        types: [GET_STAMPPAPER_DETAILS_SUCCESS, GET_STAMPPAPER_DETAILS],
      },
    });
  };

export const updateApplication =
  ({
    body,
    applicationId,
    toastMessage,
  }: {
    body: Partial<ApplicationProps>;
    applicationId: string;
    toastMessage?: string;
  }) =>
  async (dispatch: any): Promise<ApplicationProps> => {
    const showToast = typeof toastMessage !== 'undefined';
    if (showToast) {
      toastMessage =
        `Application ${body.applicationNumber || ''} - ` + (toastMessage || ' Saved successfully');
    }
    try {
      return await dispatch({
        [CALL_API]: {
          url: `/onboarding/applications/${applicationId}/update`,
          method: 'POST',
          types: [UPDATE_APPLICATION_SUCCESS, UPDATE_APPLICATION],
          body,
          showToast,
          toastMessage,
        },
      });
    } catch (error: any) {
      console.log('catch error on update application', error);
      throw error;
    }
  };

export const addJointHolder =
  (body: AddJointHolderRequestBody, applicationId: string) =>
  async (dispatch: any): Promise<ApplicationProps> => {
    return await dispatch({
      [CALL_API]: {
        url: `/onboarding/applications/${applicationId}/addApplicant`,
        method: 'POST',
        types: [ADD_APPLICANT_SUCCESS, ADD_APPLICANT],
        body,
      },
    });
  };

export const validateContributorDetails = async (
  applicants: Partial<Applicant>[],
  role: any,
  nationalitiesMdmsMasters: nationaliyType
) => {
  const schemaData = {
    applicants: applicants.map((applicant) => ({
      ...applicant,
      nationality: applicant.nationality
        ? applicant.nationality.toUpperCase()
        : [USER_ROLES.INVESTOR, USER_ROLES.POAAPPROVER].includes(role)
        ? ''
        : 'INDIAN',
      maritalStatus: applicant.maritalStatus || '',
      namePrefix:
        applicant.namePrefix && salutationsMasters.includes(applicant.namePrefix || '')
          ? applicant.namePrefix
          : 'Mr.',
      jointApplicantRelation: getRelation(applicant.relationShipWithFirstApplicant)
        ? applicant.relationShipWithFirstApplicant
        : 'Others',
      relationShipWithFirstApplicant: getRelation(applicant.relationShipWithFirstApplicant)
        ? ''
        : applicant.relationShipWithFirstApplicant,
      aadhaarNumber: applicant.aadhaarNumber || '',
    })),
    nationalityDropdown: nationalitiesMdmsMasters.countries.map((list) => list.nationality),
  };
  try {
    await validateYupSchema(schemaData, contributorDetailsSchema, true, schemaData);
  } catch (error) {
    throw `Contributor details`;
  }
};

export const validateKYCDetails = async (
  applicants: Partial<Applicant>[],
  role: any,
  dpName: string,
  dpId: string,
  dpClientId: string,
  createdAt: string,
  dematFieldValidation: string
) => {
  const schemaData = {
    applicants: applicants.map((applicant) => ({
      occupationDetails: applicant.occupationDetails || '',
      otherOccupation: applicant.otherOccupation || '',
      grossAnnualIncome: applicant.grossAnnualIncome || '',
      politicallyExposedPersonStatus: applicant.politicallyExposedPersonStatus
        ? applicant.politicallyExposedPersonStatus
        : [USER_ROLES.INVESTOR, USER_ROLES.POAAPPROVER].includes(role)
        ? ''
        : 'Not Applicable',
      ckycNo: applicant.ckycNo || '',
      kinNo: applicant.kinNo || '',
      isAccreditedInvestor: applicant.isAccreditedInvestor || '',
      issuingAuthority: applicant.issuingAuthority || '',
      certificateNo: applicant.certificateNo || '',
      accreditedInvestorFrom: applicant.accreditedInvestorFrom || new Date().toISOString(),
      accreditedInvestorTo:
        applicant.accreditedInvestorTo ||
        new Date(new Date().setDate(new Date().getDate() + 1)).toISOString(),
    })),
    dpName,
    dpId,
    dpClientId,
    createdAt,
  };
  try {
    await validateYupSchema(schemaData, KYCDetailsSchema(dematFieldValidation), true, schemaData);
  } catch (error) {
    throw `KYC details`;
  }
};

export const validateFATCA = async (
  applicants: Partial<Applicant>[],
  role: any,
  nationalitiesMdmsMasters: nationaliyType,
  hasPOA: boolean | undefined
) => {
  const schemaData = {
    applicants: applicants?.map((applicant) => ({
      typeOfAddressProvidedAtKRA: applicant.typeOfAddressProvidedAtKRA || '',
      taxResidentOfAnyCountryOtherThanIndia:
        applicant.taxResidentOfAnyCountryOtherThanIndia || false,
      placeOfBirth: applicant.placeOfBirth || '',
      countryOfBirth: applicant.countryOfBirth
        ? applicant.countryOfBirth.toUpperCase()
        : [USER_ROLES.INVESTOR, USER_ROLES.POAAPPROVER].includes(role)
        ? ''
        : 'INDIA',
      countryOfNationality: applicant.countryOfNationality
        ? applicant.countryOfNationality.toUpperCase()
        : [USER_ROLES.INVESTOR, USER_ROLES.POAAPPROVER].includes(role)
        ? ''
        : 'INDIA',
      taxCountryName: applicant.taxCountryName || '',
      taxID: applicant.taxID || '',
      idType: applicant.idType || '',
      nameOfEntity: applicant.nameOfEntity || '',
      dateOfIncorporation: applicant.dateOfIncorporation || '',
      cityOfIncorporation: applicant.cityOfIncorporation || '',
      countryOfIncorporation: applicant.countryOfIncorporation || '',
      entityExcemptionCode: applicant.entityExcemptionCode || '',
      citizenOfAnyCountryOtherThanIndia: applicant.citizenOfAnyCountryOtherThanIndia || 'no',
      countryOfBirthOtherThanIndia: applicant.countryOfBirthOtherThanIndia || 'no',
      poaMandateHolderOutsideIndia: applicant.poaMandateHolderOutsideIndia || 'no',
      addressTelephoneOutsideIndia: applicant.addressTelephoneOutsideIndia || 'no',
      sourceOfWealth: applicant.sourceOfWealth || '',
      idTypeOthers: applicant.idTypeOthers || '',
      othersTinNotAvailableReason: applicant.othersTinNotAvailableReason || '',
      fatcaCrsDocumentCheck:
        applicant.fatcaCrsDocumentCheck === null && hasPOA ? true : applicant.fatcaCrsDocumentCheck,
    })),
    countryDropdown: nationalitiesMdmsMasters.countries.map((list) => list.name),
  };
  try {
    await validateYupSchema(schemaData, FATCAValidationSchema, true, schemaData);
  } catch (error) {
    throw `FATCA`;
  }
};

export const validateNomineeDetails = async (
  Nominees: Partial<NomineeType>[],
  doNotWishToNominate: boolean,
  applicants: Partial<Applicant>[],
  hasPOA: boolean | undefined
) => {
  const schemaData = {
    doNotWishToNominate: doNotWishToNominate === null ? true : doNotWishToNominate,
    nominees: Nominees.length
      ? Nominees.map((nominee, index) => ({
          ...nominee,
          Relationship: getNomineeRelation(nominee.nomineeRelationship?.toUpperCase())
            ? nominee.nomineeRelationship
              ? nominee.nomineeRelationship.toUpperCase()
              : nominee.nomineeRelationship
            : 'OTHERS',
          nomineeRelationship: getNomineeRelation(nominee.nomineeRelationship?.toUpperCase())
            ? ''
            : nominee.nomineeRelationship,
          sno: index,
          edited: false,
          nominationDocumentCheck:
            nominee.nominationDocumentCheck === null && hasPOA
              ? true
              : nominee.nominationDocumentCheck,
        }))
      : [],
  };
  try {
    await validateYupSchema(schemaData, nomineeDetailsSchema(applicants), true, schemaData);
  } catch (error) {
    throw `Nominee details ${error}`;
  }
};

export const validateBankDetails = async (
  bankDetails: Partial<Bank>[],
  verifyPennydrop: boolean,
  applicationType: string,
  applicants: Partial<Applicant>[],
  isSameBank: boolean
) => {
  const schemaData = {
    applicationType: applicationType,
    status: applicants.length ? applicants[0].status : '',
    banks: (bankDetails.length
      ? bankDetails.map((bank) => ({ ...bank, defaultBankAccount: !!bank.defaultBankAccount }))
      : [bankObject]
    ).filter((_, index) => (index === 0 && isSameBank) || !isSameBank),
    isSameBank,
  };
  try {
    if (IS_PENNYDROP_APPLICABLE_FOR_AMC && verifyPennydrop) {
      const isAllBanksPennyChecked = bankDetails
        .filter((_, index) => (index === 0 && isSameBank) || !isSameBank)
        .every((bank) => bank.pennydropCheck);
      if (!isAllBanksPennyChecked) {
        throw new BankError('Please make sure that all the banks are verified');
      }
    }
    await validateYupSchema(schemaData, bankDetailsSchema(applicants), true, schemaData);
  } catch (error) {
    if (error instanceof BankError) {
      throw error;
    }
    throw `Bank details`;
  }
};

export const validateDocuments = async (
  application: ApplicationProps | null,
  documentsData: Document,
  role: any,
  nomineeValidationDate: string,
  dematDocumentValidation: string,
  bankAccountProofValidation: string,
  poaDocValidation: string
) => {
  const { hasPOA = false, createdAt = '', ppmNo = '', nominees = [] } = application || {};
  const schemaData = {
    ppmNo: ppmNo,
    applicants: individualDocuments(
      application,
      role,
      nomineeValidationDate,
      dematDocumentValidation,
      poaDocValidation,
      documentsData
    ),
    nominees: nomineesDocuments(application, nomineeValidationDate, documentsData),
  };
  try {
    await validateYupSchema(
      schemaData,
      documentDetailsSchema(bankAccountProofValidation),
      true,
      schemaData
    );
  } catch (error) {
    throw `Document details`;
  }
};

export const validatePOAAuthorizedSignatories = async (
  application: ApplicationProps | null,
  poaValidationDate: string
) => {
  const { poaauthorisedsignatories: authorisedSignatoriesPOA = [] } = application || {};
  try {
    if (
      checkValidationBasedOnDate(application?.createdAt || '', poaValidationDate) &&
      checkIfApplicationIsNonIndividualPOA(application as ApplicationProps) &&
      POA_Authorized_signatory_can_Esign_Enable &&
      ((checkIfApplicationIsIndividualPOA(application as ApplicationProps) &&
        POA_AUTHORIZED_SIGNATORY_INDIVIDUAL_FLOW_VISIBLITY_ENABLE) ||
        checkIfApplicationIsNonIndividualPOA(application as ApplicationProps))
    ) {
      const getCanEsignCount = authorisedSignatoriesPOA?.filter((item) => item.canEsign);
      if (!authorisedSignatoriesPOA?.length) {
        throw new AuthorisedErrors('Please add Authorised Signatories');
      }
      if (!getCanEsignCount.length) {
        throw new AuthorisedErrors('Please Select a member for E-Sign');
      }
      if (getCanEsignCount.length < Number(application?.requiredAuthorisedSignatory || 0)) {
        throw new AuthorisedErrors(
          'Authorised Signatories that can esign can not be less than the Total No. of required Authorised Signatories'
        );
      }
    }
  } catch (error) {
    if (error instanceof AuthorisedErrors) {
      throw error;
    }
  }
};

export const isFormValidForSubmission = async (
  application: ApplicationProps | null,
  riskProfileDataMaster: RiskProfileMaster,
  questionsInvestor: investorQuestionMdsm,
  dateFeatureTogglesDates: DateFeatureTogglesType,
  validateDoc = true,
  verifyPennydrop = true,
  nationalitiesMdmsMasters = {},
  documentsResponse = {},
  role = ''
) => {
  const {
    bankAccountProofValidation,
    dematDocumentValidation,
    dematFieldValidation,
    nomineeValidationDate,
    poaValidationDate,
    poaDocValidation,
  } = dateFeatureTogglesDates;
  const {
    applicants = [],
    nominees = [],
    doNotWishToNominate = false,
    banks = [],
    hasPOA = true,
    applicationType = '',
    dpName = '',
    dpId = '',
    dpClientId = '',
    createdAt = '',
    isSameBank = false,
  } = application || {};

  try {
    application?.hasPOA && (await validatePOAAuthorizedSignatories(application, poaValidationDate));
    await validateContributorDetails(applicants, role, nationalitiesMdmsMasters as nationaliyType);
    await validateKYCDetails(
      applicants,
      role,
      dpName,
      dpId,
      dpClientId,
      createdAt,
      dematFieldValidation
    );
    await validateFATCA(applicants, role, nationalitiesMdmsMasters as nationaliyType, hasPOA);
    !checkValidationBasedOnDate(createdAt, nomineeValidationDate) &&
      (await validateNomineeDetails(nominees, doNotWishToNominate, applicants, hasPOA));
    await validateBankDetails(banks, verifyPennydrop, applicationType, applicants, isSameBank);
    await validateRiskProfile(application, riskProfileDataMaster, role);
    // await validateInvestorProfile(application, questionsInvestor);
    validateDoc &&
      Object.keys(documentsResponse).length !== 0 &&
      (await validateDocuments(
        application,
        documentsResponse as Document,
        role,
        nomineeValidationDate,
        dematDocumentValidation,
        bankAccountProofValidation,
        poaDocValidation
      ));
  } catch (error) {
    if (error instanceof BankError) {
      throw (error as BankError).message;
    }
    if (error instanceof AuthorisedErrors) {
      throw (error as AuthorisedErrors).message;
    }
    if (error instanceof RiskProfileErrors) {
      throw (error as RiskProfileErrors).message;
    }
    if (error instanceof InvestorProfileErrors) {
      throw (error as InvestorProfileErrors).message;
    }
    throw `In ${error}, the required fields are not filled.`;
  }
};

export const pennyDropVerification =
  (body: Bank) =>
  async (dispatch: any): Promise<Bank> => {
    return await dispatch({
      [CALL_API]: {
        url: `/onboarding/pennydropverification`,
        method: 'POST',
        types: [PENNY_DROP_VERIFICATION_SUCCESS, PENNY_DROP_VERIFICATION],
        body,
      },
    });
  };

export const getDocuments =
  () =>
  async (dispatch: any): Promise<Document> => {
    return await dispatch({
      [CALL_API]: {
        url: '/mdms/required_document_master.json',
        method: 'GET',
        types: [GET_Documents_SUCCESS, GET_Documents],
      },
    });
  };

export const getUboTypes =
  () =>
  async (dispatch: any): Promise<uboTypes> => {
    return await dispatch({
      [CALL_API]: {
        url: '/mdms/uboTypeMaster.json',
        method: 'GET',
        types: [GET_Ubo_SUCCESS, GET_Ubo],
      },
    });
  };

export const FetchData =
  (body: FetchUBORequestBody) =>
  async (dispatch: any): Promise<ubo> => {
    return await dispatch({
      [CALL_API]: {
        url: `/onboarding/kradetails`,
        method: 'POST',
        types: [UBO_LISTING_SUCCESS, UBO_LISTING],
        body,
      },
    });
  };

export const FatcaMdmsData =
  () =>
  async (dispatch: any): Promise<FatcaMdms> => {
    return await dispatch({
      [CALL_API]: {
        url: '/mdms/fatca_data.json',
        method: 'GET',
        types: [GET_Fatca_SUCCESS, GET_Fatca],
      },
    });
  };

export const getInvestorProfileData =
  () =>
  async (dispatch: any): Promise<investorQuestionMdsm> => {
    return await dispatch({
      [CALL_API]: {
        url: '/mdms/investorprofile_data.json',
        method: 'GET',
        types: [GET_INVESTOR_PROFILE_SUCCESS, GET_INVESTOR_PROFILE],
      },
    });
  };

export const validateNonIndividualContributorDetails = async (
  applicants: Partial<Applicant>[],
  dpName: string,
  dpId: string,
  dpClientId: string,
  dematFieldValidation: string
) => {
  const schemaData = {
    applicants: applicants,
    dpName,
    dpId,
    dpClientId,
  };
  try {
    await validateYupSchema(
      schemaData,
      NonIndividualContributorValidationSchema(dematFieldValidation),
      true,
      schemaData
    );
  } catch (error) {
    throw `Contributor details`;
  }
};
export const validateNonIndividualContactDetails = async (
  applicants: Partial<Applicant>[],
  countryDropdown: string[]
) => {
  const schemaData = {
    applicants: applicants.map((applicant) => {
      const correspondence = getAddressData('correspondence', applicant.addresses);
      const permanent = getAddressData('permanent', applicant.addresses);
      const defaultPayload = {
        contactperson: applicant.contactperson
          ? {
              ...applicant.contactperson,
              countryNameAndCode: applicant.contactperson.countryNameAndCode
                ? applicant.contactperson.countryNameAndCode
                : 'India: +91',
              country: applicant.contactperson.country
                ? applicant.contactperson.country.toUpperCase()
                : 'INDIA',
            }
          : contactPersonObject,
        address: {
          correspondence,
        },
      };
      const permanentAddressPayload = correspondence.permanentAddressSameAsCorresponding
        ? defaultPayload
        : {
            ...defaultPayload,
            address: {
              ...defaultPayload.address,
              permanent: {
                ...permanent,
                country: permanent.country ? permanent.country.toUpperCase() : 'INDIA',
              },
              correspondence: {
                ...correspondence,
                country: correspondence.country ? correspondence.country.toUpperCase() : 'INDIA',
              },
            },
          };
      return permanentAddressPayload;
    }),
    countryDropdown: countryDropdown,
  };
  try {
    await validateYupSchema(schemaData, nonIndividualContactDetailsSchema, true, schemaData);
  } catch (error) {
    throw `Contact details`;
  }
};

export const validateNonIndividualDocumentDetails = async (
  applicants: Partial<Applicant>[],
  documentsData: individuals_Poa_nonIndividuals_Documents[],
  banks: Partial<Bank>[],
  role: string,
  application: ApplicationProps,
  dematDocumentValidation: string,
  bankAccountProofValidation: string,
  poaDocValidation: string,
  ppmNo?: string
) => {
  const schemaData = {
    ppmNo: ppmNo,
    applicants: nonIndividualDocuments(
      application,
      role as USER_ROLES,
      documentsData,
      dematDocumentValidation,
      poaDocValidation
    ),
    banks: banks,
  };
  try {
    await validateYupSchema(
      schemaData,
      nonIndividualDocumentDetailsSchema(bankAccountProofValidation),
      true,
      schemaData
    );
  } catch (error) {
    throw `Document details`;
  }
};
const fieldsTocheckForAuthorisedSignatories = [
  {
    key: 'name',
    label: 'Name',
    validateMulitple: false,
  },
  {
    key: 'pan',
    label: 'PAN',
    validateMulitple: true,
    regex: individualPanRegex,
    regexMsg: 'Only Individual PANs are allowed',
  },
  { key: 'mobile', label: 'Mobile Number', validateMulitple: true },
  {
    key: 'email',
    label: 'Email ID',
    validateMulitple: true,
    regex: emailRegex,
  },
  {
    key: 'designation',
    label: 'Designation',
    validateMulitple: false,
  },
];

export const validateNonIndividualAuthorisedSignatories = async (groups: Groups[]) => {
  try {
    if (!groups || !groups.length) {
      throw new AuthorisedErrors('Please add Authorized Signatories');
    }

    groups.map((group) => {
      const getActiveSignatories = group.groupsignatories.filter((item) => item.isActive);
      const getCanEsignCount = getActiveSignatories.filter((item) => item.canEsign);
      if (!getActiveSignatories.length) {
        throw new AuthorisedErrors('Please add Authorised Signatories');
      }
      if (!getCanEsignCount.length) {
        throw new AuthorisedErrors('Please Select a member for E-Sign in Authorised Signatories');
      }
      if (getCanEsignCount.length < group.threshold) {
        throw new AuthorisedErrors(
          'Authorised Signatories that can esign can not be less than the Total No. of required Authorised Signatories'
        );
      }
      getActiveSignatories.forEach((signatory, index) => {
        fieldsTocheckForAuthorisedSignatories.forEach((field) => {
          if (!signatory[field.key as keyof GroupSignatories] as unknown as string) {
            throw new AuthorisedErrors(`${field.label} is required for signatory ${index + 1}`);
          }
          if (
            field.regex &&
            !field.regex.test(signatory[field.key as keyof GroupSignatories] as unknown as string)
          ) {
            throw new AuthorisedErrors(
              field.regexMsg
                ? `${field.regexMsg} for signatory ${index + 1}`
                : `Please enter valid ${field.label} for signatory ${index + 1}`
            );
          }
          if (field.validateMulitple) {
            const values = getActiveSignatories.filter(
              (vsignatory) =>
                (vsignatory[field.key as keyof GroupSignatories] as unknown as string) ===
                (signatory[field.key as keyof GroupSignatories] as unknown as string)
            );
            if (values.length > 1) {
              throw new AuthorisedErrors(
                `There is already same ${field.label} for an Authorised Signatory associated with this application`
              );
            }
          }
        });
      });
      return group;
    });
  } catch (error) {
    if (error instanceof AuthorisedErrors) {
      throw error;
    }
  }
};

const fieldsTocheckForUbos = [
  {
    key: 'panNumber',
    label: 'Taxpayer ID Number/PAN/Equivalent ID Number',
    validateMulitple: true,
    required: true,
  },
  {
    key: 'dob',
    label: 'Date of Birth',
    validateMulitple: false,
    required: true,
  },
  { key: 'name', label: 'Name of UBO', validateMulitple: false, required: true },
  {
    key: 'identificationType',
    label: 'Identification Type',
    validateMulitple: false,
    required: true,
  },
  {
    key: 'percentageOfBeneficialInterest',
    label: 'Percentage of beneficial interest',
    validateMulitple: false,
    required: true,
  },
  {
    key: 'countryOfTaxResidency',
    label: 'Country of Tax Residency',
    validateMulitple: false,
    required: true,
  },
  {
    key: 'cpUboCode',
    label: 'CP/UBO Code',
    validateMulitple: false,
    required: true,
  },
  {
    key: 'placeAndCountryOfBirth',
    label: 'Place & Country of Birth',
    validateMulitple: false,
    required: true,
  },
  {
    key: 'occupation',
    label: 'Occupation',
    validateMulitple: false,
    required: true,
  },
  {
    key: 'otherOccupation',
    label: 'Other Occupation',
    validateMulitple: false,
    required: false,
  },
  {
    key: 'gender',
    label: 'Gender',
    validateMulitple: false,
    required: true,
  },
  {
    key: 'nationality',
    label: 'Nationality',
    validateMulitple: false,
    required: true,
  },
  {
    key: 'fatherName',
    label: 'Father Name',
    validateMulitple: false,
    required: true,
  },
  {
    key: 'ckycNumber',
    label: 'CKYC Number',
    validateMulitple: true,
    required: true,
  },
  {
    key: 'address2',
    label: 'Address 1',
    validateMulitple: false,
    required: true,
  },
  {
    key: 'address3',
    label: 'Address 2',
    validateMulitple: false,
    required: true,
  },
  {
    key: 'pincode',
    label: 'Pincode',
    validateMulitple: false,
    required: true,
  },
  {
    key: 'city',
    label: 'City',
    validateMulitple: false,
    required: true,
  },
  {
    key: 'state',
    label: 'State',
    validateMulitple: false,
    required: true,
  },
  {
    key: 'country',
    label: 'Country',
    validateMulitple: false,
    required: true,
  },
];
const validateNonIndividualUbo = async (
  applicants: Partial<Applicant>[],
  ubo_declaration_type: string,
  ubo_declaration_value: string,
  ubos: ubo[],
  nationalityDropdown: string[],
  countryDropdown: string[],
  uboTypesMdms: uboTypes
) => {
  try {
    const applicantsCkycNumbers = applicants?.map((applicant) => applicant.ckycNo);
    if (!ubo_declaration_type) {
      throw new UboErrors('Please Select Declaration Type in Declaration of Ubo');
    }
    if (
      ubo_declaration_type &&
      !uboTypesMdms?.uboType.map((_ubo) => _ubo.key).includes(ubo_declaration_type)
    ) {
      throw new UboErrors('Invalid Declaration Type in Declaration of Ubo');
    }
    if (!ubo_declaration_value) {
      throw new UboErrors('Please Select Declaration value in Declaration of Ubo');
    }
    if (ubo_declaration_value && ubo_declaration_type) {
      const _uboType = uboTypesMdms?.uboType?.find((u) => u.key === ubo_declaration_type);
      if (
        !_uboType?.openEntryField &&
        !!_uboType?.values.filter((val) => val.key === ubo_declaration_value).length &&
        _uboType?.validation === 'oneOf' &&
        !_uboType?.values.map((val) => val.key).includes(ubo_declaration_value)
      )
        throw new UboErrors('Invalid Declaration value in Declaration of Ubo');
      if (
        (_uboType?.validation === 'stringRegex' ||
          (!_uboType?.openEntryField &&
            !_uboType?.values.filter((val) => val.key === ubo_declaration_value).length &&
            _uboType?.otherValidation === 'stringRegex')) &&
        !stringRegex.test(ubo_declaration_value)
      )
        throw new UboErrors(
          'Special characters and numbers not allowed for Declaration value in Declaration of Ubo'
        );
    }
    if (ubo_declaration_type === 'none' && !ubos.filter((_item) => _item.isActive).length) {
      throw new UboErrors('Please Add Ultimate Benificiary Owners(UBO)');
    }
    if (ubos.filter((_item) => _item.isActive).length) {
      const activeUbos = ubos.filter((_item) => _item.isActive);
      activeUbos.map((ubo) => {
        if (!ubo.ckycNumber) {
          throw new UboErrors(`CKYC Number of ${ubo.name} is required`);
        }
        if (ubo.ckycNumber && applicantsCkycNumbers.includes(ubo.ckycNumber)) {
          throw new UboErrors(
            `CKYC Number of ${ubo.name} should not be same as CKYC Number exist in Contributor Details`
          );
        }
        if (ubo.ckycNumber && !ckycNumberRegex.test(ubo.ckycNumber.toString())) {
          throw new UboErrors(`Invalid CKYC Number,Only 14 Digits Allowed for ${ubo.name}`);
        }
        if (!nationalityDropdown.includes(ubo.nationality || '')) {
          throw new UboErrors(`Invalid Nationality for ${ubo.name} in Declaration of Ubo`);
        }
        if (!countryDropdown.includes(ubo.country || '')) {
          throw new UboErrors(`Invalid Country for ${ubo.name} in Declaration of Ubo`);
        }
        if (!countryDropdown.includes(ubo.countryOfTaxResidency || '')) {
          throw new UboErrors(
            `Invalid Country of Tax Residency for ${ubo.name} in Declaration of Ubo`
          );
        }
        if (!Object.keys(occupationDetailsMasters).includes(ubo.occupation || '')) {
          throw new UboErrors(`Invalid Occupation for ${ubo.name} in Declaration of Ubo`);
        }
        if (
          ubo.occupation &&
          occupationDetailsMasters[ubo.occupation || ''] === occupationDetailsMasters.others
        ) {
          if (!ubo.otherOccupation) {
            throw new UboErrors(
              `Other Occupation for ${ubo.name} in Declaration of Ubo is required`
            );
          }
        }
      });
      percentageError(ubos, true);
    }
    ubos
      .filter((_item) => _item.isActive)
      .forEach((ubo, index) => {
        fieldsTocheckForUbos.forEach((field) => {
          if ((!ubo[field.key as keyof ubo] as unknown as string) && field.required) {
            throw new UboErrors(`${field.label} is required for ubo ${index + 1}`);
          }
          if (
            field.key === 'dob' &&
            (ubo[field.key as keyof ubo] as unknown as string) &&
            isMinor(field.key)
          ) {
            throw new UboErrors(`Age should be greater than 18 for ubo ${index + 1}`);
          }
          if (
            field.key === 'percentageOfBeneficialInterest' &&
            (ubo[field.key as keyof ubo] as unknown as number) <= 0
          ) {
            throw new UboErrors(
              `Percentage Of Beneficial Interest should be greater 0 for ubo ${index + 1}`
            );
          }
          if (
            field.key === 'percentageOfBeneficialInterest' &&
            (ubo[field.key as keyof ubo] as unknown as number) > 100
          ) {
            throw new UboErrors(
              `Percentage Of Beneficial Interest must not exceed 100% for ubo ${index + 1}`
            );
          }
          if (field.validateMulitple) {
            const values = ubos.filter(
              (_ubo) =>
                (_ubo[field.key as keyof ubo] as unknown as string) ===
                  (ubo[field.key as keyof ubo] as unknown as string) &&
                (ubo[field.key as keyof ubo] as unknown as string) &&
                (_ubo[field.key as keyof ubo] as unknown as string)
            );
            if (values.length > 1) {
              throw new UboErrors(
                `There is already same ${field.label} for an Ubos associated with this application`
              );
            }
          }
        });
      });
  } catch (e) {
    if (e instanceof UboErrors) {
      throw e;
    }
  }
};
export const nonIndividualFormValidForSubmission = async (
  application: ApplicationProps | null,
  documentsData: individuals_Poa_nonIndividuals_Documents[],
  questionsInvestor: investorQuestionMdsm,
  mdmsCountriesList: mdmsCountriesList[],
  uboTypesMdms: uboTypes,
  riskProfileDataMaster: RiskProfileMaster,
  dateFeatureTogglesDates: DateFeatureTogglesType,
  verifyPennydrop = true,
  role = ''
) => {
  const {
    applicants = [],
    banks = [],
    groups = [],
    ubo_declaration_type = '',
    ubo_declaration_value = '',
    ubos = [],
    applicationType = '',
    dpName = '',
    dpId = '',
    dpClientId = '',
    isSameBank = false,
  } = application || {};
  const nationalityDropdown = mdmsCountriesList.map((list) => list.nationality);
  const countryDropdown = mdmsCountriesList.map((list) => list.name);
  const ppmNo = application?.ppmNo;
  const {
    bankAccountProofValidation,
    dematDocumentValidation,
    dematFieldValidation,
    poaValidationDate,
    poaDocValidation,
  } = dateFeatureTogglesDates;
  try {
    await validateNonIndividualContributorDetails(
      applicants,
      dpName,
      dpId,
      dpClientId,
      dematFieldValidation
    );
    await validateNonIndividualContactDetails(applicants, countryDropdown);
    await validateBankDetails(banks, verifyPennydrop, applicationType, applicants, isSameBank);
    await validateRiskProfile(application, riskProfileDataMaster, role);
    await validateNonIndividualDocumentDetails(
      applicants,
      documentsData,
      banks,
      role,
      application as ApplicationProps,
      dematDocumentValidation,
      bankAccountProofValidation,
      poaDocValidation,
      ppmNo
    );
    {
      !application?.hasPOA && (await validateNonIndividualAuthorisedSignatories(groups));
      application?.hasPOA &&
        (await validatePOAAuthorizedSignatories(application, poaValidationDate));
    }
    // await validateInvestorProfile(application, questionsInvestor);
    await validateNonIndividualUbo(
      applicants,
      ubo_declaration_type,
      ubo_declaration_value,
      ubos,
      nationalityDropdown,
      countryDropdown,
      uboTypesMdms
    );
  } catch (error) {
    if (error instanceof UboErrors) {
      throw (error as UboErrors).message;
    }
    if (error instanceof AuthorisedErrors) {
      throw (error as AuthorisedErrors).message;
    }
    if (error instanceof BankError) {
      throw (error as BankError).message;
    }
    throw `In ${error}, the required fields are not filled.`;
  }
};

export const validateRiskProfile = async (
  application: ApplicationProps | null,
  riskProfileDataMaster: RiskProfileMaster,
  role: string
) => {
  const response = riskProfileDataMaster;
  const { applicants: exisitingApplicants = [], doNotWishToFillRiskProfile } = application || {};
  try {
    const schemaData = exisitingApplicants.map((applicant) => {
      const {
        riskDocumentCheck = true,
        namePrefix,
        name,
        dateOfBirth,
        riskProfileDeclaration = true,
        wishToAddRiskProfile = false,
        totalRiskProfileScore = 0,
        investmentHorizon,
        objective,
        investmentAmount,
        investmentObjective,
      } = applicant;
      const defaultPayload = {
        riskDocumentCheck,
        namePrefix,
        name,
        dateOfBirth,
        riskProfileDeclaration,
        wishToAddRiskProfile,
        riskprofiles: response.riskProfile
          ?.map((mdmsProfile) => {
            const existingRiskProfiles = applicant?.riskprofiles?.filter(
              (risk_profiles: any) => risk_profiles.question === mdmsProfile.key
            );
            const newrisk = existingRiskProfiles?.length
              ? existingRiskProfiles?.map((existingProfiles: any) => {
                  if (
                    existingProfiles.question === mdmsProfile.key &&
                    mdmsProfile.isMultiselect === 'true'
                  ) {
                    const getInvestment = handleIntialSelect(
                      applicant?.riskprofiles as RiskProfile[],
                      mdmsProfile.key
                    );
                    const checkAnswerArray = mdmsProfile.values?.map((_value) => _value.key);
                    let getOtherValue = '';
                    const updateOptions = getInvestment.map((ans) => {
                      if (!checkAnswerArray?.includes(ans.split('_')[0])) {
                        getOtherValue = ans.split('_')[0];
                        return `others_${ans.split('_')[1]}`;
                      }
                      return ans;
                    });
                    return {
                      ...existingProfiles,
                      ...mdmsProfile,
                      values: mdmsProfile.values?.map((value) => {
                        const scoreUpdate = updateOptions
                          .find((investment) => investment?.split('_')[0] === value.key)
                          ?.split('_')[1];

                        return {
                          ...value,
                          score: scoreUpdate ? Number(scoreUpdate) : value.score,
                        };
                      }),
                      InvestmentGoal: updateOptions || [],
                      scoreCal: Number(existingProfiles.scoreText) || 0,
                      otherValue: getOtherValue,
                      answer: getOtherValue
                        ? updateOptions?.toString()?.replace(/,/g, '*')
                        : existingProfiles.answer,
                    };
                  } else {
                    const checkAnswer =
                      existingProfiles.question === mdmsProfile.key &&
                      mdmsProfile.isMultiselect === 'false' &&
                      mdmsProfile.values
                        ?.map((value) => value.key)
                        .includes(existingProfiles.answer);

                    return {
                      ...existingProfiles,
                      ...mdmsProfile,
                      values: mdmsProfile.values?.map((value) => {
                        if (existingProfiles.answer === value.key) {
                          return {
                            ...value,
                            score: Number(existingProfiles.scoreText),
                          };
                        }
                        return value;
                      }),
                      otherValue: checkAnswer ? '' : existingProfiles.answer,
                      answer: checkAnswer ? existingProfiles.answer : 'others',
                    };
                  }
                })
              : [
                  {
                    ...RiskProfileObj,
                    question: mdmsProfile.key,
                    answer: '',
                    scoreText: '',
                    ...mdmsProfile,
                  },
                ];
            return newrisk;
          })
          .flat(),
        totalRiskProfileScore,
        riskQuetionnaire: response.section.map((sec) => {
          const setAnswer = sec.values
            ?.map((value) => value.key)
            .includes(investmentObjective || '');
          return {
            ...riskQuetionnaireObject,
            ...sec,
            answer:
              (sec.key === 'investmentObjective'
                ? setAnswer
                  ? investmentObjective
                  : investmentObjective
                  ? 'others'
                  : ''
                : sec.key === 'investmentHorizonAndGoalArray'
                ? investmentHorizon
                : '') || '',
            otherValue:
              sec.key === 'investmentObjective' ? (setAnswer ? '' : investmentObjective) : '',
            values:
              sec.key === 'investmentHorizonAndGoalArray'
                ? sec?.values?.map((value) => {
                    const check = value.key === investmentHorizon;
                    return {
                      ...value,
                      isChecked: check,
                      objective:
                        check && sec.key === 'investmentHorizonAndGoalArray' ? objective : '',
                      investmentAmount:
                        check && sec.key === 'investmentHorizonAndGoalArray'
                          ? investmentAmount
                          : '',
                    };
                  })
                : sec?.values,
          };
        }),
      };
      return defaultPayload;
    });
    !doNotWishToFillRiskProfile &&
      schemaData.map((applicant) => {
        if (!applicant.riskDocumentCheck) {
          const checkAllProfileQuestionsAnswered = applicant.riskprofiles
              ?.map((profile) => profile.answer !== '' && profile.required === 'true')
              ?.every((_profile) => _profile),
            checkAllRiskQuestionsAnswered = applicant.riskQuetionnaire.some(
              (section) => section.answer !== '' && section.required === 'true'
            );
          if (
            (!applicant.wishToAddRiskProfile && !checkAllProfileQuestionsAnswered) ||
            !checkAllRiskQuestionsAnswered
          ) {
            throw new RiskProfileErrors('Please fill all the required(*) fields');
          }
          if (!applicant.wishToAddRiskProfile && !applicant.riskProfileDeclaration) {
            throw new RiskProfileErrors('Declaration is required');
          }
          applicant.riskprofiles?.map((risk) => {
            if (
              !applicant.wishToAddRiskProfile &&
              (risk.answer === 'others' ||
                (risk.isMultiselect === 'true' && risk.answer.includes('others'))) &&
              !risk.otherValue &&
              risk.required === 'true'
            ) {
              const questionForDisplay = riskProfileDataMaster.riskProfile
                ?.map((riskProfile) => {
                  if (riskProfile.key === risk.question) {
                    return riskProfile.displayText;
                  }
                  return;
                })
                ?.filter((ele) => ele)
                ?.toString();
              throw new RiskProfileErrors('Please specify others in ' + questionForDisplay);
            }
            if (
              !applicant.wishToAddRiskProfile &&
              (risk.answer === 'others' ||
                (risk.isMultiselect === 'true' && risk.answer.includes('others'))) &&
              risk.otherValue
            ) {
              if (risk.otherValidation === 'stringRegex' && !stringRegex.test(risk.otherValue))
                throw new RiskProfileErrors(
                  'Special characters and numbers not allowed for others in ' + risk.displayText
                );
            }
            if (
              risk.answer &&
              risk.validation === 'oneOf' &&
              risk.isMultiselect === 'false' &&
              !risk.values?.map((val: any) => val.key).includes(risk.answer)
            ) {
              throw new RiskProfileErrors('Invalid ' + risk.displayText);
            }
            if (risk.answer && risk.isMultiselect === 'true' && risk.validation === 'oneOf') {
              if (
                !risk.answer
                  .split('*')
                  .filter((ans: any) =>
                    risk?.values?.map((val: any) => val.key).includes(ans.split('_')[0])
                  ).length
              )
                throw new RiskProfileErrors('Invalid ' + risk.displayText);
            }
          });

          applicant.riskQuetionnaire.map((section) => {
            if (section.answer === 'others' && !section.otherValue && section.required === 'true') {
              throw new RiskProfileErrors('Please specify others in ' + section.displayText);
            }
            if (!section.answer && section.required === 'true') {
              throw new RiskProfileErrors('Please specify ' + section.displayText);
            }
            if (section.answer === 'others' && section.otherValue) {
              if (
                section.otherValidation === 'stringRegex' &&
                !stringRegex.test(section.otherValue)
              )
                throw new RiskProfileErrors(
                  'Special characters and numbers not allowed for others in ' + section.displayText
                );
            }
            if (
              section.answer &&
              section.validation === 'oneOf' &&
              section.isMultiselect === 'false' &&
              !section.values?.map((val) => val.key).includes(section.answer)
            ) {
              throw new RiskProfileErrors('Invalid ' + section.displayText);
            }
            if (
              section.answer &&
              section.isMultiselect === 'true' &&
              section.validation === 'oneOf'
            ) {
              if (
                !section.answer
                  .split('*')
                  .filter((ans) =>
                    section?.values?.map((val) => val.key).includes(ans.split('_')[0])
                  ).length
              )
                throw new RiskProfileErrors('Invalid ' + section.displayText);
            }
            if (section.answer && section.key === 'investmentHorizonAndGoalArray') {
              if (!section.values?.some((val) => val.objective))
                throw new RiskProfileErrors('Please specify objective in ' + section.displayText);
              if (!section.values?.some((val) => val.investmentAmount))
                throw new RiskProfileErrors(
                  'Please specify investment amount in ' + section.displayText
                );
              if (!section.values?.find((val) => val.objective)?.objective?.match(stringRegex))
                throw new RiskProfileErrors(
                  'Special characters and numbers not allowed for objective in ' +
                    section.displayText
                );
            }
          });
        }
      });
  } catch (e) {
    if (e instanceof RiskProfileErrors) {
      throw e;
    }
  }
};

export const validateInvestorProfile = async (
  application: ApplicationProps | null,
  questionsInvestor: investorQuestionMdsm
) => {
  const { applicants = [], scheme } = application || {};
  try {
    const schemaData = applicants.map((applicant) => {
      const { investorprofile: existingInvestorprofile = null } = applicant || {};
      return {
        investorprofile: existingInvestorprofile
          ? AssignAnsAndVisibilityForInitialInvestorQuestion(
              questionsInvestor,
              existingInvestorprofile
            )
          : questionsInvestor,
        investorProfileDeclaration: applicant.investorProfileDeclaration || false,
        investorVerificationDeclaration: applicant.investorProfileDeclaration || false,
      };
    });
    schemaData.map((_profile) => {
      _profile.investorprofile.map((question: any) => {
        if (
          question.isVisible &&
          !question.answer &&
          question.isMandatory === 'true' &&
          checkInvestorProfilebasedOnScheme(scheme?.schemeCode || '')
        ) {
          throw new InvestorProfileErrors(
            'Please fill all the required(*) fields in investor Questionnaire'
          );
        }
      });
      if (
        (!_profile.investorProfileDeclaration || !_profile.investorVerificationDeclaration) &&
        checkInvestorProfilebasedOnScheme(scheme?.schemeCode || '')
      ) {
        throw new InvestorProfileErrors(
          'Please fill all the Declarations in investor Questionnaire'
        );
      }
    });
  } catch (e) {
    if (e instanceof InvestorProfileErrors) {
      throw e;
    }
  }
};

export const acknowledgeApplication =
  ({ body, applicationId }: { body: Partial<ApplicationProps>; applicationId: string }) =>
  async (dispatch: any): Promise<ApplicationProps> => {
    return await dispatch({
      [CALL_API]: {
        url: `/onboarding/esign/${applicationId}/acknowledge`,
        method: 'POST',
        types: [UPDATE_APPLICATION_SUCCESS, UPDATE_APPLICATION],
        body,
      },
    });
  };

export const provideConsentApplication =
  ({ body, referenceId }: { body: Declaration; referenceId: string }) =>
  async (dispatch: any): Promise<ApplicationProps> => {
    return await dispatch({
      [CALL_API]: {
        url: `/onboarding/applications/${referenceId}/signatory/consent`,
        method: 'POST',
        types: [UPDATE_APPLICATION_SUCCESS, UPDATE_APPLICATION],
        body,
      },
    });
  };

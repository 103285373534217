/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import * as yup from 'yup';
import {
  Bank,
  ControllingPersons,
  Groups,
  NomineeType,
  nonIndividualQuestionsFatca,
  ubo,
  Applicant,
} from '../redux-store/types/api-types';
import {
  accountNumberRegex,
  ifscRegex,
  stringRegex,
  addressRegex,
  phoneRegExp,
  nonIndividualPanRegex,
  emailRegex,
  individualPanRegex,
  numberRegex,
  amountRegex,
  alphaNumeric,
  indianPin,
  arnCodeRegex,
  ckycNumberRegex,
  kraStringRegex,
  numberWithDecimalsRegex,
  kraMobileNumberRegEx,
  validCommentRegex,
} from './regex';
import {
  isMinor,
  currencyConversion,
  maxAge,
  getCountryCodes,
  checkForCorrespondenceAddress,
  checkifFatcaYes,
  individualIndian,
  checkifFatcaYesAndTypeOthers,
  checkValidationBasedOnDate,
} from './utilityFunctions';
import { InternalOptions } from 'yup/lib/types';
import { Values } from '../components/investors/contactDetails';
import { Distributor, SubDistributor } from '../redux-store/actions';
import { Values as ContributorValues } from '../components/investors/contributorDetails';
import { Values as DocumentValues } from '../components/investors/documentDetails';
import { Values as NonIndividualDocValues } from '../components/NonIndividualInvestor/DocumentDetails';
import { getSubQuestionsIndex } from '../components/NonIndividualInvestor/fatca';
import {
  addressTypesMasters,
  adminSupportMaster,
  AdvisoryServices,
  applicantStatusMasters,
  APPLICATION_TYPE,
  BankAccountTypeMaster,
  COMPANIES_MASTER,
  CpUboCodesMaster,
  genderMasters,
  grossAnnualMasters,
  IdTypeMasters,
  InvalidCommentsMessage,
  investorTypeMasters,
  maritalStatusMaster,
  ModeOfOperationMaster,
  NomineeRelations,
  NRIBankAccountTypeMaster,
  occupationDetailsMasters,
  PEPsMasters,
  Relations,
  yesAndNoMasters,
  yesAndNoTrueAndFalseMasters,
} from './constant';
import { CountryCode, isValidPhoneNumber } from 'libphonenumber-js';
import { Values as NonIndividualContactValues } from '../components/NonIndividualInvestor/ContactDetails';
import { Values as individualFatcaValues } from '../components/investors/fatca';
import { updateNominee } from '../components/investors/nomineeDetails';
import { FundProps } from '../redux-store/types/funds';
import { mdmsCountriesList } from '../redux-store/types/mdms';
import { defaultRegexWithSingleLine } from './regex';

export const contributorDetailsSchema = yup.object().shape({
  applicants: yup.array().of(
    yup.object().shape({
      name: yup
        .string()
        .nullable()
        .matches(kraStringRegex, 'Special characters and numbers not allowed')
        .required('Name is required'),
      maritalStatus: yup
        .string()
        .nullable()
        .required('Marital Status is required')
        .oneOf(Object.keys(maritalStatusMaster), 'Invalid value for Marital Status'),
      gender: yup
        .string()
        .nullable()
        .required('Please select gender')
        .oneOf(genderMasters, 'Invalid value for Gender'),
      motherName: yup
        .string()
        .nullable()
        .matches(stringRegex, 'Special characters and numbers not allowed'),
      fatherOrSpouseName: yup
        .string()
        .nullable()
        .matches(kraStringRegex, 'Special characters and numbers not allowed')
        .required('Father/Spouse Name is required'),
      dateOfBirth: yup
        .string()
        .nullable()
        .test(
          'dateOfBirth',
          'Age should be greater than 18',
          (dateOfBirth) => !isMinor(dateOfBirth || '')
        )
        .required('Date of Birth is required'),
      status: yup
        .string()
        .nullable()
        .oneOf(Object.keys(applicantStatusMasters), 'Invalid value for Status')
        .required('Status is required'),
      nationality: yup
        .string()
        .nullable()
        .test('nationality', (value, context) => {
          const fieldValue = value === null ? '' : value;
          const { options, createError, path, parent } = context;
          const { context: optionsContext } = options as InternalOptions<ContributorValues>;
          const { nationalityDropdown = [] } = optionsContext || {};
          if (!fieldValue) {
            return createError({
              message: 'Nationality is required',
              path,
            });
          }
          if (!nationalityDropdown.includes(fieldValue)) {
            return createError({
              message: 'Invalid value for Nationality',
              path,
            });
          }
          // if (
          //   applicantStatusMasters[parent.status] === applicantStatusMasters.NRI &&
          //   value?.toUpperCase() !== 'INDIAN'
          // ) {
          //   return createError({
          //     message: 'Invalid value for Nationality',
          //     path,
          //   });
          // }
          return true;
        }),
      jointApplicantRelation: yup
        .string()
        .nullable()
        .test('jointApplicationRelation', (value, context) => {
          const fieldValue = value === null ? '' : value;
          const { options, createError, path } = context;
          const { index } = options as InternalOptions<ContributorValues> & {
            index: number;
          };

          if (index !== 0) {
            if (!fieldValue) {
              return createError({
                message: 'Relationship with first applicant is required',
                path,
              });
            }
            if (fieldValue && !Relations.includes(fieldValue)) {
              return createError({
                message: 'Invalid value for Relationship with first applicant',
                path,
              });
            }
          }
          return true;
        }),
      relationShipWithFirstApplicant: yup
        .string()
        .nullable()
        .when('jointApplicantRelation', {
          is: (jointApplicantRelation: string | null) => jointApplicantRelation === 'Others',
          then: yup
            .string()
            .nullable()
            .matches(stringRegex, 'Special characters and numbers not allowed')
            .required('Please Specify Relationship'),
        }),
      aadhaarNumber: yup
        .string()
        .nullable()
        .test('aadhaarNumber', (value, context) => {
          const { createError, path, parent } = context;
          if ((!value && individualIndian(parent)) || (value && value?.length !== 4)) {
            return createError({
              message: 'Please Enter Aadhaar Last 4 digits',
              path,
            });
          }
          return true;
        })
        // .required('Please Specify Aadhaar Last 4 digits')
        .matches(numberRegex, 'Aadhaar Number allows only digits'),
      isExistingRelationship: yup
        .string()
        .nullable()
        .required('Existing Relationship is required')
        .oneOf(Object.keys(yesAndNoMasters), 'Invalid Existing Relationship'),
      existingRelationshipNo: yup
        .string()
        .nullable()
        .when('isExistingRelationship', {
          is: (isExistingRelationship: string) =>
            yesAndNoMasters[isExistingRelationship || ''] === yesAndNoMasters.yes,
          then: yup
            .string()
            .nullable()
            .required('Existing Relationship No is required')
            .matches(alphaNumeric, 'Special characters not allowed'),
        }),
      parentId: yup.string().nullable().matches(alphaNumeric, 'Special characters not allowed'),
      parentIdDeclaration: yup
        .bool()
        .nullable()
        .test('parentIdDeclaration', 'Declaration is required', (parentIdDeclaration: any) =>
          parentIdDeclaration ? true : false
        ),
      panNo: yup
        .string()
        .nullable()
        .uppercase()
        .length(10, 'PAN must be exactly 10 characters')
        .matches(individualPanRegex, 'Only individual PANs are allowed'),
    })
  ),
  modeOfOperation: yup
    .string()
    .nullable()
    .when('applicants', {
      is: (applicants: Applicant[]) => applicants.length > 1,
      then: yup
        .string()
        .nullable()
        .test('modeOfOperation', 'Invalid Mode Of Operation', (modeOfOperation?: string | null) =>
          modeOfOperation ? Object.keys(ModeOfOperationMaster).includes(modeOfOperation) : true
        ),
    }),
});

const validateOverseasAddressFields = (
  value: string | null | undefined,
  context: yup.TestContext<Record<string, Values>>
) => {
  const fieldValue = !value ? '' : value;
  const { options } = context;
  const { context: optionsContext, index } = options as InternalOptions<Values> & {
    index: number;
  };
  const { applicants = [] } = optionsContext || {};
  const currentApplicant = applicants[index] || {};
  const { nationality, status } = currentApplicant.address;
  return checkForCorrespondenceAddress(nationality, status) ? true : fieldValue !== '';
};

const validatePermanentAddressFields = (
  value: string | null | undefined,
  context: yup.TestContext<Record<string, Values>>,
  applicationType: string
) => {
  const fieldValue = !value ? '' : value;
  const { options } = context;
  const { context: optionsContext, index } = options as InternalOptions<Values> & {
    index: number;
  };
  const { applicants = [] } = optionsContext || {};
  const currentApplicant = applicants[index] || {};
  const { nationality, status = '' } = currentApplicant.address;
  const { permanentAddressSameAsCorresponding = false } =
    checkForCorrespondenceAddress(nationality, status) ||
    APPLICATION_TYPE.NON_INDIVIDUAL === applicationType
      ? currentApplicant.address.correspondence || {}
      : currentApplicant.address.overseas || {};
  return permanentAddressSameAsCorresponding ? true : fieldValue !== '';
};

const validatePincodeField = (
  value: string | null | undefined,
  context: yup.TestContext<Record<string, Values>>,
  addressType: string
) => {
  const fieldValue = value === undefined ? '' : value;
  const { options, path, createError, parent } = context;
  const { context: optionsContext, index } = options as InternalOptions<Values> & {
    index: number;
  };
  const { applicants = [] } = optionsContext || {};
  const currentApplicant = applicants[index] || {};
  const { nationality, status = '' } = currentApplicant.address;
  if (addressType === 'correspondence' && !checkForCorrespondenceAddress(nationality, status)) {
    return true;
  }
  if (addressType === 'overseas' && checkForCorrespondenceAddress(nationality, status)) {
    return true;
  }
  if (!fieldValue) {
    return createError({
      message: 'Pincode is required',
      path,
    });
  }
  if (parent.country?.toLowerCase() === 'india' && !indianPin.test(fieldValue)) {
    return createError({
      message: 'Pincode should be 6 digit code',
      path,
    });
  }
  if (parent.country?.toLowerCase() !== 'india' && !alphaNumeric.test(fieldValue)) {
    return createError({
      message: 'Invalid Pincode',
      path,
    });
  }

  return true;
};

const validateCorrespondenceAddressFields = (
  value: string | null | undefined,
  context: yup.TestContext<Record<string, Values>>
) => {
  const fieldValue = !value ? '' : value;
  const { options } = context;
  const { context: optionsContext, index } = options as InternalOptions<Values> & {
    index: number;
  };
  const { applicants = [] } = optionsContext || {};
  const currentApplicant = applicants[index] || {};
  const { nationality, status = '' } = currentApplicant.address;
  return checkForCorrespondenceAddress(nationality, status) ? fieldValue !== '' : true;
};

export const contactDetailsSchema = yup.object().shape({
  applicants: yup.array().of(
    yup.object().shape({
      phoneNumberoffice: yup
        .string()
        .nullable()
        .matches(numberRegex, 'Phone(Office) allows only digits'),
      phoneNumberResidence: yup
        .string()
        .nullable()
        .matches(numberRegex, 'Phone(Residential) allows only digits'),
      kraMobileNumber: yup
        .string()
        .nullable()
        .matches(kraMobileNumberRegEx, 'KRA Mobile Number allows only digits'),
      email: yup
        .string()
        .nullable()
        .matches(emailRegex, 'invalid Email ID')
        .required('Email is required'),
      mobile: yup
        .string()
        .nullable()
        .test('mobile', (value, context) => {
          const { createError, path, parent } = context;
          const codesList = getCountryCodes()
            .map((codes) => {
              if (codes.label === parent.countryNameAndCode) {
                return codes.countryCode;
              }
            })
            .filter((ele) => ele)
            .toString();
          if (!value) {
            return createError({
              message: 'Mobile is required',
              path,
            });
          }
          if (parent.countryNameAndCode === 'India: +91' && !phoneRegExp.test(value)) {
            return createError({
              message: 'Invalid Mobile number',
              path,
            });
          }
          if (!isValidPhoneNumber(value, codesList as CountryCode)) {
            return createError({
              message: 'Invalid Mobile number',
              path,
            });
          }

          return true;
        }),
      countryNameAndCode: yup
        .string()
        .nullable()
        .test('countryNameAndCode', (value, context) => {
          const { createError, path, parent } = context;
          const codesList = getCountryCodes()
            .map((codes) => {
              if (codes.label === value) {
                return codes.countryCode;
              }
            })
            .filter((ele) => ele)
            .toString();
          if (!value) {
            return createError({
              message: 'Country Code is required',
              path,
            });
          }
          if (parent.mobile && !isValidPhoneNumber(parent.mobile, codesList as CountryCode)) {
            return createError({
              message: 'Invalid Mobile number',
              path,
            });
          }

          return true;
        }),
      address: yup.object().shape({
        correspondence: yup.object().shape({
          address1: yup
            .string()
            .nullable()
            .matches(
              addressRegex,
              "Invalid address line 1 format [special characters are not allowed except -/',&()#:.]"
            )
            .test('address1', 'Address line 1 is required', (value, context) =>
              validateCorrespondenceAddressFields(value, context)
            ),
          address2: yup
            .string()
            .nullable()
            .matches(
              addressRegex,
              "Invalid address line 2 format [special characters are not allowed except -/',&()#:.]"
            )
            .test('address2', 'Address line 2 is required', (value, context) => {
              const { options } = context;
              const { context: optionsContext, index } = options as InternalOptions<Values> & {
                index: number;
              };
              const { applicants = [] } = optionsContext || {};
              const currentApplicant = applicants[index] || {};
              const { fetchedFromKRA = null } = currentApplicant.address.correspondence || {};
              if (!fetchedFromKRA) {
                return validateCorrespondenceAddressFields(value, context);
              }
              return true;
            }),
          pincode: yup
            .string()
            .nullable()
            .test('pincode', (value, context) =>
              validatePincodeField(value, context, 'correspondence')
            ),
          city: yup
            .string()
            .nullable()
            .matches(
              addressRegex,
              "Invalid City format [special characters are not allowed except -/',&()#:.]"
            )
            .test('city', 'City is required', (value, context) =>
              validateCorrespondenceAddressFields(value, context)
            ),
          state: yup
            .string()
            .nullable()
            .matches(stringRegex, 'Special characters and numbers not allowed')
            .test('state', 'State is required', (value, context) =>
              validateCorrespondenceAddressFields(value, context)
            ),
          // country: yup
          // .string()
          // .nullable()
          // .test('country', 'Country is required', (value, context) =>
          // validateCorrespondenceAddressFields(value, context)
          // ),
          country: yup
            .string()
            .nullable()
            .test('country', (value, context) => {
              const { options, createError, path } = context;
              const { context: optionsContext, index } = options as InternalOptions<Values> & {
                index: number;
              };
              const { countryDropdown = [], applicants = [] } = optionsContext || {};
              const currentApplicant = applicants[index] || {};
              const { nationality, status = '' } = currentApplicant.address;

              if (!value && checkForCorrespondenceAddress(nationality, status)) {
                return createError({
                  message: 'Country is required',
                  path,
                });
              }
              if (
                value &&
                checkForCorrespondenceAddress(nationality, status) &&
                !countryDropdown.includes(value)
              ) {
                return createError({
                  message: 'Invalid value for Country',
                  path,
                });
              }
              return true;
            }),
        }),
        overseas: yup.object().shape({
          address1: yup
            .string()
            .nullable()
            .matches(
              addressRegex,
              "Invalid address line 1 format [special characters are not allowed except -/',&()#:.]"
            )
            .test('address1', 'Address line 1 is required', (value, context) =>
              validateOverseasAddressFields(value, context)
            ),
          address2: yup
            .string()
            .nullable()
            .matches(
              addressRegex,
              "Invalid address line 2 format [special characters are not allowed except -/',&()#:.]"
            )
            .test('address2', 'Address line 2 is required', (value, context) =>
              validateOverseasAddressFields(value, context)
            ),
          pincode: yup
            .string()
            .nullable()
            .test('pincode', (value, context) => validatePincodeField(value, context, 'overseas')),
          city: yup
            .string()
            .nullable()
            .matches(
              addressRegex,
              "Invalid City format [special characters are not allowed except -/',&()#:.]"
            )
            .test('city', 'City is required', (value, context) =>
              validateOverseasAddressFields(value, context)
            ),
          state: yup
            .string()
            .nullable()
            .matches(stringRegex, 'Special characters and numbers not allowed'),
          //   .test('state', 'State is required', (value, context) =>
          //     validateOverseasAddressFields(value, context)
          //   ),
          country: yup
            .string()
            .nullable()
            .test('country', (value, context) => {
              const { options, createError, path } = context;
              const { context: optionsContext, index } = options as InternalOptions<Values> & {
                index: number;
              };
              const { applicants = [], countryDropdown = [] } = optionsContext || {};
              const currentApplicant = applicants[index] || {};
              const { nationality, status = '' } = currentApplicant.address;
              if (!value && checkForCorrespondenceAddress(nationality, status)) {
                return true;
              }
              if (!value) {
                return createError({
                  message: 'Country is required',
                  path,
                });
              }
              if (value.toUpperCase() === 'INDIA') {
                return createError({
                  message: "Country can't be choosen as INDIA",
                  path,
                });
              }
              if (!countryDropdown.includes(value)) {
                return createError({
                  message: 'Invalid value for Country',
                  path,
                });
              }
              return true;
            }),
          // country: yup
          //   .string()
          //   .nullable()
          //   .test('country', 'Country is required', (value, context) =>
          //     validateOverseasAddressFields(value, context)
          //   ),
        }),
        permanent: yup.object().shape({
          address1: yup
            .string()
            .nullable()
            .matches(
              addressRegex,
              "Invalid address line 1 format [special characters are not allowed except -/',&()#:.]"
            )
            .test('address1', 'Address line 1 is required', (value, context) =>
              validatePermanentAddressFields(value, context, APPLICATION_TYPE.INDIVIDUAL)
            ),
          address2: yup
            .string()
            .nullable()
            .matches(
              addressRegex,
              "Invalid address line 2 format [special characters are not allowed except -/',&()#:.]"
            )
            // .test('address2', 'Address2 is required', (value, context) =>
            //   validatePermanentAddressFields(value, context, APPLICATION_TYPE.INDIVIDUAL)
            // ),
            .test('address2', 'Address line 2 is required', (value, context) => {
              const { options } = context;
              const { context: optionsContext, index } = options as InternalOptions<Values> & {
                index: number;
              };
              const { applicants = [] } = optionsContext || {};
              const currentApplicant = applicants[index] || {};
              const { fetchedFromKRA = null } = currentApplicant.address.permanent || {};
              if (!fetchedFromKRA) {
                return validatePermanentAddressFields(value, context, APPLICATION_TYPE.INDIVIDUAL);
              }
              return true;
            }),
          pincode: yup
            .string()
            .trim()
            .nullable()
            .test('pincode', (value, context) => {
              const { options } = context;
              const { context: optionsContext, index } = options as InternalOptions<Values> & {
                index: number;
              };
              const { applicants = [] } = optionsContext || {};
              const currentApplicant = applicants[index] || {};
              const { nationality, status = '' } = currentApplicant.address;
              const { permanentAddressSameAsCorresponding = false } = checkForCorrespondenceAddress(
                nationality,
                status
              )
                ? currentApplicant.address.correspondence || {}
                : currentApplicant.address.overseas || {};
              if (!permanentAddressSameAsCorresponding) {
                return validatePincodeField(value, context, 'permanent');
              }
              return true;
            }),
          city: yup
            .string()
            .nullable()
            .matches(
              addressRegex,
              "Invalid City format [special characters are not allowed except -/',&()#:.]"
            )
            .test('city', 'City is required', (value, context) =>
              validatePermanentAddressFields(value, context, APPLICATION_TYPE.INDIVIDUAL)
            ),
          state: yup
            .string()
            .nullable()
            .matches(stringRegex, 'Special characters and numbers not allowed')
            .test('state', 'State is required', (value, context) =>
              validatePermanentAddressFields(value, context, APPLICATION_TYPE.INDIVIDUAL)
            ),
          country: yup
            .string()
            .nullable()
            .test('country', (value, context) => {
              const { options, createError, path } = context;
              const { context: optionsContext, index } = options as InternalOptions<Values> & {
                index: number;
              };
              const { countryDropdown = [] } = optionsContext || {};
              const { applicants = [] } = optionsContext || {};
              const currentApplicant = applicants[index] || {};
              const { nationality, status } = currentApplicant.address;
              const { permanentAddressSameAsCorresponding = false } = checkForCorrespondenceAddress(
                nationality,
                status
              )
                ? currentApplicant.address.correspondence || {}
                : currentApplicant.address.overseas || {};
              if (!permanentAddressSameAsCorresponding) {
                if (!value) {
                  return createError({
                    message: 'Country is required',
                    path,
                  });
                }
                // if (
                //   applicantStatusMasters[status || ''] === applicantStatusMasters.NRI &&
                //   value.toUpperCase() === 'INDIA'
                // ) {
                //   return createError({
                //     message:
                //       "If the Status is Individual (NRI) then Country can't be choosen as INDIA",
                //     path,
                //   });
                // }
                if (!countryDropdown.includes(value)) {
                  return createError({
                    message: 'Invalid value for Country',
                    path,
                  });
                }
              }
              return true;
            }),
          // country: yup
          //   .string()
          //   .nullable()
          //   .test('country', 'Country is required', (value, context) =>
          //     validatePermanentAddressFields(value, context)
          //   ),
        }),
      }),
    })
  ),
});

export const KYCDetailsSchema = (dematFieldValidation: string) =>
  yup.object().shape({
    applicants: yup.array().of(
      yup.object().shape({
        occupationDetails: yup
          .string()
          .nullable()
          .oneOf(Object.keys(occupationDetailsMasters), 'Invalid value for occupation details')
          .required('Occupation details is required'),
        otherOccupation: yup
          .string()
          .nullable()
          .when('occupationDetails', {
            is: (occupationDetails: string) =>
              occupationDetailsMasters[occupationDetails] === occupationDetailsMasters.others,
            then: yup
              .string()
              .nullable()
              .matches(stringRegex, 'Special characters and numbers not allowed')
              .required('Other Occupation is required'),
          }),
        politicallyExposedPersonStatus: yup
          .string()
          .nullable()
          .oneOf(PEPsMasters, 'Invalid value for PEP Status')
          .required('PEP status is required'),
        ckycNo: yup
          .number()
          .nullable()
          .test('ckycNo', (value, context) => {
            const {
              options: { context: optionsContext },
              createError,
              path,
            } = context;
            const { applicants } = optionsContext || {};
            if (!value) {
              return createError({
                message: 'CKYC No is required',
                path,
              });
            }
            if (!ckycNumberRegex.test(value?.toString())) {
              return createError({
                message: 'Invalid CKYC No,Only 14 Digits Allowed',
                path,
              });
            }
            if (
              applicants.filter(
                (applicant: Applicant) => applicant.ckycNo?.toString() === value.toString()
              ).length !== 1
            ) {
              return createError({
                message:
                  'There is already same CKYC Number for an applicant associated with this application',
                path,
              });
            }
            return true;
          }),
        // kinNo: yup.number().test('kinNo', (value, context) => {
        //   const {
        //     options: { context: optionsContext },
        //     createError,
        //     path,
        //   } = context;
        //   const { applicants } = optionsContext || {};
        //   if (!value) {
        //     return true;
        //   }
        //   if (value?.toString().length !== 14) {
        //     return createError({
        //       message: 'Invalid KIN No',
        //       path,
        //     });
        //   }
        //   if (
        //     applicants.filter(
        //       (applicant: Applicant) => applicant.kinNo?.toString() === value.toString()
        //     ).length !== 1
        //   ) {
        //     return createError({
        //       message:
        //         'There is already same KIN Number for an applicant associated with this application',
        //       path,
        //     });
        //   }
        //   return true;
        // }),
        isAccreditedInvestor: yup
          .string()
          .nullable()
          .oneOf(Object.keys(yesAndNoMasters), 'Invalid value for Accredited Investor')
          .required('Accredited Investor is required'),
        certificateNo: yup
          .string()
          .nullable()
          .when('isAccreditedInvestor', {
            is: (isAccreditedInvestor: string) =>
              yesAndNoMasters.yes === yesAndNoMasters[isAccreditedInvestor],
            then: yup
              .string()
              .nullable()
              .matches(alphaNumeric, 'Special characters not allowed')
              .required('Certificate No is required'),
          }),
        issuingAuthority: yup
          .string()
          .nullable()
          .when('isAccreditedInvestor', {
            is: (isAccreditedInvestor: string) =>
              yesAndNoMasters.yes === yesAndNoMasters[isAccreditedInvestor],
            then: yup
              .string()
              .nullable()
              .matches(alphaNumeric, 'Special characters not allowed')
              .required('Issuing Authority is required'),
          }),
        accreditedInvestorFrom: yup
          .string()
          .nullable()
          .when('isAccreditedInvestor', {
            is: (isAccreditedInvestor: string) =>
              yesAndNoMasters.yes === yesAndNoMasters[isAccreditedInvestor],
            then: yup.string().nullable().required('Accredited Investor From is required'),
          }),
        accreditedInvestorTo: yup
          .string()
          .nullable()
          .when('isAccreditedInvestor', {
            is: (isAccreditedInvestor: string) =>
              yesAndNoMasters.yes === yesAndNoMasters[isAccreditedInvestor],
            then: yup
              .string()
              .nullable()
              .required('Accredited Investor To is required')
              .test('accreditedInvestorTo', (value, context) => {
                const {
                  options: { context: optionsContext },
                  parent,
                  createError,
                  path,
                } = context;
                if (value && value < parent.accreditedInvestorFrom) {
                  return createError({
                    message: 'Accredited Investor To should be more then Accredited Investor From',
                    path,
                  });
                }
                return true;
              }),
          }),
        grossAnnualIncome: yup
          .string()
          .nullable()
          .test(
            'grossAnnualIncome',
            'Invalid value for gross annual income',
            (grossAnnualIncome?: string | null) =>
              grossAnnualIncome ? Object.keys(grossAnnualMasters).includes(grossAnnualIncome) : true
          ),
      })
    ),
    dpName: yup
      .string()
      .nullable()
      // .required('DP Name is required')
      .matches(kraStringRegex, 'Special characters and numbers not allowed')
      .test('dpName', 'DP Name is required', (value, context) => {
        const { parent } = context;
        return (
          (!value && !checkValidationBasedOnDate(parent?.createdAt || '', dematFieldValidation)) ||
          !!value
        );
      }),
    dpId: yup
      .string()
      .nullable()
      // .required('DP Id is required')
      .matches(alphaNumeric, 'Special characters not allowed')
      .test('dpId', 'DP Id is required', (value, context) => {
        const { parent } = context;
        return (
          (!value && !checkValidationBasedOnDate(parent?.createdAt || '', dematFieldValidation)) ||
          !!value
        );
      }),
    dpClientId: yup
      .string()
      .nullable()
      // .required('Client Id is required')
      .matches(alphaNumeric, 'Special characters not allowed')
      .test('dpClientId', 'Client Id is required', (value, context) => {
        const { parent } = context;
        return (
          (!value && !checkValidationBasedOnDate(parent?.createdAt || '', dematFieldValidation)) ||
          !!value
        );
      }),
  });

const createSchemaForNonIndianResident = (validationMessage: string, key: string) => {
  return {
    is: (
      taxResidentOfAnyCountryOtherThanIndia: boolean | undefined,
      citizenOfAnyCountryOtherThanIndia: string | null | undefined,
      countryOfBirthOtherThanIndia: string | null | undefined,
      poaMandateHolderOutsideIndia: string | null | undefined,
      addressTelephoneOutsideIndia: string | null | undefined
    ) =>
      checkifFatcaYes(
        taxResidentOfAnyCountryOtherThanIndia,
        citizenOfAnyCountryOtherThanIndia,
        countryOfBirthOtherThanIndia,
        poaMandateHolderOutsideIndia,
        addressTelephoneOutsideIndia
      ),
    then: yup
      .string()
      .nullable()
      .required(validationMessage)
      .test(key, (value, context) => {
        const fieldValue = value === null ? '' : value;
        const { options, createError, path, parent } = context;
        const { context: optionsContext } = options as InternalOptions<individualFatcaValues>;
        const { countryDropdown = [] } = optionsContext || {};
        if (fieldValue && key === 'taxCountryName' && !stringRegex.test(fieldValue)) {
          return createError({
            message: 'Special characters and numbers not allowed',
            path,
          });
        }
        if (fieldValue && key === 'taxID' && !alphaNumeric.test(fieldValue)) {
          return createError({
            message: 'Special characters are not allowed',
            path,
          });
        }
        if (fieldValue && key === 'idType' && !IdTypeMasters.includes(fieldValue)) {
          return createError({
            message: 'Invalid ID Type',
            path,
          });
        }
        return true;
      }),
  };
};

const createSchemaForNRIAndTypeOthers = (validationMessage: string) => {
  return {
    is: (
      taxResidentOfAnyCountryOtherThanIndia: boolean | undefined,
      citizenOfAnyCountryOtherThanIndia: string | null | undefined,
      countryOfBirthOtherThanIndia: string | null | undefined,
      poaMandateHolderOutsideIndia: string | null | undefined,
      addressTelephoneOutsideIndia: string | null | undefined,
      idType: string | null | undefined
    ) =>
      checkifFatcaYesAndTypeOthers(
        taxResidentOfAnyCountryOtherThanIndia,
        citizenOfAnyCountryOtherThanIndia,
        countryOfBirthOtherThanIndia,
        poaMandateHolderOutsideIndia,
        addressTelephoneOutsideIndia,
        idType
      ),
    then: yup
      .string()
      .nullable()
      .matches(stringRegex, 'Special characters and numbers not allowed')
      .required(validationMessage),
  };
};

export const FATCAValidationSchema = yup.object().shape({
  applicants: yup.array().of(
    yup.object().shape({
      typeOfAddressProvidedAtKRA: yup
        .string()
        .nullable()
        .when('fatcaCrsDocumentCheck', {
          is: (fatcaCrsDocumentCheck: boolean | null) => !fatcaCrsDocumentCheck,
          then: yup
            .string()
            .nullable()
            .oneOf(addressTypesMasters, 'Invalid value for Type of Address Provided At KRA')
            .required('Please select type of address provided'),
        }),
      placeOfBirth: yup
        .string()
        .nullable()
        .when('fatcaCrsDocumentCheck', {
          is: (fatcaCrsDocumentCheck: boolean | null) => !fatcaCrsDocumentCheck,
          then: yup
            .string()
            .nullable()
            .matches(stringRegex, 'Special characters and numbers not allowed')
            .required('Please enter place of birth'),
        }),
      // countryOfBirth: yup
      //   .string()
      //   .required('Please enter country of birth')
      //   .matches(stringRegex, 'Please enter country of birth'),
      countryOfBirth: yup
        .string()
        .nullable()
        .when('fatcaCrsDocumentCheck', {
          is: (fatcaCrsDocumentCheck: boolean | null) => !fatcaCrsDocumentCheck,
          then: yup
            .string()
            .nullable()
            .test('countryOfBirth', (value, context) => {
              const fieldValue = value === null ? '' : value;
              const { options, createError, path, parent } = context;
              const { context: optionsContext } = options as InternalOptions<individualFatcaValues>;
              const { countryDropdown = [] } = optionsContext || {};
              if (!fieldValue) {
                return createError({
                  message: 'country of birth is required',
                  path,
                });
              }
              if (!countryDropdown.includes(fieldValue)) {
                return createError({
                  message: 'Invalid value for country of birth',
                  path,
                });
              }
              return true;
            }),
        }),

      // countryOfNationality: yup
      //   .string()
      //   .required('Please enter country of nationality')
      //   .matches(stringRegex, 'Please enter country of nationality'),
      countryOfNationality: yup
        .string()
        .nullable()
        .when('fatcaCrsDocumentCheck', {
          is: (fatcaCrsDocumentCheck: boolean | null) => !fatcaCrsDocumentCheck,
          then: yup
            .string()
            .nullable()
            .test('countryOfNationality', (value, context) => {
              const fieldValue = value === null ? '' : value;
              const { options, createError, path, parent } = context;
              const { context: optionsContext } = options as InternalOptions<individualFatcaValues>;
              const { countryDropdown = [] } = optionsContext || {};
              if (!fieldValue) {
                return createError({
                  message: 'country of nationality is required',
                  path,
                });
              }
              if (!countryDropdown.includes(fieldValue)) {
                return createError({
                  message: 'Invalid value for country of nationality',
                  path,
                });
              }
              return true;
            }),
        }),
      taxCountryName: yup.string().when('fatcaCrsDocumentCheck', {
        is: (fatcaCrsDocumentCheck: boolean | null) => !fatcaCrsDocumentCheck,
        then: yup
          .string()
          .nullable()
          .when(
            [
              'taxResidentOfAnyCountryOtherThanIndia',
              'citizenOfAnyCountryOtherThanIndia',
              'countryOfBirthOtherThanIndia',
              'poaMandateHolderOutsideIndia',
              'addressTelephoneOutsideIndia',
            ],
            createSchemaForNonIndianResident('Please enter tax country name', 'taxCountryName')
          ),
      }),
      taxID: yup.string().when('fatcaCrsDocumentCheck', {
        is: (fatcaCrsDocumentCheck: boolean | null) => !fatcaCrsDocumentCheck,
        then: yup
          .string()
          .nullable()
          .max(20, 'Invalid Tax ID')
          .when(
            [
              'taxResidentOfAnyCountryOtherThanIndia',
              'citizenOfAnyCountryOtherThanIndia',
              'countryOfBirthOtherThanIndia',
              'poaMandateHolderOutsideIndia',
              'addressTelephoneOutsideIndia',
            ],
            createSchemaForNonIndianResident('Please enter Tax ID', 'taxID')
          ),
      }),
      idType: yup.string().when('fatcaCrsDocumentCheck', {
        is: (fatcaCrsDocumentCheck: boolean | null) => !fatcaCrsDocumentCheck,
        then: yup
          .string()
          .nullable()
          .when(
            [
              'taxResidentOfAnyCountryOtherThanIndia',
              'citizenOfAnyCountryOtherThanIndia',
              'countryOfBirthOtherThanIndia',
              'poaMandateHolderOutsideIndia',
              'addressTelephoneOutsideIndia',
            ],
            createSchemaForNonIndianResident('Please enter Tax ID', 'idType')
          ),
      }),
      idTypeOthers: yup.string().when('fatcaCrsDocumentCheck', {
        is: (fatcaCrsDocumentCheck: boolean | null) => !fatcaCrsDocumentCheck,
        then: yup
          .string()
          .nullable()
          .when(
            [
              'taxResidentOfAnyCountryOtherThanIndia',
              'citizenOfAnyCountryOtherThanIndia',
              'countryOfBirthOtherThanIndia',
              'poaMandateHolderOutsideIndia',
              'addressTelephoneOutsideIndia',
              'idType',
            ],
            createSchemaForNRIAndTypeOthers('Please Specify ID Type')
          ),
      }),
      othersTinNotAvailableReason: yup.string().when('fatcaCrsDocumentCheck', {
        is: (fatcaCrsDocumentCheck: boolean | null) => !fatcaCrsDocumentCheck,
        then: yup
          .string()
          .nullable()
          .when(
            [
              'taxResidentOfAnyCountryOtherThanIndia',
              'citizenOfAnyCountryOtherThanIndia',
              'countryOfBirthOtherThanIndia',
              'poaMandateHolderOutsideIndia',
              'addressTelephoneOutsideIndia',
              'idType',
            ],
            {
              is: (
                taxResidentOfAnyCountryOtherThanIndia: boolean | undefined,
                citizenOfAnyCountryOtherThanIndia: string | null | undefined,
                countryOfBirthOtherThanIndia: string | null | undefined,
                poaMandateHolderOutsideIndia: string | null | undefined,
                addressTelephoneOutsideIndia: string | null | undefined,
                idType: string | null | undefined
              ) =>
                checkifFatcaYesAndTypeOthers(
                  taxResidentOfAnyCountryOtherThanIndia,
                  citizenOfAnyCountryOtherThanIndia,
                  countryOfBirthOtherThanIndia,
                  poaMandateHolderOutsideIndia,
                  addressTelephoneOutsideIndia,
                  idType
                ),
              then: yup
                .string()
                .nullable()
                .matches(stringRegex, 'Special characters and numbers not allowed')
                .required('Please Specify Reason'),
            }
          ),
      }),
      // nameOfEntity: yup
      //   .string()
      //   .when(
      //     'taxResidentOfAnyCountryOtherThanIndia',
      //     buildValidationSchema('Please enter name of entity')
      //   ),
      // dateOfIncorporation: yup
      //   .string()
      //   .when(
      //     'taxResidentOfAnyCountryOtherThanIndia',
      //     buildValidationSchema('Please enter date of incorporation')
      //   ),
      // cityOfIncorporation: yup
      //   .string()
      //   .when(
      //     'taxResidentOfAnyCountryOtherThanIndia',
      //     buildValidationSchema('Please enter city of incorporation')
      //   ),
      // countryOfIncorporation: yup
      //   .string()
      //   .when(
      //     'taxResidentOfAnyCountryOtherThanIndia',
      //     buildValidationSchema('Please enter country of incorporation')
      //   ),
      // entityExcemptionCode: yup
      //   .string()
      //   .when(
      //     'taxResidentOfAnyCountryOtherThanIndia',
      //     buildValidationSchema('Please enter entity exemption code')
      //   ),
      citizenOfAnyCountryOtherThanIndia: yup
        .string()
        .nullable()
        .when('fatcaCrsDocumentCheck', {
          is: (fatcaCrsDocumentCheck: boolean | null) => !fatcaCrsDocumentCheck,
          then: yup
            .string()
            .nullable()
            .oneOf(Object.keys(yesAndNoMasters), 'Invalid Citizen of any country other than India')
            .required('Please select citizen of any country other than India'),
        }),
      countryOfBirthOtherThanIndia: yup
        .string()
        .nullable()
        .when('fatcaCrsDocumentCheck', {
          is: (fatcaCrsDocumentCheck: boolean | null) => !fatcaCrsDocumentCheck,
          then: yup
            .string()
            .nullable()
            .oneOf(
              Object.keys(yesAndNoMasters),
              'Invalid Country of birth is any country other than India'
            )
            .required('Please select Country of birth is any country other than India'),
        }),
      taxResidentOfAnyCountryOtherThanIndia: yup
        .string()
        .nullable()
        .when('fatcaCrsDocumentCheck', {
          is: (fatcaCrsDocumentCheck: boolean | null) => !fatcaCrsDocumentCheck,
          then: yup
            .string()
            .nullable()
            .oneOf(
              Object.keys(yesAndNoTrueAndFalseMasters),
              'Invalid Tax Resident of any country other than India'
            )
            .required('Please select Tax Resident of any country other than India'),
        }),
      poaMandateHolderOutsideIndia: yup
        .string()
        .nullable()
        .when('fatcaCrsDocumentCheck', {
          is: (fatcaCrsDocumentCheck: boolean | null) => !fatcaCrsDocumentCheck,
          then: yup
            .string()
            .nullable()
            .oneOf(
              Object.keys(yesAndNoMasters),
              'Invalid POA or a mandate holder who has an address outside India'
            )
            .required('Please select POA or a mandate holder who has an address outside India'),
        }),
      addressTelephoneOutsideIndia: yup
        .string()
        .nullable()
        .when('fatcaCrsDocumentCheck', {
          is: (fatcaCrsDocumentCheck: boolean | null) => !fatcaCrsDocumentCheck,
          then: yup
            .string()
            .nullable()
            .oneOf(
              Object.keys(yesAndNoMasters),
              'Invalid Address or telephone number outside India'
            )
            .required('Please select Address or telephone number outside India'),
        }),
      sourceOfWealth: yup.string().when('fatcaCrsDocumentCheck', {
        is: (fatcaCrsDocumentCheck: boolean | null) => !fatcaCrsDocumentCheck,
        then: yup
          .string()
          .nullable()
          .when(
            [
              'taxResidentOfAnyCountryOtherThanIndia',
              'citizenOfAnyCountryOtherThanIndia',
              'countryOfBirthOtherThanIndia',
              'poaMandateHolderOutsideIndia',
              'addressTelephoneOutsideIndia',
            ],
            {
              is: (
                taxResidentOfAnyCountryOtherThanIndia: boolean | undefined,
                citizenOfAnyCountryOtherThanIndia: string | null | undefined,
                countryOfBirthOtherThanIndia: string | null | undefined,
                poaMandateHolderOutsideIndia: string | null | undefined,
                addressTelephoneOutsideIndia: string | null | undefined
              ) =>
                checkifFatcaYes(
                  taxResidentOfAnyCountryOtherThanIndia,
                  citizenOfAnyCountryOtherThanIndia,
                  countryOfBirthOtherThanIndia,
                  poaMandateHolderOutsideIndia,
                  addressTelephoneOutsideIndia
                ),
              then: yup
                .string()
                .nullable()
                .matches(stringRegex, 'Special characters and numbers not allowed'),
            }
          ),
      }),
    })
  ),
});

export const adminSupportValidation = yup.object().shape({
  supportType: yup.string().required('Support Type is required'),
  application_number: yup
    .string()
    .nullable()
    .when('supportType', {
      is: (supportType: string) =>
        [
          adminSupportMaster.change_expired_status,
          adminSupportMaster.change_status_to_draft,
        ].includes(adminSupportMaster[supportType]),
      then: yup.string().required('Application Number is required'),
    }),
  status: yup
    .string()
    .nullable()
    .when('supportType', {
      is: (supportType: string) =>
        adminSupportMaster[supportType] === adminSupportMaster.change_expired_status,
      then: yup.string().required('Status is required'),
    }),
  phone: yup
    .string()
    .nullable()
    .when('supportType', {
      is: (supportType: string) =>
        ![
          adminSupportMaster.change_expired_status,
          adminSupportMaster.change_status_to_draft,
        ].includes(adminSupportMaster[supportType]),
      then: yup.string().test('phone', (value, context) => {
        const { createError, path, parent } = context;
        const codesList = getCountryCodes()
          .map((codes) => {
            if (codes.label === parent.countryCode) {
              return codes.countryCode;
            }
          })
          .filter((ele) => ele)
          .toString();
        if (!value) {
          return createError({
            message: 'Mobile number is required',
            path,
          });
        }
        if (parent.countryCode === 'India: +91' && !phoneRegExp.test(value)) {
          return createError({
            message: 'Invalid Mobile number',
            path,
          });
        }
        if (!isValidPhoneNumber(value, codesList as CountryCode)) {
          return createError({
            message: 'Invalid Mobile number',
            path,
          });
        }
        return true;
      }),
    }),
  // .required('Mobile Number is required')
  // .min(8, 'Invalid mobile number')
  // .max(16, 'Invalid mobile number')
  // .matches(phoneRegExp, 'Invalid mobile number'),
  // countryCode: yup
  //   .string()
  //   .nullable()
  //   .test('countryCode', (value, context) => {
  //     const { createError, path, parent } = context;
  //     const codesList = getCountryCodes()
  //       .map((codes) => {
  //         if (codes.label === value) {
  //           return codes.countryCode;
  //         }
  //       })
  //       .filter((ele) => ele)
  //       .toString();
  //     if (!value) {
  //       return createError({
  //         message: 'Country Code is required',
  //         path,
  //       });
  //     }
  //     if (parent.phone && !isValidPhoneNumber(parent.phone, codesList as CountryCode)) {
  //       return createError({
  //         message: 'Invalid Mobile number',
  //         path,
  //       });
  //     }
  //     return true;
  //   }),
});

export const addNomineeDetailsSchema = (NomineeSno: number, applicants?: Partial<Applicant>[]) =>
  yup.object().shape({
    dateOfBirth: yup
      .string()
      .nullable()
      .when('sno', {
        is: (sno: number) => sno === NomineeSno,
        then: yup.string().nullable().required('Date of Birth is required'),
      }),
    nomineePan: yup
      .string()
      .nullable()
      .test('nomineePan', (value, context) => {
        const {
          options: { context: optionsContext },
          createError,
          path,
          parent,
        } = context;
        const { nominees = [] } = optionsContext || {};
        const applicantsPan = applicants?.map((applicant) => applicant.panNo) || [];
        if (!value) {
          if (!isMinor(parent.dateOfBirth || '')) {
            return createError({
              message: 'Nominee PAN is required',
              path,
            });
          }
        }
        if (value && !value.match(individualPanRegex)) {
          return createError({
            message: 'Only individual PANs are allowed',
            path,
          });
        }
        if (value && applicantsPan.length && applicantsPan?.includes(value)) {
          return createError({
            message: 'Applicant PAN not allowed to enter as nominee PAN',
            path,
          });
        }
        if (
          value &&
          nominees.filter(
            (nominee: NomineeType, index: number) =>
              nominee.nomineePan === value && nominee.isActive && index !== parent.sno
          ).length
        ) {
          return createError({
            message: 'There is already same pan for a nominee associated with this application',
            path,
          });
        }
        if (
          value &&
          !parent.edited &&
          parent.nomineeGuardianPan &&
          value === parent.nomineeGuardianPan
        ) {
          return createError({
            message: 'Nominee Pan should not be same as guardian PAN',
            path,
          });
        }
        return true;
      }),
  });
export const nomineeDetailsSchema = (applicants?: Partial<Applicant>[]) =>
  yup.object().shape({
    nominees: yup.array().of(
      yup.object().shape({
        nomineeName: yup
          .string()
          .nullable()
          .when(['isActive', 'nominationDocumentCheck', 'edited'], {
            is: (isActive: boolean, nominationDocumentCheck: boolean, edited: boolean) =>
              isActive && !nominationDocumentCheck && !edited,
            then: yup
              .string()
              .nullable()
              .matches(stringRegex, 'Special characters and numbers not allowed')
              .required('Nominee Name is required'),
          }),
        nomineeRelationship: yup
          .string()
          .nullable()
          .when(['Relationship', 'isActive', 'nominationDocumentCheck', 'edited'], {
            is: (
              Relationship: string | null,
              isActive: boolean,
              nominationDocumentCheck: boolean,
              edited: boolean
            ) => isActive && Relationship === 'OTHERS' && !nominationDocumentCheck && !edited,
            then: yup
              .string()
              .matches(stringRegex, 'Special characters and numbers not allowed')
              .required('Please Specify Relationship'),
          }),
        Relationship: yup
          .string()
          .nullable()
          .when(['isActive', 'nominationDocumentCheck', 'edited'], {
            is: (isActive: boolean, nominationDocumentCheck: boolean, edited: boolean) =>
              isActive && !nominationDocumentCheck && !edited,
            then: yup
              .string()
              .nullable()
              .oneOf(NomineeRelations, 'Invalid value for Nominee Relationship')
              .required('Please select relationship'),
          }),

        dateOfBirth: yup
          .string()
          .nullable()
          .when(['isActive', 'nominationDocumentCheck'], {
            is: (isActive: boolean, nominationDocumentCheck: boolean) =>
              isActive && !nominationDocumentCheck,
            then: yup.string().nullable().required('Date of Birth is required'),
          }),
        nomineePercentage: yup
          .number()
          .nullable()
          .when(['isActive', 'nominationDocumentCheck', 'edited'], {
            is: (isActive: boolean, nominationDocumentCheck: boolean, edited: boolean) =>
              isActive && !nominationDocumentCheck && !edited,
            then: yup
              .number()
              .nullable()
              .required('Nominee % is required')
              .typeError('Nominee % should be a number')
              .test(
                'nomineePercentage',
                'Total Nominee % should be equal to 100%',
                (value, context) => {
                  const {
                    options: { context: optionsContext },
                    createError,
                    path,
                  } = context;
                  if (value === 0) {
                    return createError({
                      message: 'Nominee % should be greater then 0',
                      path,
                    });
                  }
                  const { nominees } = optionsContext || {};
                  const total = nominees
                    .filter(
                      (_nominee: updateNominee) =>
                        _nominee.isActive && !_nominee.nominationDocumentCheck && !_nominee.edited
                    )
                    .map((nominee: updateNominee) => Number(nominee.nomineePercentage))
                    .reduce((a: number, b: number) => a + b, 0);
                  return nominees.length && total === 100;
                }
              ),
          }),
        guardianName: yup
          .string()
          .nullable()
          .when(['dateOfBirth', 'isActive', 'nominationDocumentCheck', 'edited'], {
            is: (
              dateOfBirth: string | null,
              isActive: boolean,
              nominationDocumentCheck: boolean,
              edited: boolean
            ) => isActive && isMinor(dateOfBirth || '') && !nominationDocumentCheck && !edited,
            then: yup
              .string()
              .nullable()
              .matches(stringRegex, 'Special characters and numbers not allowed')
              .required('Guardian Name is required'),
          }),
        favourOf: yup
          .string()
          .nullable()
          .when(['dateOfBirth', 'isActive', 'nominationDocumentCheck', 'edited'], {
            is: (
              dateOfBirth: string | null,
              isActive: boolean,
              nominationDocumentCheck: boolean,
              edited: boolean
            ) => isActive && isMinor(dateOfBirth || '') && !nominationDocumentCheck && !edited,
            then: yup
              .string()
              .nullable()
              // .required('Guardian CKYC No. is required')
              .matches(ckycNumberRegex, 'Invalid CKYC Number,Only 14 Digits Allowed'),
            // .length(14, 'Invalid CKYC Number'),
          }),
        nomineePan: yup
          .string()
          .nullable()
          .when(['isActive', 'nominationDocumentCheck'], {
            is: (isActive: boolean, nominationDocumentCheck: boolean) =>
              isActive && !nominationDocumentCheck,
            then: yup
              .string()
              .nullable()
              .matches(individualPanRegex, 'Only individual PANs are allowed')
              .test('nomineePan', (value, context) => {
                const {
                  options: { context: optionsContext },
                  createError,
                  path,
                  parent,
                } = context;
                const { nominees = [] } = optionsContext || {};
                const applicantsPan = applicants?.map((applicant) => applicant.panNo) || [];
                if (!value && !isMinor(parent.dateOfBirth || '')) {
                  return createError({
                    message: 'Nominee PAN is required',
                    path,
                  });
                }
                if (value && applicantsPan.length && applicantsPan?.includes(value)) {
                  return createError({
                    message: 'Applicant PAN not allowed to enter as nominee PAN',
                    path,
                  });
                }
                if (
                  value &&
                  nominees.filter(
                    (nominee: NomineeType, index: number) =>
                      nominee.nomineePan === value && nominee.isActive && index !== parent.sno
                  ).length
                ) {
                  return createError({
                    message:
                      'There is already same pan for a nominee associated with this application',
                    path,
                  });
                }
                if (value && parent.nomineeGuardianPan && value === parent.nomineeGuardianPan) {
                  return createError({
                    message: 'Nominee Pan should not be same as guardian PAN',
                    path,
                  });
                }
                return true;
              }),
          }),
        nomineeGuardianPan: yup
          .string()
          .nullable()
          .when(['dateOfBirth', 'isActive', 'nominationDocumentCheck', 'edited'], {
            is: (
              dateOfBirth: string | null,
              isActive: boolean,
              nominationDocumentCheck: boolean,
              edited: boolean
            ) => isActive && isMinor(dateOfBirth || '') && !nominationDocumentCheck && !edited,
            then: yup
              .string()
              .nullable()
              .matches(individualPanRegex, 'Only individual PANs are allowed')
              .test('nomineeGuardianPan', (value, context) => {
                const {
                  options: { context: optionsContext },
                  createError,
                  path,
                  parent,
                } = context;

                const { nominees = [] } = optionsContext || {};
                const applicantsPan = applicants?.map((applicant) => applicant.panNo) || [];
                if (!value && isMinor(parent.dateOfBirth || '') && parent.isActive) {
                  return createError({
                    message: 'Guardian Pan is required',
                    path,
                  });
                }
                if (
                  value &&
                  parent.isActive &&
                  parent.nomineePan === value
                  // nominees.filter(
                  //   (nominee: NomineeType, index: number) =>
                  //     nominee.nomineePan === value && nominee.isActive && index !== parent.sno
                  // ).length &&
                ) {
                  return createError({
                    message: 'Guardian Pan should not be same as nominee PAN',
                    path,
                  });
                }
                if (
                  value &&
                  applicantsPan.length &&
                  applicantsPan?.includes(value) &&
                  parent.isActive
                ) {
                  return createError({
                    message: 'Applicant PAN not allowed to enter as nominee PAN',
                    path,
                  });
                }
                return true;
              }),
          }),
      })
    ),
  });

export const bankDetailsSchema = (applicants: Partial<Applicant>[] | undefined) =>
  yup.object().shape({
    banks: yup.array().of(
      yup.object().shape({
        ifscCode: yup
          .string()
          .nullable()
          .required('IFSC Code is required')
          .matches(ifscRegex, 'Invalid IFSC Code'),
        bankAccountNumber: yup
          .string()
          .nullable()
          .required('Bank Account Number is required')
          .matches(accountNumberRegex, 'Please enter valid Account Number')
          .test('bankAccountNumber', 'Account number already used', (value, context) => {
            const {
              options: { context: optionsContext },
            } = context;
            const { banks } = optionsContext || {};
            return banks.filter((bank: Bank) => bank.bankAccountNumber === value).length === 1;
          }),
        bankAccountType: yup
          .string()
          .nullable()
          // .required('Bank Account Type is required')
          .test('bankAccountType', 'Bank Account Type is required', (value, context) => {
            const {
              options: { context: optionsContext },
              createError,
              path,
              parent,
            } = context;
            const fieldValue = value === null ? '' : value;
            const { applicationType, status, banks } = optionsContext || {};
            if (
              applicationType === APPLICATION_TYPE.NON_INDIVIDUAL &&
              !['huf'].includes(applicants ? applicants[0]?.investorType || '' : '') &&
              !fieldValue
            ) {
              return true;
            }
            if (
              applicationType === APPLICATION_TYPE.NON_INDIVIDUAL &&
              fieldValue &&
              ![BankAccountTypeMaster.Current, BankAccountTypeMaster.Savings].includes(fieldValue)
            ) {
              return false;
            } else if (
              fieldValue &&
              applicantStatusMasters[status] !== applicantStatusMasters.NRI &&
              !Object.values(BankAccountTypeMaster).includes(fieldValue)
            ) {
              return false;
            }
            if (
              fieldValue &&
              applicantStatusMasters[status] === applicantStatusMasters.NRI &&
              !Object.values(NRIBankAccountTypeMaster).includes(fieldValue)
            ) {
              return false;
            }
            if (
              fieldValue &&
              applicantStatusMasters[status] === applicantStatusMasters.NRI &&
              banks.filter(
                (bank: Bank) =>
                  Object.values(NRIBankAccountTypeMaster).includes(bank.bankAccountType) &&
                  Object.values(NRIBankAccountTypeMaster).includes(fieldValue) &&
                  bank.bankAccountType != fieldValue
              ).length === 1
            ) {
              return createError({
                message: 'Both Bank Account Type should be same',
                path,
              });
            }

            return !!fieldValue;
          }),
        bankName: yup
          .string()
          .nullable()
          .required('Bank Name is required')
          .matches(stringRegex, 'Special characters and numbers not allowed'),
        bankBranch: yup
          .string()
          .nullable()
          .required('Bank Branch is required')
          .matches(stringRegex, 'Special characters and numbers not allowed'),
        defaultBankAccount: yup
          .bool()
          .test('defaultBankAccount', 'Please check the default bank account', (value, context) => {
            const {
              options: { context: optionsContext },
            } = context;
            const { banks } = optionsContext || {};
            const isDefaultBankSelected = banks.some((bank: Bank) => bank.defaultBankAccount);
            return isDefaultBankSelected;
          }),
        bankAddress: yup
          .string()
          .nullable()
          .matches(
            addressRegex,
            "Invalid bank address format [special characters are not allowed except -/',&()#:.]"
          ),
        micrCode: yup.string().nullable().matches(numberRegex, 'MICR code allows only digits'),
      })
    ),
  });

export const investmentPaymentDetailsSchema = (
  minCommitmentAmount: number,
  maxCommitmentAmount: number,
  minContributionPercentage: number,
  fundsList: FundProps[]
) =>
  yup.object().shape({
    schemeId: yup
      .string()
      .required('Fund Scheme is required')
      .test('schemeId', 'Invalid Fund Scheme', (schemeId?: string | null) =>
        schemeId ? fundsList.map((f) => Number(f.id)).includes(Number(schemeId)) : true
      ),
    planId: yup
      .string()
      .required('Class Plan is required')
      .test('planId', `Invalid Class Plan`, (value, context) => {
        const { parent } = context;
        const { schemeId } = parent;
        return (
          fundsList
            ?.find((f) => Number(f.id) === Number(schemeId))
            ?.plans?.map((p) => Number(p.id))
            ?.includes(Number(value)) || false
        );
      }),
    commitmentAmount: yup
      .number()
      .nullable()
      .min(
        minCommitmentAmount,
        `Commitment Amount should be greater than or equal to ${currencyConversion(
          minCommitmentAmount
        )}`
      )
      .max(
        maxCommitmentAmount,
        `Commitment Amount should be between ${
          currencyConversion(minCommitmentAmount) || '1 Cr'
        } and ${currencyConversion(maxCommitmentAmount) || '2 Cr'}`
      )
      .required('Commitment Amount is required'),
    setupFeePercentage: yup
      .number()
      .nullable()
      .required('Setup Fee Percentage is required')
      .lessThan(101, 'Setup Fee Percentage should be less then 100%'),
    contributionChequeAmount: yup
      .number()
      .nullable()
      .required('Contribution Cheque Amount is required')
      .max(
        yup.ref('commitmentAmount'),
        'Contribution Amount should not be greater than commitment amount'
      )
      .test(
        'contributionChequeAmount',
        `${
          Number(minContributionPercentage) === 100
            ? `Contribution Amount should be equal to commitment amount`
            : `Contribution Amount should be greater than or equal to ${minContributionPercentage}% of commitment amount`
        }`,
        (value, context) => {
          const { parent } = context;
          const { commitmentAmount } = parent;
          return (
            (100 * Number(value)) / Number(commitmentAmount) >= Number(minContributionPercentage)
          );
        }
      ),
    setupFeeTDS: yup
      .number()
      .nullable()
      .min(0, `TDS should be greater than 0`)
      .max(yup.ref('totalSetupFee'), 'TDS should not be greater than Total Setup Fee'),

    referralName: yup
      .string()
      .nullable()
      .required('Referral Name is required')
      .matches(kraStringRegex, 'Special characters and numbers not allowed'),

    referralPercentage: yup
      .number()
      .nullable()
      .typeError('Referral Percentage should be number')
      .required('Referral Percentage is required')
      .min(0, 'Referral Percentage should be in between or equal to 0% and 100%')
      .max(100, 'Referral Percentage should be in between or equal to 0% and 100%'),

    investorAdvisory: yup
      .string()
      .nullable()
      .oneOf(Object.keys(AdvisoryServices), 'Invalid value')
      .required('Please select any one option'),
  });

export const documentDetailsSchema = (bankAccountProofValidation: string) =>
  yup.object().shape({
    ppmNo: yup
      .string()
      .nullable()
      .matches(defaultRegexWithSingleLine, 'Invalid value for PPM Number'),
    // .length(10, 'Invalid PPM Number'),
    applicants: yup.array().of(
      yup.object().shape({
        documents: yup.array().of(
          yup.object().shape({
            documentsList: yup.array().of(
              yup.object().shape({
                documentName: yup
                  .string()
                  .nullable()
                  .test(
                    'documentName',
                    (
                      value: string | undefined | null,
                      context: yup.TestContext<Record<string, DocumentValues>>
                    ) => {
                      const { options, createError, path } = context;
                      const { context: optionsContext, parent } =
                        options as InternalOptions<DocumentValues> & { index: number };
                      const { applicants = [], banks } = optionsContext || ({} as DocumentValues);
                      const { uniqueKey = '' } = parent || {};
                      // eslint-disable-next-line
                      const [parent1, parent2, parent3, parent4, ...rest] = (context as any).from;
                      const indexes = uniqueKey.split('-');
                      const {
                        required,
                        documentName,
                        documentType,
                        options: optionsDropdown,
                      } = applicants[indexes[0]].documents[indexes[1]].documentsList[indexes[2]];
                      const fieldValue =
                        optionsDropdown && value && optionsDropdown.includes(value);
                      if (optionsDropdown) {
                        if (
                          documentType === 'bankAccountProof' &&
                          !fieldValue &&
                          banks &&
                          // .filter((bank) => !bank.pennydropVerified && bank.defaultBankAccount)
                          banks.length !== 0 &&
                          Number(indexes[0]) === 0 &&
                          checkValidationBasedOnDate(
                            parent4.value.createdAt || '',
                            bankAccountProofValidation
                          )
                        ) {
                          return createError({
                            message: `Please Select ${documentName}`,
                            path,
                          });
                        }
                        if (documentType === 'bankAccountProof' && Number(indexes[0]) !== 0) {
                          return true;
                        }
                        if (
                          !fieldValue &&
                          required === 'true' &&
                          documentType !== 'bankAccountProof'
                        ) {
                          return createError({
                            message: `Please Select ${documentName}`,
                            path,
                          });
                        }
                      }
                      return true;
                    }
                  ),
                documentId: yup
                  .string()
                  .nullable()
                  .test(
                    'documentId',
                    (
                      value: string | undefined | null,
                      context: yup.TestContext<Record<string, DocumentValues>>
                    ) => {
                      const { options, createError, path } = context;
                      const { context: optionsContext, parent } =
                        options as InternalOptions<DocumentValues> & { index: number };
                      const { applicants = [], banks } = optionsContext || ({} as DocumentValues);
                      const { uniqueKey = '' } = parent || {};
                      // eslint-disable-next-line
                      const [parent1, parent2, parent3, parent4, ...rest] = (context as any).from;
                      const indexes = uniqueKey.split('-');
                      const { required, documentName, documentType, isVisible } =
                        applicants[indexes[0]].documents[indexes[1]].documentsList[indexes[2]];
                      if (required === 'false') {
                        return true;
                      }
                      if (!value && isVisible) {
                        if (
                          !value &&
                          banks &&
                          // .filter((bank) => !bank.pennydropVerified && bank.defaultBankAccount)
                          banks.length !== 0 &&
                          Number(indexes[0]) === 0 &&
                          documentType === 'bankAccountProof' &&
                          checkValidationBasedOnDate(
                            parent4.value.createdAt || '',
                            bankAccountProofValidation
                          )
                        ) {
                          return createError({
                            message: `Please upload ${documentName}`,
                            path,
                          });
                        }
                        if (!value && required === 'true' && documentType !== 'bankAccountProof') {
                          return createError({
                            message: `Please upload ${documentName}`,
                            path,
                          });
                        }
                      }
                      return true;
                    }
                  ),
              })
            ),
          })
        ),
      })
    ),
    nominees: yup.array().of(
      yup.object().shape({
        nomineedocuments: yup.array().of(
          yup.object().shape({
            documentsList: yup.array().of(
              yup.object().shape({
                documentName: yup
                  .string()
                  .nullable()
                  .test(
                    'documentName',
                    (
                      value: string | undefined | null,
                      context: yup.TestContext<Record<string, DocumentValues>>
                    ) => {
                      const { options, createError, path } = context;
                      const { context: optionsContext, parent } =
                        options as InternalOptions<DocumentValues> & { index: number };
                      const { nominees = [] } = optionsContext || ({} as DocumentValues);
                      const { uniqueKey = '' } = parent || {};
                      const indexes = uniqueKey.split('-');
                      const {
                        required,
                        documentName,
                        documentType,
                        options: optionsDropdown,
                      } = nominees[indexes[0]].nomineedocuments[indexes[1]].documentsList[
                        indexes[2]
                      ];
                      const fieldValue =
                        optionsDropdown && value && optionsDropdown.includes(value);
                      if (optionsDropdown) {
                        if (!fieldValue && required === 'true') {
                          return createError({
                            message: `Please Select ${documentName}`,
                            path,
                          });
                        }
                      }
                      return true;
                    }
                  ),
                documentId: yup
                  .string()
                  .nullable()
                  .test(
                    'documentId',
                    (
                      value: string | undefined | null,
                      context: yup.TestContext<Record<string, DocumentValues>>
                    ) => {
                      const { options, createError, path } = context;
                      const { context: optionsContext, parent } =
                        options as InternalOptions<DocumentValues> & { index: number };
                      const { nominees = [], banks } = optionsContext || ({} as DocumentValues);
                      const { uniqueKey = '' } = parent || {};
                      const indexes = uniqueKey.split('-');
                      const { required, documentName, documentType } =
                        nominees[indexes[0]].nomineedocuments[indexes[1]].documentsList[indexes[2]];
                      if (required === 'false') {
                        return true;
                      }
                      if (!value && required === 'true') {
                        return createError({
                          message: `Please upload ${documentName}`,
                          path,
                        });
                      }
                      return true;
                    }
                  ),
              })
            ),
          })
        ),
      })
    ),
    // applicants: yup.array().of(
    //   yup.object().shape({
    //     documents: yup.object().shape({
    //       // identityProof: yup.object().shape({
    //       //   documentName: yup.string().required('Please select identity proof'),
    //       //   documentId: yup.string().required('Please upload Identity Proof'),
    //       // }),
    //       // addressProof: yup.object().shape({
    //       //   documentName: yup.string().required('Please select Address Proof'),
    //       //   documentId: yup.string().required('Please upload Address Proof'),
    //       // }),
    //       bankAccountProof: yup.object().shape({
    //         documentName: yup
    //           .string()
    //           .test('documentName', 'Please select Bank Account Proof', (value, context) =>
    //             DocumentRequiredForPennyDrop(value, context)
    //           ),

    //         documentId: yup
    //           .string()
    //           .test('documentId', 'Please upload Bank Account Proof', (value, context) =>
    //             DocumentRequiredForPennyDrop(value, context)
    //           ),
    //       }),
    //       poaNotarized: yup.object().shape({
    //         documentId: yup
    //           .string()
    //           .test('documentId', 'Please upload POA Notarized Document', (value, context) =>
    //             POADocumentValidation(value, context, 'poaNotarized')
    //           ),
    //       }),
    //       investorsConsent: yup.object().shape({
    //         documentId: yup
    //           .string()
    //           .test('documentId', "Please upload Investor's Consent Document", (value, context) =>
    //             POADocumentValidation(value, context)
    //           ),
    //       }),
    //       fatca: yup.object().shape({
    //         documentId: yup
    //           .string()
    //           .test('documentId', 'Please upload FATCA Document', (value, context) =>
    //             POADocumentValidation(value, context)
    //           ),
    //       }),
    //     }),
    //   })
    // ),
  });

export const distributorsValidationSchema = (
  selectedDistributor: SubDistributor[],
  distributors: Distributor[]
) =>
  yup.object().shape({
    //role: useSelector((store: RootStateType) => store.auth),
    email: yup.string().nullable().matches(emailRegex, 'Invalid RM email id'),
    firstName: yup
      .string()
      .nullable()
      .matches(stringRegex, 'Special characters and numbers not allowed'),
    distributorId: yup
      .string()
      .nullable()
      .when('loginType', {
        is: (loginType: string) => loginType === 'distributor' && !!distributors.length,
        then: yup
          .string()
          .nullable()
          .required('Please select a distributor code')
          .test(
            'distributorId',
            "Invalid distributor's rm name - code",
            (distributorId?: string | null) =>
              distributorId
                ? distributors.map((dis) => Number(dis.id)).includes(Number(distributorId))
                : false
          ),
      }),
    distributorName: yup
      .string()
      .nullable()
      .when(['loginType'], {
        is: (loginType: string) => loginType === 'distributor' && !!distributors.length,
        then: yup
          .string()
          .nullable()
          .required('Distributor Name is required')
          .matches(stringRegex, 'Special characters and numbers not allowed'),
      }),
    subdistributorId: yup
      .string()
      .nullable()
      .when(['loginType', 'distributorId'], {
        is: (distributorId: string) =>
          distributorId && 'distributor' && !!selectedDistributor.length,
        then: yup
          .string()
          .nullable()
          .required("Please select a distributor's rm name - code")
          .test(
            'subdistributorId',
            "Invalid distributor's rm name - code",
            (subdistributorId?: string | null) =>
              subdistributorId
                ? selectedDistributor.map((dis) => dis.id).includes(Number(subdistributorId))
                : false
          ),
      }),
    subdistributorEmail: yup
      .string()
      .nullable()
      .when(['loginType', 'distributorId'], {
        is: (distributorId: string) =>
          distributorId && 'distributor' && !!selectedDistributor.length,
        then: yup
          .string()
          .nullable()
          .required("Distributor's RM Email id is required")
          .matches(emailRegex, "Invalid Distributor's RM Email id"),
      }),
    filledBy: yup
      .string()
      .nullable()
      .matches(stringRegex, 'Special characters and numbers not allowed'),
    //selectedDistributor.length
  });
const validateNonIndividualPincodeField = (
  value: string | null | undefined,
  context: yup.TestContext<Record<string, Values>>
) => {
  const fieldValue = value === undefined ? '' : value;
  const { options, path, createError, parent } = context;
  const { context: optionsContext, index } =
    options as InternalOptions<NonIndividualContactValues> & {
      index: number;
    };
  const { applicants = [] } = optionsContext || {};
  // if (!fieldValue) {
  //   return createError({
  //     message: 'Pincode is required',
  //     path,
  //   });
  // }
  if (fieldValue && parent.country?.toLowerCase() === 'india' && !indianPin.test(fieldValue)) {
    return createError({
      message: 'Pincode code should be 6 digit code',
      path,
    });
  }
  if (fieldValue && parent.country?.toLowerCase() !== 'india' && !alphaNumeric.test(fieldValue)) {
    return createError({
      message: 'Invalid Pincode',
      path,
    });
  }
  return true;
};

const addressBaseSchema = yup.object().shape({
  pincode: yup
    .string()
    .nullable()
    .test('pincode', (value, context) => validateNonIndividualPincodeField(value, context)),
  state: yup.string().nullable().matches(stringRegex, 'Special characters and numbers not allowed'),
  city: yup
    .string()
    .nullable()
    .matches(
      addressRegex,
      "Invalid City format [special characters are not allowed except -/',&()#:.]"
    ),
  country: yup
    .string()
    .nullable()
    .matches(stringRegex, 'Special characters and numbers not allowed'),
  buildingNo: yup
    .string()
    .nullable()
    .matches(addressRegex, `Special characters are not allowed except -/',&()#:.`),
  streetName: yup
    .string()
    .nullable()
    .matches(addressRegex, `Special characters are not allowed except -/',&()#:.`),
  comment: yup
    .string()
    .nullable()
    .when('checkId', {
      is: (value: string) => value,
      then: yup
        .string()
        .nullable()
        .matches(validCommentRegex, InvalidCommentsMessage)
        .required('Please provide Comments'),
    }),
});

export const userManagementRmSchema = addressBaseSchema.shape({
  name: yup
    .string()
    .nullable()
    .matches(stringRegex, 'Special characters and numbers not allowed')
    .required('RM Name is required'),
  email: yup
    .string()
    .nullable()
    .matches(emailRegex, 'Invalid email id')
    .required('Email is required'),
  phone: yup
    .string()
    .nullable()
    .min(8, 'Invalid Phone number')
    .max(16, 'Invalid Phone number')
    .matches(phoneRegExp, 'Invalid Phone number')
    .required('Phone Number is required'),
  companyName: yup
    .string()
    .nullable()
    .oneOf(Object.values(COMPANIES_MASTER), 'Invalid value')
    .required('Company Name is required'),
  panNumber: yup.string().nullable().matches(individualPanRegex, 'Invalid Pan'),
  empCode: yup.string().nullable().matches(defaultRegexWithSingleLine, 'Invalid Emp Code'),
  //   phone: yup
  //   .string()
  //   .nullable()
  //   .test('phone', (value, context) => {
  //     const { createError, path, parent } = context;
  //     const codesList = getCountryCodes()
  //       .map((codes) => {
  //         if (codes.label === parent.countryNameAndCode) {
  //           return codes.countryCode;
  //         }
  //       })
  //       .filter((ele) => ele)
  //       .toString();
  //     if (!value) {
  //       return createError({
  //         message: 'Phone is required',
  //         path,
  //       });
  //     }
  //     if (parent.countryNameAndCode === 'India: +91' && !phoneRegExp.test(value)) {
  //       return createError({
  //         message: 'Invalid Phone number',
  //         path,
  //       });
  //     }
  //     if (!isValidPhoneNumber(value, codesList as CountryCode)) {
  //       return createError({
  //         message: 'Invalid Phone number',
  //         path,
  //       });
  //     }

  //     return true;
  //   }),
  // countryNameAndCode: yup
  //   .string()
  //   .nullable()
  //   .test('countryNameAndCode', (value, context) => {
  //     const { createError, path, parent } = context;
  //     const codesList = getCountryCodes()
  //       .map((codes) => {
  //         if (codes.label === value) {
  //           return codes.countryCode;
  //         }
  //       })
  //       .filter((ele) => ele)
  //       .toString();
  //     if (!value) {
  //       return createError({
  //         message: 'Country Code is required',
  //         path,
  //       });
  //     }
  //     if (parent.phone && !isValidPhoneNumber(parent.phone, codesList as CountryCode)) {
  //       return createError({
  //         message: 'Invalid Mobile number',
  //         path,
  //       });
  //     }

  //     return true;
  //   }),
});

export const DistributorSchema = (rmList: string[]) =>
  addressBaseSchema.shape({
    name: yup
      .string()
      .nullable()
      .matches(stringRegex, 'Special characters and numbers not allowed')
      .required('Distributor Name is required'),
    email: yup
      .string()
      .nullable()
      .matches(emailRegex, 'Invalid email id')
      .when('type', {
        is: (value: string) => value === 'individual',
        then: yup.string().required('Email is required'),
      }),
    phone: yup
      .string()
      .nullable()
      .min(8, 'Invalid Contact number')
      .max(16, 'Invalid Contact number')
      .matches(phoneRegExp, 'Invalid Contact number')
      .when('type', {
        is: (value: string) => value === 'individual',
        then: yup.string().nullable().required('Contact Number is required'),
      }),
    companyName: yup
      .string()
      .nullable()
      .when('type', {
        is: (value: string) => value === 'individual',
        then: yup
          .string()
          .nullable()
          .oneOf(Object.values(COMPANIES_MASTER), 'Invalid value')
          .required('Company Name is required'),
      }),
    panNumber: yup
      .string()
      .nullable()
      .when('type', {
        is: (value: string) => value === 'individual',
        then: yup.string().nullable().matches(individualPanRegex, 'Invalid Pan'),
      })
      .when('type', {
        is: (value: string) => value === 'company',
        then: yup.string().nullable().matches(nonIndividualPanRegex, 'Invalid Pan'),
      }),
    //   phone: yup
    //   .string()
    //   .nullable()
    //   .test('phone', (value, context) => {
    //     const { createError, path, parent } = context;
    //     const codesList = getCountryCodes()
    //       .map((codes) => {
    //         if (codes.label === parent.countryNameAndCode) {
    //           return codes.countryCode;
    //         }
    //       })
    //       .filter((ele) => ele)
    //       .toString();
    //     if (!value && parent.type=== 'individual') {
    //       return createError({
    //         message: 'Phone is required',
    //         path,
    //       });
    //     }
    //     if (parent.countryNameAndCode === 'India: +91' && !phoneRegExp.test(value)) {
    //       return createError({
    //         message: 'Invalid Phone number',
    //         path,
    //       });
    //     }
    //     if (!isValidPhoneNumber(value, codesList as CountryCode)) {
    //       return createError({
    //         message: 'Invalid Phone number',
    //         path,
    //       });
    //     }

    //     return true;
    //   }),
    // countryNameAndCode: yup
    //   .string()
    //   .nullable()
    //   .test('countryNameAndCode', (value, context) => {
    //     const { createError, path, parent } = context;
    //     const codesList = getCountryCodes()
    //       .map((codes) => {
    //         if (codes.label === value) {
    //           return codes.countryCode;
    //         }
    //       })
    //       .filter((ele) => ele)
    //       .toString();
    //     if (!value&&parent.type=== 'individual') {
    //       return createError({
    //         message: 'Country Code is required',
    //         path,
    //       });
    //     }
    //     if (parent.phone && !isValidPhoneNumber(parent.phone, codesList as CountryCode)) {
    //       return createError({
    //         message: 'Invalid Mobile number',
    //         path,
    //       });
    //     }

    //     return true;
    //   }),
    // panNumber: yup
    //   .string()
    //   .uppercase()
    //   .length(10, 'PAN must be exactly 10 characters')
    //   .matches(individualPanRegex, 'Only individual PANs are allowed currently')
    //   .required('PAN is required'),
    arnCode: yup
      .string()
      .nullable()
      .matches(arnCodeRegex, 'Invalid ARN Code Ex:- ARN-12345')
      .required('ARN Code is required'),
    rmId: yup
      .string()
      .nullable()
      .when('type', {
        is: (value: string) => value === 'individual',
        then: yup
          .string()
          .nullable()
          .test('rmId', (value, context) => {
            const { createError, path } = context;
            if (!value) {
              return createError({
                message: 'RM Name is required',
                path,
              });
            } else if (!rmList.includes(value.toString())) {
              return createError({
                message: 'Invalid value',
                path,
              });
            }

            return true;
          }),
      }),
    poaKinNum: yup.string().test('poaKinNum', (value, context) => {
      const { createError, path } = context;
      if (!value) {
        return true;
      }
      if (value?.toString().length !== 14 || isNaN(Number(value))) {
        return createError({
          message: 'Invalid Distributor/POA KIN',
          path,
        });
      }
      return true;
    }),
    // pincode: yup.string().nullable().required('Please Enter Pincode'),
    // buildingNo: yup.string().nullable().required('Please Enter Building Number'),
    // streetName: yup.string().nullable().required('Please Enter Street Name'),
    // city: yup.string().nullable().required('Please Enter City'),
    // state: yup.string().nullable().required('Please Enter State'),
    // country: yup.string().nullable().required('Please Enter Country'),
  });

export const TrusteeAndFundManagerSchema = addressBaseSchema.shape({
  name: yup
    .string()
    .nullable()
    .matches(stringRegex, 'Special characters and numbers not allowed')
    .required('Name is required'),
  email: yup
    .string()
    .nullable()
    .matches(emailRegex, 'Invalid email id')
    .required('Email is required'),
  phone: yup
    .string()
    .nullable()
    .required('Mobile Number is required')
    .min(8, 'Invalid Mobile number')
    .max(16, 'Invalid Mobile number')
    .matches(phoneRegExp, 'Invalid Mobile number'),
  companyName: yup
    .string()
    .nullable()
    .oneOf(Object.values(COMPANIES_MASTER), 'Invalid value')
    .required('Company Name is required'),
  panNumber: yup.string().nullable().matches(individualPanRegex, 'Invalid Pan'),
  //   phone: yup
  //   .string()
  //   .nullable()
  //   .test('phone', (value, context) => {
  //     const { createError, path, parent } = context;
  //     const codesList = getCountryCodes()
  //       .map((codes) => {
  //         if (codes.label === parent.countryNameAndCode) {
  //           return codes.countryCode;
  //         }
  //       })
  //       .filter((ele) => ele)
  //       .toString();
  //     if (!value) {
  //       return createError({
  //         message: 'Phone is required',
  //         path,
  //       });
  //     }
  //     if (parent.countryNameAndCode === 'India: +91' && !phoneRegExp.test(value)) {
  //       return createError({
  //         message: 'Invalid Phone number',
  //         path,
  //       });
  //     }
  //     if (!isValidPhoneNumber(value, codesList as CountryCode)) {
  //       return createError({
  //         message: 'Invalid Phone number',
  //         path,
  //       });
  //     }

  //     return true;
  //   }),
  // countryNameAndCode: yup
  //   .string()
  //   .nullable()
  //   .test('countryNameAndCode', (value, context) => {
  //     const { createError, path, parent } = context;
  //     const codesList = getCountryCodes()
  //       .map((codes) => {
  //         if (codes.label === value) {
  //           return codes.countryCode;
  //         }
  //       })
  //       .filter((ele) => ele)
  //       .toString();
  //     if (!value) {
  //       return createError({
  //         message: 'Country Code is required',
  //         path,
  //       });
  //     }
  //     if (parent.phone && !isValidPhoneNumber(parent.phone, codesList as CountryCode)) {
  //       return createError({
  //         message: 'Invalid Mobile number',
  //         path,
  //       });
  //     }

  //     return true;
  //   }),
  dateOfBirth: yup
    .string()
    .nullable()
    .test(
      'dateOfBirth',
      'Age should be greater than 18',
      (dateOfBirth) => !isMinor(dateOfBirth || '')
    ),
  leegalityName: yup
    .string()
    .nullable()
    .matches(defaultRegexWithSingleLine, 'Invalid value')
    .required('Leegality Name is required'),
  // .required('Date of Birth is required'),
  // panNumber: yup
  //   .string()
  //   .uppercase()
  //   .length(10, 'PAN must be exactly 10 characters')
  //   .matches(individualPanRegex, 'Only individual PANs are allowed currently')
  //   .required('PAN is required'),
  // pincode: yup.string().required('Please Enter Pincode'),
  // buildingNo: yup.string().required('Please Enter Building Number'),
  // streetName: yup.string().required('Please Enter Street Name'),
  // city: yup.string().required('Please Enter City'),
  // state: yup.string().required('Please Enter State'),
  // country: yup.string().required('Please Enter Country'),
});

export const aifApproverSchema = addressBaseSchema.shape({
  name: yup
    .string()
    .nullable()
    .matches(stringRegex, 'Special characters and numbers not allowed')
    .required('Name is required'),
  email: yup
    .string()
    .nullable()
    .matches(emailRegex, 'Invalid email id')
    .required('Email is required'),
  phone: yup
    .string()
    .nullable()
    .required('Mobile Number is required')
    .min(8, 'Invalid Mobile Number')
    .max(16, 'Invalid Mobile Number')
    .matches(phoneRegExp, 'Invalid Mobile Number'),
  companyName: yup
    .string()
    .nullable()
    .oneOf(Object.values(COMPANIES_MASTER), 'Invalid value')
    .required('Company Name is required'),
  //   phone: yup
  //   .string()
  //   .nullable()
  //   .test('phone', (value, context) => {
  //     const { createError, path, parent } = context;
  //     const codesList = getCountryCodes()
  //       .map((codes) => {
  //         if (codes.label === parent.countryNameAndCode) {
  //           return codes.countryCode;
  //         }
  //       })
  //       .filter((ele) => ele)
  //       .toString();
  //     if (!value) {
  //       return createError({
  //         message: 'Phone is required',
  //         path,
  //       });
  //     }
  //     if (parent.countryNameAndCode === 'India: +91' && !phoneRegExp.test(value)) {
  //       return createError({
  //         message: 'Invalid Phone number',
  //         path,
  //       });
  //     }
  //     if (!isValidPhoneNumber(value, codesList as CountryCode)) {
  //       return createError({
  //         message: 'Invalid Phone number',
  //         path,
  //       });
  //     }

  //     return true;
  //   }),
  // countryNameAndCode: yup
  //   .string()
  //   .nullable()
  //   .test('countryNameAndCode', (value, context) => {
  //     const { createError, path, parent } = context;
  //     const codesList = getCountryCodes()
  //       .map((codes) => {
  //         if (codes.label === value) {
  //           return codes.countryCode;
  //         }
  //       })
  //       .filter((ele) => ele)
  //       .toString();
  //     if (!value) {
  //       return createError({
  //         message: 'Country Code is required',
  //         path,
  //       });
  //     }
  //     if (parent.phone && !isValidPhoneNumber(parent.phone, codesList as CountryCode)) {
  //       return createError({
  //         message: 'Invalid Mobile number',
  //         path,
  //       });
  //     }

  //     return true;
  //   }),
  // pincode: yup.string().required('Please Enter Pincode'),
  // buildingNo: yup.string().required('Please Enter Building Number'),
  // streetName: yup.string().required('Please Enter Street Name'),
  // city: yup.string().required('Please Enter City'),
  // state: yup.string().required('Please Enter State'),
  // country: yup.string().required('Please Enter Country'),
});

export const addFundManagerSchema = addressBaseSchema.shape({
  name: yup
    .string()
    .nullable()
    .matches(stringRegex, 'Special characters and numbers not allowed')
    .required('Name is required'),
  email: yup
    .string()
    .nullable()
    .matches(emailRegex, 'Invalid email id')
    .required('Email is required'),
  phone: yup
    .string()
    .nullable()
    .required('Mobile Number is required')
    .min(8, 'Invalid mobile number')
    .max(16, 'Invalid mobile number')
    .matches(phoneRegExp, 'Invalid mobile number'),
  companyName: yup
    .string()
    .nullable()
    .oneOf(Object.values(COMPANIES_MASTER), 'Invalid value')
    .required('Company Name is required'),
  //   phone: yup
  //   .string()
  //   .nullable()
  //   .test('phone', (value, context) => {
  //     const { createError, path, parent } = context;
  //     const codesList = getCountryCodes()
  //       .map((codes) => {
  //         if (codes.label === parent.countryNameAndCode) {
  //           return codes.countryCode;
  //         }
  //       })
  //       .filter((ele) => ele)
  //       .toString();
  //     if (!value) {
  //       return createError({
  //         message: 'Phone is required',
  //         path,
  //       });
  //     }
  //     if (parent.countryNameAndCode === 'India: +91' && !phoneRegExp.test(value)) {
  //       return createError({
  //         message: 'Invalid Phone number',
  //         path,
  //       });
  //     }
  //     if (!isValidPhoneNumber(value, codesList as CountryCode)) {
  //       return createError({
  //         message: 'Invalid Phone number',
  //         path,
  //       });
  //     }

  //     return true;
  //   }),
  // countryNameAndCode: yup
  //   .string()
  //   .nullable()
  //   .test('countryNameAndCode', (value, context) => {
  //     const { createError, path, parent } = context;
  //     const codesList = getCountryCodes()
  //       .map((codes) => {
  //         if (codes.label === value) {
  //           return codes.countryCode;
  //         }
  //       })
  //       .filter((ele) => ele)
  //       .toString();
  //     if (!value) {
  //       return createError({
  //         message: 'Country Code is required',
  //         path,
  //       });
  //     }
  //     if (parent.phone && !isValidPhoneNumber(parent.phone, codesList as CountryCode)) {
  //       return createError({
  //         message: 'Invalid Mobile number',
  //         path,
  //       });
  //     }

  //     return true;
  //   }),
  leegalityName: yup
    .string()
    .nullable()
    .matches(defaultRegexWithSingleLine, 'Invalid value')
    .required('Leegality Name is required'),
  // pincode: yup.string().required('Please Enter Pincode'),
  // buildingNo: yup.string().required('Please Enter Building Number'),
  // streetName: yup.string().required('Please Enter Street Name'),
  // city: yup.string().required('Please Enter City'),
  // state: yup.string().required('Please Enter State'),
  // country: yup.string().required('Please Enter Country'),
});

export const AuthorisedSignatoryAndPOASchema = addressBaseSchema.shape({
  name: yup
    .string()
    .nullable()
    .matches(stringRegex, 'Special characters and numbers not allowed')
    .required('Name is required'),
  email: yup
    .string()
    .nullable()
    .matches(emailRegex, 'Invalid email id')
    .required('Email is required'),
  phone: yup
    .string()
    .nullable()
    .min(8, 'Invalid Mobile number')
    .max(16, 'Invalid Mobile number')
    .matches(phoneRegExp, 'Invalid Mobile number')
    .required('Mobile Number is required'),
  companyName: yup
    .string()
    .nullable()
    .oneOf(Object.values(COMPANIES_MASTER), 'Invalid value')
    .required('Company Name is required'),
  panNumber: yup.string().nullable().matches(individualPanRegex, 'Invalid Pan'),
  //   phone: yup
  //   .string()
  //   .nullable()
  //   .test('phone', (value, context) => {
  //     const { createError, path, parent } = context;
  //     const codesList = getCountryCodes()
  //       .map((codes) => {
  //         if (codes.label === parent.countryNameAndCode) {
  //           return codes.countryCode;
  //         }
  //       })
  //       .filter((ele) => ele)
  //       .toString();
  //     if (!value) {
  //       return createError({
  //         message: 'Phone is required',
  //         path,
  //       });
  //     }
  //     if (parent.countryNameAndCode === 'India: +91' && !phoneRegExp.test(value)) {
  //       return createError({
  //         message: 'Invalid Phone number',
  //         path,
  //       });
  //     }
  //     if (!isValidPhoneNumber(value, codesList as CountryCode)) {
  //       return createError({
  //         message: 'Invalid Phone number',
  //         path,
  //       });
  //     }

  //     return true;
  //   }),
  // countryNameAndCode: yup
  //   .string()
  //   .nullable()
  //   .test('countryNameAndCode', (value, context) => {
  //     const { createError, path, parent } = context;
  //     const codesList = getCountryCodes()
  //       .map((codes) => {
  //         if (codes.label === value) {
  //           return codes.countryCode;
  //         }
  //       })
  //       .filter((ele) => ele)
  //       .toString();
  //     if (!value) {
  //       return createError({
  //         message: 'Country Code is required',
  //         path,
  //       });
  //     }
  //     if (parent.phone && !isValidPhoneNumber(parent.phone, codesList as CountryCode)) {
  //       return createError({
  //         message: 'Invalid Mobile number',
  //         path,
  //       });
  //     }

  //     return true;
  //   }),
  // panNumber: yup
  //   .string()
  //   .uppercase()
  //   .length(10, 'PAN must be exactly 10 characters')
  //   .matches(individualPanRegex, 'Only individual PANs are allowed currently')
  //   .required('PAN is required'),
  // distributorId: yup.string().required('Please Enter Distridutor Id'),
  // amcId: yup.string().required('Please Enter AmcId'),
  // pincode: yup.string().nullable().required('Please Enter Pincode'),
  // buildingNo: yup.string().nullable().required('Please Enter Building Number'),
  // streetName: yup.string().nullable().required('Please Enter Street Name'),
  // city: yup.string().nullable().required('Please Enter City'),
  // state: yup.string().nullable().required('Please Enter State'),
  // country: yup.string().nullable().required('Please Enter Country'),
});

export const DistributorRmSchema = (rmList: string[]) =>
  addressBaseSchema.shape({
    name: yup
      .string()
      .nullable()
      .matches(stringRegex, 'Special characters and numbers not allowed')
      .required("Distributor's RM name is required"),
    email: yup
      .string()
      .nullable()
      .matches(emailRegex, 'Invalid email id')
      .required('Email is required'),
    phone: yup
      .string()
      .nullable()
      .min(8, 'Invalid Contact number')
      .max(16, 'Invalid Contact number')
      .matches(phoneRegExp, 'Invalid Contact number')
      .required('Contact Number is required'),
    companyName: yup
      .string()
      .nullable()
      .oneOf(Object.values(COMPANIES_MASTER), 'Invalid value')
      .required('Company Name is required'),
    panNumber: yup.string().nullable().matches(individualPanRegex, 'Invalid Pan'),
    distributors_RM_Code: yup
      .string()
      .nullable()
      .matches(defaultRegexWithSingleLine, 'Invalid RM Code'),
    //   phone: yup
    //   .string()
    //   .nullable()
    //   .test('phone', (value, context) => {
    //     const { createError, path, parent } = context;
    //     const codesList = getCountryCodes()
    //       .map((codes) => {
    //         if (codes.label === parent.countryNameAndCode) {
    //           return codes.countryCode;
    //         }
    //       })
    //       .filter((ele) => ele)
    //       .toString();
    //     if (!value) {
    //       return createError({
    //         message: 'Phone is required',
    //         path,
    //       });
    //     }
    //     if (parent.countryNameAndCode === 'India: +91' && !phoneRegExp.test(value)) {
    //       return createError({
    //         message: 'Invalid Phone number',
    //         path,
    //       });
    //     }
    //     if (!isValidPhoneNumber(value, codesList as CountryCode)) {
    //       return createError({
    //         message: 'Invalid Phone number',
    //         path,
    //       });
    //     }

    //     return true;
    //   }),
    // countryNameAndCode: yup
    //   .string()
    //   .nullable()
    //   .test('countryNameAndCode', (value, context) => {
    //     const { createError, path, parent } = context;
    //     const codesList = getCountryCodes()
    //       .map((codes) => {
    //         if (codes.label === value) {
    //           return codes.countryCode;
    //         }
    //       })
    //       .filter((ele) => ele)
    //       .toString();
    //     if (!value) {
    //       return createError({
    //         message: 'Country Code is required',
    //         path,
    //       });
    //     }
    //     if (parent.phone && !isValidPhoneNumber(parent.phone, codesList as CountryCode)) {
    //       return createError({
    //         message: 'Invalid Mobile number',
    //         path,
    //       });
    //     }

    //     return true;
    //   }),
    //distributors_RM_Code: yup.string().required('Please Enter Distributor RM code'),
    rmId: yup
      .string()
      .nullable()
      .test('rmId', (value, context) => {
        const { createError, path } = context;
        if (!value) {
          return createError({
            message: "AMC's RM name is required",
            path,
          });
        } else if (!rmList.includes(value.toString())) {
          return createError({
            message: 'Invalid value',
            path,
          });
        }

        return true;
      }),
    // panNumber: yup
    //   .string()
    //   .uppercase()
    //   .length(10, 'PAN must be exactly 10 characters')
    //   .matches(individualPanRegex, 'Only individual PANs are allowed currently')
    //   .required('PAN is required'),
    // pincode: yup.string().nullable().required('Please Enter Pincode'),
    // buildingNo: yup.string().nullable().required('Please Enter Building Number'),
    // streetName: yup.string().nullable().required('Please Enter Street Name'),
    // city: yup.string().nullable().required('Please Enter City'),
    // state: yup.string().nullable().required('Please Enter State'),
    // country: yup.string().nullable().required('Please Enter Country'),
  });

// name,
// email,
// countryCode,
// phone,
// panNumber,
// distributors_RM_Code,
// rmId,
// distributorId,
// pincode,
// buildingNo,
// streetName,
// city,
// state,
// country,

// pan: yup
// .string()
// .nullable()
// .uppercase()
// .length(10, 'PAN must be exactly 10 characters')
// .matches(individualPanRegex, 'Only individual PANs are allowed')
// .test('pan', (value, context) => {
//   const { path, createError, options } = context;
//   const { context: optionsContext } = options as InternalOptions<Groups[]>;
//   const authorisedSignatoriesPanNumbers = optionsContext
//     ?.map((groups) => groups.groupsignatories.map((signatory) => signatory.pan))
//     .flat();
//   if (!value) {
//     return createError({
//       message: 'PAN is required',
//       path,
//     });
//   }
//   if (authorisedSignatoriesPanNumbers && authorisedSignatoriesPanNumbers.includes(value)) {
//     return createError({
//       message:
//         'There is already same pan number for an Authorised Signatory associated with this application',
//       path,
//     });
//   }

//   return true;
// }),

// mobile: yup
// .string()
// .nullable()
// .min(8, 'Invalid Mobile Number')
// .max(16, 'Invalid Mobile Number')
// .matches(phoneRegExp, 'Invalid Mobile Number')
// .test('mobile', (value, context) => {
//   const { path, createError, options } = context;
//   const { context: optionsContext } = options as InternalOptions<Groups[]>;
//   const authorisedSignatoriesMobileNumbers = optionsContext
//     ?.map((groups) => groups.groupsignatories.map((signatory) => signatory.mobile))
//     .flat();
//   if (!value) {
//     return createError({
//       message: 'Mobile Number is required',
//       path,
//     });
//   }
//   if (
//     authorisedSignatoriesMobileNumbers &&
//     authorisedSignatoriesMobileNumbers.includes(value)
//   ) {
//     return createError({
//       message:
//         'There is already same mobile number for an Authorised Signatory associated with this application',
//       path,
//     });
//   }

//   return true;
// }),
// email: yup
// .string()
// .nullable()
// .matches(emailRegex, 'invalid Email ID')
// .test('email', (value, context) => {
//   const { path, createError, options } = context;
//   const { context: optionsContext } = options as InternalOptions<Groups[]>;
//   const authorisedSignatoriesEmailID = optionsContext
//     ?.map((groups) => groups.groupsignatories.map((signatory) => signatory.email))
//     .flat();
//   if (!value) {
//     return createError({
//       message: 'Email ID is required',
//       path,
//     });
//   }
//   if (authorisedSignatoriesEmailID && authorisedSignatoriesEmailID.includes(value)) {
//     return createError({
//       message:
//         'There is already same Email ID for an Authorised Signatory associated with this application',
//       path,
//     });
//   }

//   return true;
// }),
export const amcAdminSchema = yup.object().shape({
  name: yup
    .string()
    .nullable()
    .matches(stringRegex, 'Special characters and numbers not allowed')
    .required('Name is required'),
  email: yup
    .string()
    .nullable()
    .matches(emailRegex, 'Invalid email id')
    .required('Email is required'),
  phone: yup
    .string()
    .nullable()
    .required('Mobile Number is required')
    .min(8, 'Invalid Mobile Number')
    .max(16, 'Invalid Mobile Number')
    .matches(phoneRegExp, 'Invalid Mobile Number'),
  companyName: yup
    .string()
    .nullable()
    .oneOf(Object.values(COMPANIES_MASTER), 'Invalid value')
    .required('Company Name is required'),
  comment: yup
    .string()
    .nullable()
    .when('checkId', {
      is: (value: string) => value,
      then: yup
        .string()
        .nullable()
        .matches(validCommentRegex, InvalidCommentsMessage)
        .required('Please provide Comments'),
    }),
});

export const NonIndividualContributorValidationSchema = (dematFieldValidation: string) =>
  yup.object().shape({
    applicants: yup.array().of(
      yup.object().shape({
        name: yup
          .string()
          .nullable()
          .matches(kraStringRegex, 'Special characters and numbers not allowed')
          .required('Name Of Entity is required'),
        cityOfIncorporation: yup
          .string()
          .nullable()
          .required('Place of Registration/Incorporation is required')
          .matches(stringRegex, 'Special characters and numbers not allowed'),
        dateOfBirth: yup
          .string()
          .nullable()
          .required('Date of Registration/Incorporation is required'),
        ckycNo: yup
          .string()
          .nullable()
          .test('ckycNo', (value, context) => {
            const fieldValue = value === undefined ? '' : value;
            const {
              options: { context: optionsContext },
              createError,
              path,
            } = context;
            const { applicants } = optionsContext || {};
            if (!fieldValue) {
              return createError({
                message: 'CKYC No./CKYC Acknowledge No. is required',
                path,
              });
            } else if (!ckycNumberRegex.test(fieldValue)) {
              // } else if (fieldValue.length !== 14) {
              return createError({
                message: 'Invalid CKYC No./CKYC Acknowledge No., Only 14 Digits Allowed',
                path,
              });
              // } else if (!numberRegex.test(fieldValue)) {
              //   return createError({
              //     message: 'CKYC No./CKYC Acknowledge No. allows only digits',
              //     path,
              //   });
            } else if (
              applicants.filter((applicant: Applicant) => applicant.ckycNo === fieldValue).length >
              1
            ) {
              return createError({
                message:
                  'There is already same CKYC Number for an applicant associated with this application',
                path,
              });
            }
            return true;
          }),
        // kinNo: yup
        //   .string()
        //   .nullable()
        //   .test('kinNo', (value, context) => {
        //     const fieldValue = value === undefined ? '' : value;
        //     const {
        //       options: { context: optionsContext },
        //       createError,
        //       path,
        //     } = context;
        //     const { applicants } = optionsContext || {};
        //     if (!fieldValue) {
        //       return true;
        //     } else if (fieldValue.length !== 14) {
        //       return createError({
        //         message: 'Invalid KIN No',
        //         path,
        //       });
        //     } else if (!numberRegex.test(fieldValue)) {
        //       return createError({
        //         message: 'KIN No allows only digits',
        //         path,
        //       });
        //     } else if (
        //       applicants.filter((applicant: Applicant) => applicant.kinNo === fieldValue).length > 1
        //     ) {
        //       return createError({
        //         message:
        //           'There is already same KIN Number for an applicant associated with this application',
        //         path,
        //       });
        //     }
        //     return true;
        //   }),
        panNo: yup
          .string()
          .nullable()
          .uppercase()
          .length(10, 'PAN must be exactly 10 characters')
          .matches(nonIndividualPanRegex, 'invalid PAN')
          .required('PAN is required'),
        investorType: yup
          .string()
          .nullable()
          .oneOf(Object.keys(investorTypeMasters), 'Invalid value for Investor Type')
          .required('Please Select Investor Type'),
        netWorth: yup
          .string()
          .nullable()
          .test('netWorth', (value, context) => {
            const fieldValue = value === undefined ? '' : value;
            const { path, createError } = context;
            if (!fieldValue) {
              return createError({
                message: 'Net Worth is required',
                path,
              });
            }
            if (!amountRegex.test(fieldValue)) {
              return createError({
                message: 'Invalid amount',
                path,
              });
            }
            return true;
          }),
        grossAnnualIncome: yup
          .string()
          .nullable()
          .required('Gross Annual Income is required')
          .oneOf(Object.keys(grossAnnualMasters), 'Invalid value for Gross Annual Income'),
        corporateIdentificationNo: yup
          .string()
          .nullable()
          .matches(alphaNumeric, 'Special characters not allowed'),
      })
    ),
    dpName: yup
      .string()
      .nullable()
      // .required('DP Name is required')
      .matches(kraStringRegex, 'Special characters and numbers not allowed')
      .test('dpName', 'DP Name is required', (value, context) => {
        const { parent } = context;
        return (
          (!value && !checkValidationBasedOnDate(parent?.createdAt || '', dematFieldValidation)) ||
          !!value
        );
      }),
    dpId: yup
      .string()
      .nullable()
      // .required('DP Id is required')
      .matches(alphaNumeric, 'Special characters not allowed')
      .test('dpId', 'DP Id is required', (value, context) => {
        const { parent } = context;
        return (
          (!value && !checkValidationBasedOnDate(parent?.createdAt || '', dematFieldValidation)) ||
          !!value
        );
      }),
    dpClientId: yup
      .string()
      .nullable()
      // .required('Client Id is required')
      .matches(alphaNumeric, 'Special characters not allowed')
      .test('dpClientId', 'Client Id is required', (value, context) => {
        const { parent } = context;
        return (
          (!value && !checkValidationBasedOnDate(parent?.createdAt || '', dematFieldValidation)) ||
          !!value
        );
      }),
  });

export const nonIndividualContactDetailsSchema = yup.object().shape({
  applicants: yup.array().of(
    yup.object().shape({
      contactperson: yup.object().shape({
        address1: yup
          .string()
          .nullable()
          .matches(
            addressRegex,
            "Invalid address line 1 format [special characters are not allowed except -/',&()#:.]"
          ),
        // .required('Address line 1 is required'),
        address2: yup
          .string()
          .nullable()
          .matches(
            addressRegex,
            "Invalid address line 2 format [special characters are not allowed except -/',&()#:.]"
          ),
        // .required('Address line 2 is required'),

        landLineNumber: yup
          .string()
          .nullable()
          .test('landLineNumber', (value, context) => {
            const fieldValue = value === undefined ? '' : value;
            const { path, createError, parent } = context;
            if (!fieldValue && parent.stdCode) {
              return createError({
                message: 'Please enter Landline number',
                path,
              });
            } else if (parent.landLineNumber && !numberRegex.test(parent.landLineNumber)) {
              return createError({
                message: 'Please enter valid Landline Number',
                path,
              });
            }
            return true;
          }),
        stdCode: yup
          .string()
          .nullable()
          .test('stdCode', (value, context) => {
            const fieldValue = value === undefined ? '' : value;
            const { path, createError, parent } = context;
            if (!fieldValue && parent.landLineNumber) {
              return createError({
                message: 'Please enter STD Code',
                path,
              });
            } else if (parent.stdCode && !numberRegex.test(parent.stdCode)) {
              return createError({
                message: 'Please enter valid STD Code',
                path,
              });
            }
            return true;
          }),

        city: yup
          .string()
          .nullable()
          .matches(
            addressRegex,
            "Invalid City format [special characters are not allowed except -/',&()#:.]"
          ),
        // .required('City is required'),
        state: yup
          .string()
          .nullable()
          .matches(stringRegex, 'Special characters and numbers not allowed'),
        // .required('State is required'),
        country: yup
          .string()
          .nullable()
          .test('country', (value, context) => {
            const { options, createError, path } = context;
            const { context: optionsContext } =
              options as InternalOptions<NonIndividualContactValues>;
            const { countryDropdown = [] } = optionsContext || {};
            // if (!value) {
            //   return createError({
            //     message: 'Country is required',
            //     path,
            //   });
            // }
            if (value && !countryDropdown.includes(value)) {
              return createError({
                message: 'Invalid value for Country',
                path,
              });
            }
            return true;
          }),
        pincode: yup
          .string()
          .nullable()
          .test('pincode', (value, context) => validateNonIndividualPincodeField(value, context)),
        name: yup
          .string()
          .nullable()
          .required('Name is required')
          .matches(stringRegex, 'Special characters and numbers not allowed'),
        email: yup
          .string()
          .nullable()
          .matches(emailRegex, 'invalid Email ID')
          .required('Email is required'),
        mobile: yup
          .string()
          .nullable()
          .test('mobile', (value, context) => {
            const { createError, path, parent } = context;
            const codesList = getCountryCodes()
              .map((codes) => {
                if (codes.label === parent.countryNameAndCode) {
                  return codes.countryCode;
                }
              })
              .filter((ele) => ele)
              .toString();
            if (!value) {
              return createError({
                message: 'Mobile is required',
                path,
              });
            }
            if (parent.countryNameAndCode === 'India: +91' && !phoneRegExp.test(value)) {
              return createError({
                message: 'Invalid Mobile number',
                path,
              });
            }
            if (!isValidPhoneNumber(value, codesList as CountryCode)) {
              return createError({
                message: 'Invalid Mobile number',
                path,
              });
            }

            return true;
          }),
        countryNameAndCode: yup
          .string()
          .nullable()
          .test('countryNameAndCode', (value, context) => {
            const { createError, path, parent } = context;
            const codesList = getCountryCodes()
              .map((codes) => {
                if (codes.label === value) {
                  return codes.countryCode;
                }
              })
              .filter((ele) => ele)
              .toString();
            if (!value) {
              return createError({
                message: 'Country Code is required',
                path,
              });
            }
            if (parent.mobile && !isValidPhoneNumber(parent.mobile, codesList as CountryCode)) {
              return createError({
                message: 'Invalid Mobile number',
                path,
              });
            }

            return true;
          }),
      }),
      address: yup.object().shape({
        correspondence: yup.object().shape({
          address1: yup
            .string()
            .nullable()
            .matches(
              addressRegex,
              "Invalid address line 1 format [special characters are not allowed except -/',&()#:.]"
            )
            .required('Address line 1 is required'),
          address2: yup
            .string()
            .nullable()
            .matches(
              addressRegex,
              "Invalid address line 2 format [special characters are not allowed except -/',&()#:.]"
            )
            .test('address2', 'Address line 2 is required', (value, context) => {
              const { options } = context;
              const { context: optionsContext, index } = options as InternalOptions<Values> & {
                index: number;
              };
              const { applicants = [] } = optionsContext || {};
              const currentApplicant = applicants[index] || {};
              const { fetchedFromKRA = null } = currentApplicant.address.correspondence || {};
              if (!fetchedFromKRA && !value) {
                return false;
              }
              return true;
            }),
          pincode: yup
            .string()
            .nullable()
            .test('pincode', (value, context) => validateNonIndividualPincodeField(value, context)),
          city: yup
            .string()
            .nullable()
            .required('City is required')
            .matches(
              addressRegex,
              "Invalid City format [special characters are not allowed except -/',&()#:.]"
            ),
          state: yup
            .string()
            .nullable()
            .required('State is required')
            .matches(stringRegex, 'Special characters and numbers not allowed'),
          country: yup
            .string()
            .nullable()
            .test('country', (value, context) => {
              const { options, createError, path } = context;
              const { context: optionsContext } =
                options as InternalOptions<NonIndividualContactValues>;
              const { countryDropdown = [] } = optionsContext || {};
              if (!value) {
                return createError({
                  message: 'Country is required',
                  path,
                });
              }
              if (!countryDropdown.includes(value)) {
                return createError({
                  message: 'Invalid value for Country',
                  path,
                });
              }
              return true;
            }),
        }),
        permanent: yup.object().shape({
          address1: yup
            .string()
            .nullable()
            .matches(
              addressRegex,
              "Invalid address line 1 format [special characters are not allowed except -/',&()#:.]"
            )
            .test('address1', 'Address line 1 is required', (value, context) =>
              validatePermanentAddressFields(value, context, APPLICATION_TYPE.NON_INDIVIDUAL)
            ),
          address2: yup
            .string()
            .nullable()
            .matches(
              addressRegex,
              "Invalid address line 2 format [special characters are not allowed except -/',&()#:.]"
            )
            // .test('address2', 'Address2 is required', (value, context) =>
            //   validatePermanentAddressFields(value, context, APPLICATION_TYPE.NON_INDIVIDUAL)
            // ),
            .test('address2', 'Address line 2 is required', (value, context) => {
              const { options } = context;
              const { context: optionsContext, index } = options as InternalOptions<Values> & {
                index: number;
              };
              const { applicants = [] } = optionsContext || {};
              const currentApplicant = applicants[index] || {};
              const { fetchedFromKRA = null } = currentApplicant.address.permanent || {};
              if (!fetchedFromKRA) {
                return validatePermanentAddressFields(
                  value,
                  context,
                  APPLICATION_TYPE.NON_INDIVIDUAL
                );
              }
              return true;
            }),
          pincode: yup
            .string()
            .nullable()
            .test('pincode', (value, context) => {
              const { options } = context;
              const { context: optionsContext, index } = options as InternalOptions<Values> & {
                index: number;
              };
              const { applicants = [] } = optionsContext || {};
              const currentApplicant = applicants[index] || {};
              const { permanentAddressSameAsCorresponding = false } =
                currentApplicant.address.correspondence || {};
              if (!permanentAddressSameAsCorresponding) {
                return validateNonIndividualPincodeField(value, context);
              }
              return true;
            }),
          city: yup
            .string()
            .nullable()
            .matches(
              addressRegex,
              "Invalid City format [special characters are not allowed except -/',&()#:.]"
            )
            .test('city', 'City is required', (value, context) =>
              validatePermanentAddressFields(value, context, APPLICATION_TYPE.NON_INDIVIDUAL)
            ),
          state: yup
            .string()
            .nullable()
            .matches(stringRegex, 'Special characters and numbers not allowed')
            .test('state', 'State is required', (value, context) =>
              validatePermanentAddressFields(value, context, APPLICATION_TYPE.NON_INDIVIDUAL)
            ),
          country: yup
            .string()
            .nullable()
            .test('country', (value, context) => {
              const { options, createError, path } = context;
              const { context: optionsContext, index } =
                options as InternalOptions<NonIndividualContactValues> & {
                  index: number;
                };
              const { applicants = [], countryDropdown = [] } = optionsContext || {};
              const currentApplicant = applicants[index] || {};
              const { permanentAddressSameAsCorresponding = false } =
                currentApplicant.address.correspondence || {};
              if (!permanentAddressSameAsCorresponding) {
                if (!value) {
                  return createError({
                    message: 'Country is required',
                    path,
                  });
                }
                if (!countryDropdown.includes(value)) {
                  return createError({
                    message: 'Invalid value for Country',
                    path,
                  });
                }
              }
              return true;
            }),
          // .test('country', 'Country is required', (value, context) =>
          //   validatePermanentAddressFields(value, context)
          // ),
        }),
      }),
    })
  ),
});

export const nonIndividualDocumentDetailsSchema = (bankAccountProofValidation: string) =>
  yup.object().shape({
    ppmNo: yup
      .string()
      .nullable()
      .matches(defaultRegexWithSingleLine, 'Invalid value for PPM Number'),
    // .length(10, 'Invalid PPM Number'),
    applicants: yup.array().of(
      yup.object().shape({
        documents: yup.array().of(
          yup.object().shape({
            documentsList: yup.array().of(
              yup.object().shape({
                documentName: yup
                  .string()
                  .nullable()
                  .test(
                    'documentName',
                    (
                      value: string | undefined | null,
                      context: yup.TestContext<Record<string, NonIndividualDocValues>>
                    ) => {
                      const { options, createError, path } = context;
                      const { context: optionsContext, parent } =
                        options as InternalOptions<NonIndividualDocValues> & { index: number };
                      const { applicants = [], banks } =
                        optionsContext || ({} as NonIndividualDocValues);
                      const { uniqueKey = '' } = parent || {};
                      // eslint-disable-next-line
                      const [parent1, parent2, parent3, parent4, ...rest] = (context as any).from;
                      const indexes = uniqueKey.split('-');
                      const {
                        required,
                        documentName,
                        documentType,
                        options: optionsDropdown,
                      } = applicants[indexes[0]].documents[indexes[1]].documentsList[indexes[2]];
                      const fieldValue =
                        optionsDropdown && value && optionsDropdown.includes(value);
                      if (optionsDropdown) {
                        if (
                          documentType === 'bankAccountProof' &&
                          !fieldValue &&
                          banks &&
                          // .filter((bank) => !bank.pennydropVerified && bank.defaultBankAccount)
                          banks.length !== 0 &&
                          checkValidationBasedOnDate(
                            parent4.value.createdAt || '',
                            bankAccountProofValidation
                          )
                        ) {
                          return createError({
                            message: `Please Select ${documentName}`,
                            path,
                          });
                        }
                        if (!fieldValue && required === 'true') {
                          return createError({
                            message: `Please Select ${documentName}`,
                            path,
                          });
                        }
                      }
                      return true;
                    }
                  ),
                documentId: yup
                  .string()
                  .nullable()
                  .test(
                    'documentId',
                    (
                      value: string | undefined | null,
                      context: yup.TestContext<Record<string, NonIndividualDocValues>>
                    ) => {
                      const { options, createError, path } = context;
                      const { context: optionsContext, parent } =
                        options as InternalOptions<NonIndividualDocValues> & { index: number };
                      const { applicants = [], banks } =
                        optionsContext || ({} as NonIndividualDocValues);
                      const { uniqueKey = '' } = parent || {};
                      // eslint-disable-next-line
                      const [parent1, parent2, parent3, parent4, ...rest] = (context as any).from;
                      const indexes = uniqueKey.split('-');
                      const { required, documentName, documentType, isVisible } =
                        applicants[indexes[0]].documents[indexes[1]].documentsList[indexes[2]];
                      if (required === 'false') {
                        return true;
                      }
                      if (!value && isVisible) {
                        if (
                          !value &&
                          banks &&
                          documentType === 'bankAccountProof' &&
                          // .filter((bank) => !bank.pennydropVerified && bank.defaultBankAccount)
                          banks.length !== 0 &&
                          checkValidationBasedOnDate(
                            parent4.value.createdAt || '',
                            bankAccountProofValidation
                          )
                        ) {
                          return createError({
                            message: `Please upload ${documentName}`,
                            path,
                          });
                        }
                        if (required === 'true' && documentType !== 'bankAccountProof') {
                          return createError({
                            message: `Please upload ${documentName}`,
                            path,
                          });
                        }
                      }
                      return true;
                    }
                  ),
              })
            ),
          })
        ),
      })
    ),
  });

export const nonIndividualAuthorisedSignatories = yup.object().shape({
  name: yup
    .string()
    .nullable()
    .required('Name is required')
    .matches(stringRegex, 'Special characters and numbers not allowed'),
  pan: yup
    .string()
    .nullable()
    .uppercase()
    .length(10, 'PAN must be exactly 10 characters')
    .matches(individualPanRegex, 'Only individual PANs are allowed')
    .test('pan', (value, context) => {
      const { path, createError, options } = context;
      const { context: optionsContext } = options as InternalOptions<Groups[]>;
      const authorisedSignatoriesPanNumbers = optionsContext
        ?.map((groups) => groups.groupsignatories.map((signatory) => signatory.pan))
        .flat();
      if (!value) {
        return createError({
          message: 'PAN is required',
          path,
        });
      }
      if (authorisedSignatoriesPanNumbers && authorisedSignatoriesPanNumbers.includes(value)) {
        return createError({
          message:
            'There is already same pan for an Authorised Signatory associated with this application',
          path,
        });
      }

      return true;
    }),
  mobile: yup
    .string()
    .nullable()
    .test('mobile', (value, context) => {
      const { path, createError, options, parent } = context;
      const { context: optionsContext } = options as InternalOptions<Groups[]>;
      const authorisedSignatoriesMobileNumbers = optionsContext
        ?.map((groups) => groups.groupsignatories.map((signatory) => signatory.mobile))
        .flat();
      const codesList = getCountryCodes()
        .map((codes) => {
          if (codes.label === parent.countryNameAndCode) {
            return codes.countryCode;
          }
        })
        .filter((ele) => ele)
        .toString();
      if (!value) {
        return createError({
          message: 'Mobile Number is required',
          path,
        });
      }
      if (parent.countryNameAndCode === 'India: +91' && !phoneRegExp.test(value)) {
        return createError({
          message: 'Invalid Mobile number',
          path,
        });
      }
      if (!isValidPhoneNumber(value, codesList as CountryCode)) {
        return createError({
          message: 'Invalid Mobile number',
          path,
        });
      }
      if (
        authorisedSignatoriesMobileNumbers &&
        authorisedSignatoriesMobileNumbers.includes(value)
      ) {
        return createError({
          message:
            'There is already same mobile number for an Authorised Signatory associated with this application',
          path,
        });
      }

      return true;
    }),

  countryNameAndCode: yup
    .string()
    .nullable()
    .test('countryNameAndCode', (value, context) => {
      const { createError, path, parent } = context;
      const codesList = getCountryCodes()
        .map((codes) => {
          if (codes.label === value) {
            return codes.countryCode;
          }
        })
        .filter((ele) => ele)
        .toString();
      if (!value) {
        return createError({
          message: 'Country Code is required',
          path,
        });
      }
      if (parent.mobile && !isValidPhoneNumber(parent.mobile, codesList as CountryCode)) {
        return createError({
          message: 'Invalid Mobile number',
          path,
        });
      }

      return true;
    }),
  email: yup
    .string()
    .nullable()
    .matches(emailRegex, 'invalid Email ID')
    .test('email', (value, context) => {
      const { path, createError, options } = context;
      const { context: optionsContext } = options as InternalOptions<Groups[]>;
      const authorisedSignatoriesEmailID = optionsContext
        ?.map((groups) => groups.groupsignatories.map((signatory) => signatory.email))
        .flat();
      if (!value) {
        return createError({
          message: 'Email ID is required',
          path,
        });
      }
      if (authorisedSignatoriesEmailID && authorisedSignatoriesEmailID.includes(value)) {
        return createError({
          message:
            'There is already same Email ID for an Authorised Signatory associated with this application',
          path,
        });
      }

      return true;
    }),
  designation: yup
    .string()
    .nullable()
    .required('Designation is required')
    .matches(stringRegex, 'Special characters and numbers not allowed'),
});

const fieldValidation = (
  value: string | undefined | null | number,
  context: yup.TestContext<Record<string, ubo>>,
  mes: string
) => {
  const fieldValue = value === undefined ? '' : value;
  const { path, createError, parent } = context;
  if (!fieldValue && !parent.isFetchData) {
    return createError({
      message: `${mes} is required`,
      path,
    });
  }
  return true;
};

export const nonIndividualUbo = (countryDropdown: mdmsCountriesList[]) =>
  yup.object().shape({
    panNumber: yup
      .string()
      .nullable()
      .uppercase()
      .matches(alphaNumeric, 'Special characters not allowed')
      .test('panNumber', (value, context) => {
        const { path, createError, options } = context;
        const { context: optionsContext } = options as InternalOptions<ubo[]>;

        const uboPanNumbers = optionsContext?.map((ubo) => ubo.panNumber);
        if (!value) {
          return createError({
            message: 'Taxpayer ID Number/PAN/Equivalent ID Number is required',
            path,
          });
        }
        if (uboPanNumbers && uboPanNumbers.includes(value)) {
          return createError({
            message:
              'There is already same Taxpayer ID Number/PAN/Equivalent ID Number for an Ultimate Benificiary Ownership (UBO) associated with this application',
            path,
          });
        }

        return true;
      }),

    dob: yup
      .string()
      .nullable()
      .test('dob', (value, context) => {
        const fieldValue = value === undefined ? '' : value;
        const { path, createError, parent } = context;
        if (!fieldValue) {
          return createError({
            message: 'Date of birth is required',
            path,
          });
        }
        if (
          parent.panNumber &&
          individualPanRegex.test(parent.panNumber) &&
          isMinor(fieldValue || '')
        ) {
          return createError({
            message: 'Age should be greater than 18',
            path,
          });
        }
        if (
          parent.panNumber &&
          individualPanRegex.test(parent.panNumber) &&
          maxAge(fieldValue || '')
        ) {
          return createError({
            message: 'Age should be less than 125',
            path,
          });
        }
        return true;
      }),
    name: yup
      .string()
      .nullable()
      .matches(stringRegex, 'Special characters and numbers not allowed')
      .test('name', (value, context) => {
        return fieldValidation(value, context, 'Name');
      }),
    identificationType: yup
      .string()
      .nullable()
      .matches(stringRegex, 'Special characters and numbers not allowed')
      .test('identificationType', (value, context) => {
        return fieldValidation(value, context, 'Identification Type');
      }),
    percentageOfBeneficialInterest: yup
      .number()
      .nullable()
      .test('percentageOfBeneficialInterest', (value, context) => {
        const fieldValue = value;
        const { path, createError, parent } = context;
        if (fieldValue === null || (fieldValue === undefined && !parent.isFetchData)) {
          return createError({
            message: `Percentage Of Beneficial Interest is required`,
            path,
          });
        }
        if (fieldValue === 0 && !parent.isFetchData) {
          return createError({
            message: `Percentage Of Beneficial Interest should be greater 0`,
            path,
          });
        }
        if (fieldValue) {
          if (fieldValue < 0 && !parent.isFetchData) {
            return createError({
              message: `Percentage Of Beneficial Interest should be greater 0`,
              path,
            });
          }
          if (fieldValue > 100 && !parent.isFetchData) {
            return createError({
              message: `Percentage Of Beneficial Interest must not exceed 100%`,
              path,
            });
          }
        }
        return true;
      }),
    countryOfTaxResidency: yup
      .string()
      .nullable()
      .test('countryOfTaxResidency', (value, context) => {
        return fieldValidation(value, context, 'Country Of Tax Residency');
      })
      .oneOf(
        countryDropdown.map((country) => country.name),
        'Invalid Country Of Tax Residency'
      ),
    cpUboCode: yup
      .string()
      .nullable()
      .oneOf(Object.keys(CpUboCodesMaster), 'Invalid value for CP/UBO Code')
      .test('cpUboCode', (value, context) => {
        return fieldValidation(value, context, 'Cp/UboCode');
      }),
    placeAndCountryOfBirth: yup
      .string()
      .nullable()
      .matches(stringRegex, 'Special characters and numbers not allowed')
      .test('placeAndCountryOfBirth', (value, context) => {
        return fieldValidation(value, context, 'Place And Country Of Birth');
      }),
    occupation: yup
      .string()
      .nullable()
      .oneOf(Object.keys(occupationDetailsMasters), 'Invalid value for Occupation')
      .test('occupation', (value, context) => {
        return fieldValidation(value, context, 'Occupation');
      }),
    otherOccupation: yup
      .string()
      .nullable()
      .when('occupation', {
        is: (occupation: string) =>
          occupationDetailsMasters[occupation] === occupationDetailsMasters.others,
        then: yup
          .string()
          .nullable()
          .matches(stringRegex, 'Special characters and numbers not allowed')
          .test('otherOccupation', (value, context) => {
            return fieldValidation(value, context, 'Other Occupation');
          }),
      }),
    gender: yup
      .string()
      .nullable()
      .test('gender', (value, context) => {
        return fieldValidation(value, context, 'Gender');
      })
      .oneOf(genderMasters, 'Invalid value for Gender'),
    nationality: yup
      .string()
      .nullable()
      .oneOf(
        countryDropdown.map((country) => country.nationality),
        'Invalid value for Nationality'
      )
      .test('nationality', (value, context) => {
        return fieldValidation(value, context, 'Nationality');
      }),
    fatherName: yup
      .string()
      .nullable()
      .matches(stringRegex, 'Special characters and numbers not allowed')
      .test('fatherName', (value, context) => {
        return fieldValidation(value, context, 'FatherName');
      }),
    ckycNumber: yup
      .string()
      .nullable()
      // .length(14, 'Invalid CKYC Number')
      .test('ckycNumber', (value, context) => {
        const fieldValue = value === undefined ? '' : value;
        const { path, createError, options, parent } = context;
        const { context: optionsContext } = options as InternalOptions<ubo[]>;
        if (!fieldValue && !parent.isFetchData) {
          return createError({
            message: 'CKYC Number is required',
            path,
          });
        } else if (!ckycNumberRegex.test(fieldValue as string) && !parent.isFetchData) {
          return createError({
            message: 'Invalid CKYC Number, Only 14 Digits Allowed',
            path,
          });
        }
        const uboCkycNumbers = optionsContext?.map((ubo) => Number(ubo.ckycNumber).toString());
        if (
          uboCkycNumbers &&
          uboCkycNumbers.includes(fieldValue as string) &&
          !parent.isFetchData
        ) {
          return createError({
            message:
              'There is already same CKYC Number for an Ultimate Benificiary Ownership (UBO) associated with this application',
            path,
          });
        }
        return true;
      }),
    address2: yup
      .string()
      .nullable()
      .matches(
        addressRegex,
        "Invalid address line 1 format [special characters are not allowed except -/',&()#:.]"
      )
      .test('address2', (value, context) => {
        return fieldValidation(value, context, 'Address Line 1');
      }),
    address3: yup
      .string()
      .nullable()
      .matches(
        addressRegex,
        "Invalid address line 2 format [special characters are not allowed except -/',&()#:.]"
      )
      .test('address3', (value, context) => {
        return fieldValidation(value, context, 'Address Line 2');
      }),
    city: yup
      .string()
      .nullable()
      .matches(
        addressRegex,
        "Invalid City format [special characters are not allowed except -/',&()#:.]"
      )
      .test('city', (value, context) => {
        return fieldValidation(value, context, 'City');
      }),
    state: yup
      .string()
      .nullable()
      .matches(stringRegex, 'Special characters and numbers not allowed')
      .test('state', (value, context) => {
        return fieldValidation(value, context, 'State');
      }),
    country: yup
      .string()
      .nullable()
      .test('country', (value, context) => {
        return fieldValidation(value, context, 'Country');
      })
      .oneOf(
        countryDropdown.map((country) => country.name),
        'Invalid Country'
      ),
    pincode: yup
      .string()
      .nullable()
      .test('pincode', (value, context) => {
        const fieldValue = value === undefined ? '' : value;
        const { path, createError, parent } = context;
        if (!fieldValue && !parent.isFetchData) {
          return createError({
            message: 'Pincode is required',
            path,
          });
        } else if (
          parent.country?.toLowerCase() === 'india' &&
          !indianPin.test(fieldValue as string) &&
          !parent.isFetchData
        ) {
          return createError({
            message: 'Pincode length should be 6 digits',
            path,
          });
        } else if (
          parent.country?.toLowerCase() !== 'india' &&
          !alphaNumeric.test(fieldValue as string) &&
          !parent.isFetchData
        ) {
          return createError({
            message: 'Invalid Pincode',
            path,
          });
        }
        return true;
      }),
  });

export const nonIndividualControllingPersons = (countryDropdown: mdmsCountriesList[]) =>
  yup.object().shape({
    name: yup
      .string()
      .nullable()
      .matches(stringRegex, 'Special characters and numbers not allowed')
      .required('Name of controlling person is required'),
    correspondenceAddress: yup
      .string()
      .nullable()
      .matches(
        addressRegex,
        "Invalid address format [special characters are not allowed except -/',&()#:.]"
      )
      .required('Correspondence address is required'),
    countryOfResidense: yup
      .string()
      .nullable()
      .required('Country of residence for tax purposes is required')
      .oneOf(
        countryDropdown.map((country) => country.name),
        'Invalid Country Of Tax Residency'
      ),
    issuingCountry: yup
      .string()
      .nullable()
      .required('Issuing country is required')
      .oneOf(
        countryDropdown.map((country) => country.name),
        'Invalid Country Of Tax Residency'
      ),
    controllingPersonType: yup
      .string()
      .nullable()
      .matches(alphaNumeric, 'Special characters not allowed')
      .required('Controlling Person Type is required'),
    indentificationType: yup
      .string()
      .nullable()
      .matches(alphaNumeric, 'Special characters not allowed')
      .required('Indentification Type is required'),
    indentificationNumber: yup
      .string()
      .nullable()
      .test('indentificationNumber', (value, context) => {
        const { path, createError, options } = context;
        const { context: optionsContext } = options as InternalOptions<ControllingPersons[]>;
        const indentificationNumberArr = optionsContext?.map(
          (person) => person.indentificationNumber
        );
        if (!value) {
          return createError({
            message: 'Indentification Number is required',
            path,
          });
        }
        if (value && !alphaNumeric.test(value)) {
          return createError({
            message: 'Special Characters are Not allowed',
            path,
          });
        }
        if (indentificationNumberArr && indentificationNumberArr.includes(value)) {
          return createError({
            message:
              'There is already same Indentification Number for a Controlling Persons associated with this application',
            path,
          });
        }

        return true;
      }),
    occupationType: yup
      .string()
      .nullable()
      .required('Occupation Type is required')
      .matches(alphaNumeric, 'Special characters not allowed'),
    occupation: yup
      .string()
      .nullable()
      .oneOf(Object.keys(occupationDetailsMasters), 'Invalid value for Occupation')
      .required('Occupation is required'),
    otherOccupation: yup
      .string()
      .nullable()
      .when('occupation', {
        is: (occupation: string) =>
          occupationDetailsMasters[occupation] === occupationDetailsMasters.others,
        then: yup
          .string()
          .nullable()
          .required('Other Occupation is required')
          .matches(alphaNumeric, 'Special characters not allowed'),
      }),
    dateOfBirth: yup
      .string()
      .nullable()
      .test('dateOfBirth', (value, context) => {
        const { path, createError } = context;
        if (!value) {
          return createError({
            message: 'Date of Birth is required',
            path,
          });
        }
        if (isMinor(value)) {
          return createError({
            message: 'Age should be greater than 18',
            path,
          });
        }
        if (maxAge(value)) {
          return createError({
            message: 'Age should be less than 125',
            path,
          });
        }
        return true;
      }),
    countryOfBirth: yup
      .string()
      .nullable()
      .required('Country Of Birth is required')
      .oneOf(
        countryDropdown.map((country) => country.name),
        'Invalid Country Of Tax Residency'
      ),
    nationality: yup
      .string()
      .nullable()
      .required('Nationality is required')
      .oneOf(
        countryDropdown.map((country) => country.nationality),
        'Invalid Country Of Tax Residency'
      ),
    tin: yup
      .string()
      .nullable()
      .test('tin', (value, context) => {
        const { path, createError, options } = context;
        const { context: optionsContext } = options as InternalOptions<ControllingPersons[]>;
        const controllingPersons = optionsContext?.map((person) => person.tin);
        if (!value) {
          return createError({
            message: 'TIN is required',
            path,
          });
        }
        if (value && !alphaNumeric.test(value)) {
          return createError({
            message: 'Special Characters are Not allowed',
            path,
          });
        }
        if (controllingPersons && controllingPersons.includes(value)) {
          return createError({
            message:
              'There is already same Tin for a Controlling Persons associated with this application',
            path,
          });
        }

        return true;
      }),
  });

type errorObj = { fieldPath: string; fieldName: string };

const subQuestionsValidation = (
  fatca: nonIndividualQuestionsFatca,
  path: string
): errorObj | errorObj[] | undefined => {
  if (fatca.isVisible && fatca.backend_key) {
    if (
      !fatca.answer &&
      fatca.isMandatory === 'true' &&
      fatca.question_type !== 'Controlling_person_details'
    ) {
      const fieldPath =
        path + '.' + getSubQuestionsIndex(fatca.id.split('Q')[1].split('.'), '', true);
      return {
        fieldPath: fieldPath,
        fieldName:
          fatca.question_type === 'single_choice_radio'
            ? 'Field is required'
            : fatca.question_text
            ? fatca.question_text + ' is required'
            : (fatca.placeholder || 'Field') + ' is required',
      };
    }
    if (fatca.answer) {
      if (fatca.validation === 'alphaNumeric' && !alphaNumeric.test(fatca.answer)) {
        const fieldPath =
          path + '.' + getSubQuestionsIndex(fatca.id.split('Q')[1].split('.'), '', true);
        return {
          fieldPath: fieldPath,
          fieldName: 'Special Characters are Not allowed',
        };
      }
      if (fatca.validation === 'stringRegex' && !stringRegex.test(fatca.answer)) {
        const fieldPath =
          path + '.' + getSubQuestionsIndex(fatca.id.split('Q')[1].split('.'), '', true);
        return {
          fieldPath: fieldPath,
          fieldName: 'Special characters and numbers not allowed',
        };
      }
      if (
        fatca.validation === 'oneOf' &&
        !fatca?.options?.map((op: any) => op?.label)?.includes(fatca.answer)
      ) {
        const fieldPath =
          path + '.' + getSubQuestionsIndex(fatca.id.split('Q')[1].split('.'), '', true);
        return {
          fieldPath: fieldPath,
          fieldName: 'Invalid Value',
        };
      }
    }
  }
  if (fatca.question_type === 'group' && fatca.isVisible && fatca.sub_questions?.length) {
    return fatca.sub_questions
      ?.map((qun) => subQuestionsValidation(qun, path) as errorObj)
      .filter((ele: errorObj) => ele)
      .flat();
  }
};

export const nonIndividualFatcaSchema = yup.object().shape({
  applicants: yup.array().of(
    yup.object().shape({
      fatcadetail: yup
        .array()
        .nullable()
        .of(
          yup.object().shape({
            answer: yup
              .string()
              .nullable()
              .when('fatcaCrsDocumentCheck', {
                is: (fatcaCrsDocumentCheck: boolean | null) => !fatcaCrsDocumentCheck,
                then: yup
                  .string()
                  .nullable()
                  .test('answer', (value, context) => {
                    const { path, createError, parent } = context;
                    if (parent.isVisible && parent.backend_key) {
                      if (
                        !value &&
                        parent.isMandatory === 'true' &&
                        parent.question_type !== 'single_choice_radio'
                      ) {
                        return createError({
                          message:
                            parent.question_type === 'open_text_field'
                              ? (parent.placeholder || 'Field') + ' is required'
                              : parent.question_text
                              ? parent.question_text + ' is required'
                              : (parent.placeholder || 'Field') + ' is required',
                          path,
                        });
                      }
                      if (value) {
                        if (parent.validation === 'alphaNumeric' && !alphaNumeric.test(value)) {
                          return createError({
                            message: 'Special Characters are Not allowed',
                            path,
                          });
                        }
                        if (parent.validation === 'stringRegex' && !stringRegex.test(value)) {
                          return createError({
                            message: 'Special characters and numbers not allowed',
                            path,
                          });
                        }
                        if (
                          parent.validation === 'oneOf' &&
                          !parent.options.map((op: any) => op?.label).includes(value)
                        ) {
                          return createError({
                            message: 'Invalid Value',
                            path,
                          });
                        }
                      }
                    }
                    if (
                      parent.question_type === 'group' &&
                      parent.isVisible &&
                      parent.sub_questions
                    ) {
                      //subQuestionsValidation(value, context)
                      const res: errorObj[] = [];
                      const val = parent.sub_questions
                        ?.map((qun: nonIndividualQuestionsFatca) => {
                          const r = subQuestionsValidation(qun, path?.split('.')[0]) as errorObj;
                          return r;
                          // return (r as errorObj)
                          //   ? new yup.ValidationError(r.fieldName, '', `${r.fieldPath}.answer`)
                          //   : (r as unknown as errorObj[])?.map(
                          //       (ele: errorObj) =>
                          //         new yup.ValidationError(ele.fieldName, '', `${ele.fieldPath}.answer`)
                          //     );
                        })
                        .filter((ele: errorObj) => ele)
                        .flat();
                      // .toString();
                      if (val.length) {
                        const error = val.map((ele: errorObj) => {
                          return new yup.ValidationError(
                            ele.fieldName,
                            '',
                            `${ele.fieldPath}.answer`
                          );
                        });
                        if (error.length) {
                          return new yup.ValidationError(error);
                        }

                        // return createError({
                        //   message: 'Field is Required1',
                        //   path: `${val}.answer`,
                        // });
                      }
                      return true;
                    }
                    return true;
                  }),
              }),
          })
        ),
    })
  ),
});

export const addPlanValidation = yup.object().shape({
  tcLink: yup.string().nullable().required('PPM Link is required'),
  planCode: yup
    .string()
    .nullable()
    .matches(defaultRegexWithSingleLine, 'Invalid Plan Code')
    .required('Plan Code is required'),
  planDescription: yup
    .string()
    .nullable()
    .matches(defaultRegexWithSingleLine, 'Invalid Plan Description')
    .required('Plan Description is required'),
  schemeNature: yup
    .string()
    .nullable()
    .matches(stringRegex, 'Special characters and numbers not allowed')
    .required('Scheme Nature is required'),
  // registrationNumber: yup.string().nullable().required('Registration Number is required'),
  fundManagerId: yup.string().nullable().required('Investment Manager Name is required'),
  trusteeId: yup.string().nullable().required('Trustee Name is required'),
  schemeCategory: yup
    .string()
    .nullable()
    .matches(defaultRegexWithSingleLine, 'Invalid Scheme Category')
    .required('Scheme Category is required'),
  minCommitmentAmount: yup
    .number()
    .nullable()
    .moreThan(0, `Minimum Commitment Amount should be greater than 0`)
    .required('Minimum Commitment Amount is required'),
  maxCommitmentAmount: yup
    .number()
    .nullable()
    .min(
      yup.ref('minCommitmentAmount'),
      'Maximum Commitment Amount should be greater than or equal to Minimum Commitment Amount'
    )
    .required('Maximum Commitment Amount is required'),
  country: yup
    .string()
    .nullable()
    .matches(stringRegex, 'Special characters and numbers not allowed'),
  currency: yup
    .string()
    .nullable()
    .matches(stringRegex, 'Special characters and numbers not allowed'),
  custodian: yup
    .string()
    .nullable()
    .matches(stringRegex, 'Special characters and numbers not allowed'),
  fundAddress1: yup
    .string()
    .nullable()
    .matches(
      addressRegex,
      "Invalid fund address 1 format [special characters are not allowed except -/',&()#:.]"
    ),
  fundAddress2: yup
    .string()
    .nullable()
    .matches(
      addressRegex,
      "Invalid fund address 2 format [special characters are not allowed except -/',&()#:.]"
    ),
  fundAddress3: yup
    .string()
    .nullable()
    .matches(
      addressRegex,
      "Invalid fund address 3 format [special characters are not allowed except -/',&()#:.]"
    ),
  lockInPeriod: yup.string().nullable().matches(numberWithDecimalsRegex, 'Invalid Lockin Period'),
  lockInExtension: yup
    .string()
    .nullable()
    .matches(numberWithDecimalsRegex, 'Invalid Lockin Period'),
  faceValue: yup
    .number()
    .nullable()
    .test('faceValue', (value, context) => {
      const fieldValue = value === null ? '' : value;
      const { createError, path } = context;
      if (!fieldValue && fieldValue !== 0) {
        return createError({
          message: 'Face Value should be greater than or equal to 0',
          path,
        });
      }
      return true;
    }),
  setupFee: yup
    .number()
    .nullable()
    .test('setupFee', (value, context) => {
      const fieldValue = value === null ? '' : value;
      const { createError, path } = context;
      if (!fieldValue && fieldValue !== 0) {
        return createError({
          message: 'Setup Fee should be greater than or equal to 0',
          path,
        });
      }
      return true;
    }),
  minContributionPercentage: yup
    .number()
    .nullable()
    .test('minContributionPercentage', (value, context) => {
      const fieldValue = value === null ? '' : value;
      const { createError, path } = context;
      if (!fieldValue && fieldValue !== 0) {
        return createError({
          message: 'Min Contribution Percentage Fee should be greater than or equal to 0',
          path,
        });
      }
      return true;
    })
    .max(100, 'Minimum Contribution Percentage Fee cannot be greater than 100'),
  managementFee: yup
    .number()
    .nullable()
    .test(' managementFee', (value, context) => {
      const fieldValue = value === null ? '' : value;
      const { createError, path } = context;
      if (!fieldValue && fieldValue !== 0) {
        return createError({
          message: 'Management Fee should be greater than or equal to 0',
          path,
        });
      }
      return true;
    }),
  comment: yup
    .string()
    .nullable()
    .when('checkId', {
      is: (value: string) => value,
      then: yup
        .string()
        .nullable()
        .matches(validCommentRegex, InvalidCommentsMessage)
        .required('Please provide Comments'),
    }),
  referralFee: yup
    .number()
    .nullable()
    .test('referralFee', (value, context) => {
      const fieldValue = value === null ? '' : value;
      const { createError, path } = context;
      if (!fieldValue && fieldValue !== 0) {
        return createError({
          message:
            'Referral fee is % of management fee charged should be greater than or equal to 0',
          path,
        });
      }
      return true;
    })
    .max(100, 'Referral fee is % of management fee charged cannot be greater than 100'),
  minInvestmentAmt: yup
    .number()
    .nullable()
    .min(0, `Group Investment Commitment Amount should be greater than or equal to 0`)
    .max(
      yup.ref('minCommitmentAmount'),
      `Group Investment Commitment Amount should be less than or equal to Minimum Commitment Amount`
    )
    .required('Group Investment Commitment Amount is required'),
});

export const addFundValidation = yup.object().shape({
  tcLink: yup.string().nullable().required('PPM Link is required'),
  schemeCode: yup
    .string()
    .nullable()
    .matches(defaultRegexWithSingleLine, 'Invalid Scheme Code')
    .required('Scheme Code is required'),
  schemeName: yup
    .string()
    .nullable()
    .matches(defaultRegexWithSingleLine, 'Invalid Scheme Name')
    .required('Scheme Name is required'),
  planCode: yup
    .string()
    .nullable()
    .matches(defaultRegexWithSingleLine, 'Invalid Plan Code')
    .required('Plan Code is required'),
  planDescription: yup
    .string()
    .nullable()
    .matches(defaultRegexWithSingleLine, 'Invalid Plan Description')
    .required('Plan Description is required'),
  schemeNature: yup
    .string()
    .nullable()
    .matches(stringRegex, 'Special characters and numbers not allowed')
    .required('Scheme Nature is required'),
  // registrationNumber: yup.string().nullable().required('Registration Number is required'),
  fundManagerId: yup.string().nullable().required('Investment Manager Name is required'),
  trusteeId: yup.string().nullable().required('Trustee Name is required'),
  schemeCategory: yup
    .string()
    .nullable()
    .matches(defaultRegexWithSingleLine, 'Invalid Scheme Category')
    .required('Scheme Category is required'),
  minCommitmentAmount: yup
    .number()
    .nullable()
    .moreThan(0, `Minimum Commitment Amount should be greater than 0`)
    .required('Minimum Commitment Amount is required'),
  maxCommitmentAmount: yup
    .number()
    .nullable()
    .min(
      yup.ref('minCommitmentAmount'),
      'Maximum Commitment Amount should be greater than or equal to Minimum Commitment Amount'
    )
    .required('Maximum Commitment Amount is required'),
  bankName: yup
    .string()
    .nullable()
    .matches(stringRegex, 'Special characters and numbers not allowed'),
  bankBranch: yup
    .string()
    .nullable()
    .matches(stringRegex, 'Special characters and numbers not allowed'),
  amcCode: yup.string().nullable().matches(defaultRegexWithSingleLine, 'Invalid AMC Code'),
  ifscCode: yup.string().nullable().matches(ifscRegex, 'Invalid IFSC Code'),
  bankAccountNumber: yup
    .string()
    .nullable()
    .matches(accountNumberRegex, 'Please enter valid Account Number'),
  country: yup
    .string()
    .nullable()
    .matches(stringRegex, 'Special characters and numbers not allowed'),
  currency: yup
    .string()
    .nullable()
    .matches(stringRegex, 'Special characters and numbers not allowed'),
  custodian: yup
    .string()
    .nullable()
    .matches(stringRegex, 'Special characters and numbers not allowed'),
  fundAddress1: yup
    .string()
    .nullable()
    .matches(
      addressRegex,
      "Invalid fund address 1 format [special characters are not allowed except -/',&()#:.]"
    ),
  fundAddress2: yup
    .string()
    .nullable()
    .matches(
      addressRegex,
      "Invalid fund address 2 format [special characters are not allowed except -/',&()#:.]"
    ),
  fundAddress3: yup
    .string()
    .nullable()
    .matches(
      addressRegex,
      "Invalid fund address 3 format [special characters are not allowed except -/',&()#:.]"
    ),
  lockInPeriod: yup.string().nullable().matches(numberWithDecimalsRegex, 'Invalid Lockin Period'),
  lockInExtension: yup
    .string()
    .nullable()
    .matches(numberWithDecimalsRegex, 'Invalid Lockin Period'),
  faceValue: yup
    .number()
    .nullable()
    .test('faceValue', (value, context) => {
      const fieldValue = value === null ? '' : value;
      const { createError, path } = context;
      if (!fieldValue && fieldValue !== 0) {
        return createError({
          message: 'Face Value should be greater than or equal to 0',
          path,
        });
      }
      return true;
    }),
  setupFee: yup
    .number()
    .nullable()
    .test('setupFee', (value, context) => {
      const fieldValue = value === null ? '' : value;
      const { createError, path } = context;
      if (!fieldValue && fieldValue !== 0) {
        return createError({
          message: 'Setup Fee should be greater than or equal to 0',
          path,
        });
      }
      return true;
    }),
  minContributionPercentage: yup
    .number()
    .nullable()
    .test('minContributionPercentage', (value, context) => {
      const fieldValue = value === null ? '' : value;
      const { createError, path } = context;
      if (!fieldValue && fieldValue !== 0) {
        return createError({
          message: 'Min Contribution Percentage Fee should be greater than or equal to 0',
          path,
        });
      }
      return true;
    })
    .max(100, 'Minimum Contribution Percentage Fee cannot be greater than 100'),
  managementFee: yup
    .number()
    .nullable()
    .test(' managementFee', (value, context) => {
      const fieldValue = value === null ? '' : value;
      const { createError, path } = context;
      if (!fieldValue && fieldValue !== 0) {
        return createError({
          message: 'Management Fee should be greater than or equal to 0',
          path,
        });
      }
      return true;
    }),
  referralFee: yup
    .number()
    .nullable()
    .test('referralFee', (value, context) => {
      const fieldValue = value === null ? '' : value;
      const { createError, path } = context;
      if (!fieldValue && fieldValue !== 0) {
        return createError({
          message:
            'Referral fee is % of management fee charged should be greater than or equal to 0',
          path,
        });
      }
      return true;
    })
    .max(100, 'Referral fee is % of management fee charged cannot be greater than 100'),
  minInvestmentAmt: yup
    .number()
    .nullable()
    .min(0, `Group Investment Commitment Amount should be greater than or equal to 0`)
    .max(
      yup.ref('minCommitmentAmount'),
      `Group Investment Commitment Amount should be less than or equal to Minimum Commitment Amount`
    )
    .required('Group Investment Commitment Amount is required'),
});

export const editFundValidation = yup.object().shape({
  schemeCode: yup
    .string()
    .nullable()
    .matches(defaultRegexWithSingleLine, 'Invalid Scheme Code')
    .required('Scheme Code is required'),
  schemeName: yup
    .string()
    .nullable()
    .matches(defaultRegexWithSingleLine, 'Invalid Scheme Name')
    .required('Scheme Name is required'),
  bankName: yup
    .string()
    .nullable()
    .matches(stringRegex, 'Special characters and numbers not allowed'),
  bankBranch: yup
    .string()
    .nullable()
    .matches(stringRegex, 'Special characters and numbers not allowed'),
  amcCode: yup.string().nullable().matches(defaultRegexWithSingleLine, 'Invalid AMC Code'),
  ifscCode: yup.string().nullable().matches(ifscRegex, 'Invalid IFSC Code'),
  bankAccountNumber: yup
    .string()
    .nullable()
    .matches(accountNumberRegex, 'Please enter valid Account Number'),
  comment: yup
    .string()
    .nullable()
    .when('checkId', {
      is: (value: string) => value,
      then: yup
        .string()
        .nullable()
        .matches(validCommentRegex, InvalidCommentsMessage)
        .required('Please provide Comments'),
    }),
});
